import dateFormat from "dateformat";


export function FormatStringColumnByLength(value: string, wordsOnLine: number): string {
    let parts: string[] = value.split(" ");
    let output: string = "<span class='no-word-wrap'>";
    for (let i = 0; i < parts.length; ++i) {
        output += parts[i] + " ";
        if (i == (wordsOnLine - 1)) {
            output += "</span>";
        }
    }

    if (parts.length < (wordsOnLine - 1)) { output += "</span>"; }
    return output.trim();
}


export function RenderProgressBar(data, type, row, meta): any {
    let output: string;
    if (type !== 'display') { output = data; }
    else if (data == '-1') { output = "" }
    else {
        output = '<div class="progress position-relative"><div class="progress-bar" style="width:' + Math.round(data * 100.0) + '%"></div><div class="label justify-content-center d-flex position-absolute w-100">' + Math.round(data * 100.0) + '%</div></div>'
    }
    return output;
}

export function RenderProgressValue(data, type, row, meta): any {
    let output: string;
    if (type !== 'display') { output = data; }
    else if (data == '-1' || data == undefined) { output = "" }
    else {
        output = Math.round(data * 100.0) + '%'
    }
    return output;
}

export function RenderNullableNumber(data, type, row, meta): any {
    if (data == -1) return "";
    else return data;
}
export function RenderNullabledNumberAndRound_1Digit(data, type, row): any {
    if (data == -1 || data == undefined) return "";
    else return (<number>data).toFixed(1);
}

export function RenderNullabledNumberAndRound_2Digits(data, type, row): any {
    if (data == -1 || data == undefined) return "";
    else return (+data).toFixed(2);
}

export function RenderNullableNumberWithSeparators(data, type, row, meta): any {
    if (data == -1 || data == undefined) return "";
    else return data.toLocaleString("en-US")
}

export function RenderDateMDY(data, type, row, meta): any {

    //console.log("Render Date:", data, row);

    if (data == undefined) return "";

    let dateString: string = GetDateString(data);
    if (dateString == "") return "";

    let delimiter: string = GetDateDelimiter(dateString);
    if (delimiter == "") return "";

    let date: Date = undefined;
    try {
        let parts: string[] = (dateString).split(delimiter);
        //let date: Date = new Date(data + " 06:00:00Z-0400");
        date = new Date(Number.parseInt(parts[2]), Number.parseInt(parts[0]) - 1, Number.parseInt(parts[1]), 11, 0);
    }
    catch (error) {
        date = undefined;
    }

    if (type == 'sort') {
        if (date != undefined) return date.getTime();
        else return -1;
    }
    else {
        if (date != undefined) return "<span class='no-word-wrap'>" + dateFormat(date, "mmm dd yyyy") + "</span>";
        else return "";
    }
}

export function RenderDateYMD(data, type, row, meta): any {

    if (data == undefined) return "";

    let dateString: string = GetDateString(data);
    if (dateString == "") return "";

    let delimiter: string = GetDateDelimiter(dateString);
    if (delimiter == "") return "";

    let date: Date = undefined;
    try {
        let parts: string[] = (dateString).split(delimiter);
        //let date: Date = new Date(data + " 06:00:00Z-0400");
        date = new Date(Number.parseInt(parts[0]), Number.parseInt(parts[1]) - 1, Number.parseInt(parts[2]), 11, 0);
    }
    catch (error) {
        date = undefined;
    }

    if (type == 'sort') {
        if (date != undefined) return date.getTime();
        else return -1;
    }
    else {
        if (date != undefined) return "<span class='no-word-wrap'>" + dateFormat(date, "mmm dd yyyy") + "</span>";
        else return "";
    }
}

export function GetDateString(data: any): string {

    if (data == undefined || data == -1 || data == "") return "";

    //to remove trailing time
    let dateString: string = <string>data;
    if (dateString.indexOf(" ") != -1) { dateString = dateString.substring(0, dateString.indexOf(" ")).trim(); }

    return dateString;
}

export function GetDateDelimiter(date: string): string {
    if (date.indexOf("-") != -1) { return "-"; }
    else if (date.indexOf("/")! - 1) { return "/"; }
    else return "";
}
