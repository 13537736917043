import { SampleRequestFormData, SampleRequestCommentData, SampleRequestsData } from '../scripts/sample-request-form-data.entities'
import { FormatDate } from "../../../shared/scripts/date-functions";
import { plainToClass } from "class-transformer";
import { SpecimenSearchResult } from '../../sample-search/scripts/sample-search.entities';
import { UserQuery } from '../../../shared/entities/user-query/user-query.entities'

declare var $: any;
 
export class RequestDashboardSampleInfo {

    activeQueries = [];

    constructor() {

    }

    public openModal(userId: number, formData: SampleRequestFormData, sampleData: SampleRequestsData[], queries: UserQuery[], isAdmin: boolean) {
        // if the current User matches this userId and the status is "Not Submitted", show the "Edit/Update Request" button
        if (userId == formData.user_Id && formData.status == "In Draft") {
            $(".continue-form").removeClass("inactive-section");
            $(".continue-form").attr("href", `/secure/inventory-request/${formData.id}`);

            $(".delete-request").removeClass("inactive-section");
            $(".return-form").addClass("inactive-section");
            $(".clone-form").addClass("inactive-section");
            $(".admin-modal").addClass("inactive-section");
            $(".status-modal").addClass("inactive-section");
        }
        else {
            $(".continue-form").addClass("inactive-section");
            $(".continue-form").attr("href", "");

            $(".delete-request").addClass("inactive-section");

            // only allow the user who made the request to clone it
            if (userId == formData.user_Id) {
                $(".clone-form").removeClass("inactive-section");
                $(".status-modal").removeClass("inactive-section");
            }
            else {
                $(".clone-form").addClass("inactive-section");
                $(".status-modal").removeClass("inactive-section");
            }


            if (userId == formData.user_Id && formData.status == "Submitted") {
                $(".return-form").removeClass("inactive-section");
            }
            else {
                $(".return-form").addClass("inactive-section");
            }

            if (isAdmin && formData.status != "In Draft") {
                $(".admin-modal").removeClass("inactive-section");
            }
            else {
                $(".admin-modal").addClass("inactive-section");
            }

            if (isAdmin) {
                $(".status-modal").addClass("inactive-section");
                $(".delete-request").removeClass("inactive-section");
            }
        }

        // populate model
        // --- Database Content ---
        $("#sample-header-id").find("span").text(formData.combinedId);
        $("#request-id").text(formData.combinedId);
        $("#request-status").text(formData.status);
        $("#request-last-updated").text(FormatDate(formData.lastUpdated));
        $("#request-submission-date").text(FormatDate(formData.submissionDate));

        // --- Requestor Contact Info ---
        let applicantFullName = `${formData.requestorFirstName ? formData.requestorFirstName : ""} ${formData.requestorLastName ? formData.requestorLastName : ""}`;
        $("#requestor-name").text(applicantFullName);
        $("#institution-name").text(formData.institutionName);
        $("#institution-type").text(formData.institutionType);
        $("#requestor-telephone").text(formData.requestorTelephone);
        $("#requestor-email").text(formData.requestorEmail);

        // --- Shipment Info ---
        $("#laboratory-name").text(formData.laboratoryName);
        let shippingFullName = `${formData.shippingFirstName ? formData.shippingFirstName : ""} ${formData.shippingLastName ? formData.shippingLastName : ""}`;
        $("#shipping-name").text(shippingFullName);
        $("#shipping-telephone").text(formData.shippingTelephone);
        $("#shipping-email").text(formData.shippingEmail);
        $("#shipping-country").text(formData.shippingCountry);

        let shippingString = `${formData.shippingStreet ? formData.shippingStreet + ", " : ""}${formData.shippingStreet2 ? formData.shippingStreet2 + ", " : ""}${formData.shippingCity ? formData.shippingCity + ", " : ""}${formData.shippingState ? formData.shippingState + ", " : ""}${formData.shippingZip ? formData.shippingZip : ""}`;
        $("#shipping-address").text(shippingString);
        $("#shipping-account-name").text(formData.shippingAccountName);
        $("#shipping-account-number").text(formData.shippingAccountNumber);
        $("#shipping-special-instructions").text(formData.shippingSpecialInstructions);
        let agreementName = `${formData.agreementContactFirstName ? formData.agreementContactFirstName : ""} ${formData.agreementContactLastName ? formData.agreementContactLastName : ""}`;
        $("#shipping-agreement-name").text(agreementName);
        $("#shipping-agreement-telephone").text(formData.agreementContactTelephone);
        $("#shipping-agreement-email").text(formData.agreementContactEmail);

        // --- Project Approach ---
        let piNames = "";
        if (formData.piNames != null) {
            piNames = formData.piNames.split("|").join(", ");
        }
        $("#project-title").text(formData.projectTitle);
        $("#short-title").text(formData.shortTitle);
        $("#pi-names").text(piNames);
        $("#background").text(formData.background);
        $("#scientific-aims").text(formData.scientificAims);
        $("#significance").text(formData.significance);
        $("#methods").text(formData.methods);
        $("#sample-size").text(formData.approach);
        $("#additional-population").text(formData.participantDescription);
        $("#clinical-data").text(formData.clinicalData);
        $("#clinical-data-additional").text(formData.clinicalDataAdditional == "" ? "N/A" : formData.clinicalDataAdditional);
        $("#clinical-data-date").text(formData.anticipatedClinicalDataDate == "0001-01-01T00:00:00" ? "N/A" : FormatDate(formData.anticipatedClinicalDataDate));

        // --- Admin Details ---
        $("#data-sharing-plan").text(formData.sharingPlan);
        $("#biospecimen-use").text(formData.biospecimenUse);
        $("#start-date").text(formData.startDate == "0001-01-01T00:00:00" ? "" : FormatDate(formData.startDate));
        $("#end-date").text(formData.completionDate == "0001-01-01T00:00:00" ? "" : FormatDate(formData.completionDate));
        $("#funding-available").text(formData.fundingAvailable ? "Yes" : "No");
        $("#nih-check").text(formData.nihFunding ? "Yes" : "No");
        $("#nih-award-number").text(formData.nihAwardNumber);
        $("#other-federal-check").text(formData.otherFederalFunding ? "Yes" : "No");
        $("#other-agency").text(formData.otherFederalName);
        $("#foundation-check").text(formData.foundationFunding ? "Yes" : "No");
        $("#industry-check").text(formData.industryFunding ? "Yes" : "No");
        $("#other-check").text(formData.otherFunding ? "Yes" : "No");
        $("#other-funding").text(formData.otherFundingName);
        $("#a-nih-check").text(formData.anticipatedNIHFunding ? "Yes" : "No");
        $("#a-other-federal-check").text(formData.anticipatedOtherFederalFunding ? "Yes" : "No");
        $("#a-other-agency").text(formData.anticipatedOtherFederalName);
        $("#a-foundation-check").text(formData.anticipatedFoundationFunding ? "Yes" : "No");
        $("#a-industry-check").text(formData.anticipatedIndustryFunding ? "Yes" : "No");
        $("#a-other-check").text(formData.anticipatedOtherFunding ? "Yes" : "No");
        $("#a-other-funding").text(formData.anticipatedOtherFundingName);
        $("#a-application-submit").text(formData.fundingAvailable ? "N/A" : formData.anticipatedFundingSubmissionCheck);
        $("#a-application-submit-date").text(formData.fundingAvailable ? "N/A" : FormatDate(formData.anticipatedFundingSubmission));
        $("#a-application-award-date").text(formData.fundingAvailable ? "N/A" : FormatDate(formData.anticipatedFundingAward));

        // --- Samples Requested Tables ----
        this.populateSpecimenTables(sampleData, queries, formData.id);

        // --- Additional Info --- 
        $("#additional-detail").text(formData.additionalDetail);

        // Remove unnecessary rows

        // clinical data
        if (formData.clinicalData == "Requested" || formData.clinicalData == "Approved") {
            $("#clinical-data-date-table-row").removeClass("inactive-section");

            if (formData.clinicalData == "Requested") {
                $("#clinical-data-date-label").text("Anticipated Date of Approval for Clinical Data Request:");
            }

            if (formData.clinicalData == "Approved") {
                $("#clinical-data-date-label").text("Anticipated Date for Receipt of Dataset:");
            }
        }
        else {
            $("#clinical-data-date-table-row").addClass("inactive-section");
        }

        if (formData.clinicalData == "Not Yet Requested" || formData.clinicalData == "Not Applicable") {
            $("#clinical-data-additional-table-row").removeClass("inactive-section");
        }
        else {
            $("#clinical-data-additional-table-row").addClass("inactive-section");
        }

        // funding sources
        if (formData.fundingAvailable) {
            $("#funding-sources-table-row").removeClass("inactive-section");
            $("#a-funding-sources-table-row").addClass("inactive-section");
            $("#a-application-submit-table-row").addClass("inactive-section");
            $("#a-application-award-date-table-row").addClass("inactive-section");
            $("#a-application-submit-date-table-row").addClass("inactive-section");
        }
        else {
            $("#funding-sources-table-row").addClass("inactive-section");
            $("#a-funding-sources-table-row").removeClass("inactive-section");
            $("#a-application-submit-table-row").removeClass("inactive-section");
            $("#a-application-award-date-table-row").removeClass("inactive-section");

            if (formData.anticipatedFundingSubmissionCheck?.toString() == "Yes") {
                $("#a-application-submit-date-table-row").removeClass("inactive-section");
            }
            else {
                $("#a-application-submit-date-table-row").addClass("inactive-section");
            }
        }

        // don't show unfinished sections (blank)
        if (!formData.requestorFirstName) {
            $("#applicant-table").addClass("inactive-section");
            $("#applicant-table-blank").removeClass("inactive-section");
        }
        else {
            $("#applicant-table").removeClass("inactive-section");
            $("#applicant-table-blank").addClass("inactive-section");
        }

        if (!formData.shippingFirstName) {
            $("#shipment-table").addClass("inactive-section");
            $("#shipment-table-blank").removeClass("inactive-section");
        }
        else {
            $("#shipment-table").removeClass("inactive-section");
            $("#shipment-table-blank").addClass("inactive-section");
        }

        if (!formData.projectTitle) {
            $("#project-approach-table").addClass("inactive-section");
            $("#project-approach-table-blank").removeClass("inactive-section");
        }
        else {
            $("#project-approach-table").removeClass("inactive-section");
            $("#project-approach-table-blank").addClass("inactive-section");
        }

        if (!formData.sharingPlan) {
            $("#admin-details-table").addClass("inactive-section");
            $("#admin-details-table-blank").removeClass("inactive-section");
        }
        else {
            $("#admin-details-table").removeClass("inactive-section");
            $("#admin-details-table-blank").addClass("inactive-section");
        }
        

        // show modal
        $("#sample-info").modal("show");
    }

    populateSpecimenTables(sampleData: SampleRequestsData[], queries: UserQuery[], formId: string) {
        let self = this;
        // Samples Requested
        // clear out any data that was previously on the page
        $("#samples-requested").find("table").remove();

        if (sampleData.length > 0) {
            $("#samples-requested-table-blank").addClass("inactive-section");
        }
        else {
            $("#samples-requested-table-blank").removeClass("inactive-section");
        }

        // creates table for samples requested
        sampleData.forEach((sample, i) => {
            // queries
            let queryStringArr: string[] = sample.queries.split(",");
            let queryDataIds: number[] = [];
            let queryData: UserQuery[] = [];
            let queryHTML = "";

            queryStringArr.forEach(query => {
                let queryId = parseInt(query);
                if (queryId != NaN) {
                    queryDataIds.push(queryId);
                }
            });

            queryDataIds.forEach(id => {
                let query = queries.find(query => query.id == id);
                if (query != undefined) {
                    queryData.push(query);
                }
                else {
                    let newQuery: UserQuery = {
                        id: 0,
                        queryJson: "{blankQuery}",
                        humanReadable: "",
                        queryName: "Query Not Found"                   
                    }
                    queryData.push(newQuery);
                }
            });
            queryData.forEach((query) => {              
                let content = `
                    <tr id="samples-requested-queries">
                        <td class="text-center">
                            <a href="/secure/inventory-search/${query.id}" target="_blank" class="queryLink">${query.id}</a>
                        </td>
                        <td>${query.queryName}</td>
                        <td id="sample-${sample.id}-query-${query.id}-participants" class="query-number"><i class="fa-solid fa-spinner fa-spin"></i></td>
                        <td id="sample-${sample.id}-query-${query.id}-available" class="query-number"><i class="fa-solid fa-spinner fa-spin"></i></td>
                    </tr>
                `;

                // if the query isn't in the database
                if (query.id == 0) {
                    content = `
                    <tr id="samples-requested-queries">
                        <td class="text-center">
                            ${query.id}
                        </td>
                        <td>${query.queryName}</td>
                        <td id="sample-${sample.id}-query-${query.id}-participants">Not Available</i></td>
                        <td id="sample-${sample.id}-query-${query.id}-available">Not Available</td>
                    </tr>
                `;
                }
                queryHTML += content;
            });

            // formatting for timepoint(s)
            let timepointHTMLTableContent = "";
            sample.timepoint.split(",").forEach(timepoint => {
                timepointHTMLTableContent += `<tr><td>${timepoint}</td></tr>`;
            })

            let timepointHTMLTable = `
                <table class="table table-striped table-bordered table-purple">
                    <thead>
                        <tr>
                            <th class="purple-bg">Timepoint(s)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <td class="pdf-only pdf-header purple-bg" scope="col">Timepoint(s)</td>
                        ${timepointHTMLTableContent}
                    </tbody>
                </table> 
            `;

            let studyNames: string[] = ["ACTIV1", "ACTIV4a", "ACTIV4c", "ACTIV4 Host Tissue"]
            let studyDisplayNames: string[] = ["ACTIV1 Immune Modulators", "ACTIV4a Inpatient", "ACTIV4c Convalescent", "ACTIV4d Host Tissue"]

            let displayStudy = sample.study;
            let foundStudyIndex = studyNames.indexOf(sample.study);
            if (foundStudyIndex != -1) {
                displayStudy = studyDisplayNames[foundStudyIndex];
            }
            
            $("#samples-requested").append(
                `<table class="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th scope="col" colspan="2">Request #${i + 1}: ${sample.sampleQueryName}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th class="pdf-only pdf-header" scope="col" colspan="2">Request #${i + 1}: ${sample.sampleQueryName}</th>
                        </tr>
                        <tr>
                            <td class="table-label">Study:</td>
                            <td>${displayStudy}</td>
                        </tr>
                        <tr>
                            <td class="table-label">Biospecimen Type:</td>
                            <td>${sample.sampleType}</td>
                        </tr>
                        <tr>
                            <td class="table-label">Treatment Arm:</td>
                            <td>${sample.treatmentArm}</td>
                        </tr>
                        <tr>
                            <td class="table-label">Number of Biospecimens Requested per Timepoint:</td>
                            <td>${sample.quantity}</td>
                        </tr>
                        <tr>
                            <td class="table-label">Amount per Biospecimen:</td>
                            <td>${sample.volume} ${sample.unit}</td>
                        </tr>
                        <tr>
                            <td class="table-label">Timepoint:</td>
                            <td>${timepointHTMLTable}</td>
                        </tr>
                        <tr>
                            <td class="table-label">Queries:</td>
                            <td>
                                <table class="table table-striped table-bordered table-purple">
                                    <thead>
                                        <tr>
                                            <th class="purple-bg middle-header" scope="col">Id</th>
                                            <th class="purple-bg middle-header" scope="col">Query Name</th>
                                            <th class="purple-bg middle-header" scope="col">Available Participants</th>
                                            <th class="purple-bg middle-header" scope="col">Available Biospecimens</th>
                                        </tr>
                                    </thead>
                                        <tr>
                                            <td class="pdf-only pdf-header purple-bg middle-header" scope="col">Id</td>
                                            <td class="pdf-only pdf-header purple-bg middle-header" scope="col">Query Name</td>
                                            <td class="pdf-only pdf-header purple-bg middle-header" scope="col">Available Participants</td>
                                            <td class="pdf-only pdf-header purple-bg middle-header" scope="col">Available Biospecimens</td>
                                        </tr>
                                    <tbody>
                                        ${queryHTML}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td class="table-label">Other Specifications:</td>
                            <td>${sample.otherSpecifications != null ? sample.otherSpecifications : ""}</td>
                        </tr>
                    </tbody>
                </table>`
            );

            // --- Getting Total Requested / Total Available
            /*queryData.forEach(query => {
                if (query.id != 0) {
                    this.searchQueries(query.queryJson, sample.sampleRequestFormId, query.id)
                }
            });*/

        });

        // --- Getting Total Requested / Total Available
        this.searchQueries(queries, sampleData, formId);
        
    }

    // populates available particpants + available biospecimens in table
    searchQueries(queries: UserQuery[], sampleData: SampleRequestsData[], formId: string) {
        let queryDataIds: number[] = [];
        let sampleFormIds: number[] = [];
        let queryData: string[] = [];

        // create queryJson Array, queryId Array, and sampleFormId Array
        sampleData.forEach(sample => {
            let queryStringArr: string[] = sample.queries.split(",");
            queryStringArr.forEach(query => {
                let queryId = parseInt(query);
                if (queryId != NaN && queryId != 0) {
                    let query = queries.find(query => query.id == queryId);
                    if (query != undefined) {
                        queryDataIds.push(queryId);
                        sampleFormIds.push(sample.id);

                        // Parse JSON Data, so it won't be double stringified
                        queryData.push(JSON.parse(query.queryJson));
                    }
                }
            });
        });

        let jsonDataArray = JSON.stringify(queryData);

        var request = $.ajax({
            url: '/secure/request-dashboard/request-dashboard?handler=QuerySearch',
            method: "POST",
            //dataType: "json",
            //contentType: "application/json",
            data: { queryData: jsonDataArray },
            headers: { "RequestVerificationToken": $('input[name="__RequestVerificationToken"]').val() },
            success: (response: []) => {

                //console.log(response);

                // populate query table
                response.forEach((result, i) => {
                    let searchResult: SpecimenSearchResult[] = plainToClass(SpecimenSearchResult, <SpecimenSearchResult[]>result);
                    let patientSet: Set<string> = new Set<string>();
                    let sampleSet: Set<string> = new Set<string>();

                    searchResult.forEach((result: SpecimenSearchResult) => {
                        result.initialize();
                        patientSet.add(result.Patient);
                        sampleSet.add(result.SampleId);
                    });

                    // Use the sampleFormId and queryDataId to target specific table cell
                    let participantsId = `#sample-${sampleFormIds[i]}-query-${queryDataIds[i]}-participants`;
                    let availableId = `#sample-${sampleFormIds[i]}-query-${queryDataIds[i]}-available`;

                    $(participantsId).text(patientSet.size).addClass("pdf-ready");
                    $(availableId).text(sampleSet.size).addClass("pdf-ready");
                });
            },
            error: (response) => {

                if (response.statusText != "abort") {
                    console.log("error", response);
                    alert("An unexpected error occurred. Please try again.");
                }

                // on error, fill table cells with "Not Available"
                queryDataIds.forEach((queryId, i) => {
                    let participantsId = `#sample-${sampleFormIds[i]}-query-${queryId}-participants`;
                    let availableId = `#sample-${sampleFormIds[i]}-query-${queryId}-available`;

                    $(participantsId).text("Not Available").addClass("pdf-ready");
                    $(availableId).text("Not Available").addClass("pdf-ready");
                });
            }
        });

        this.activeQueries.push(request);
    }

    public getActiveQueries() {
        return this.activeQueries;
    }

}