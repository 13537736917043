export class SampleExportPDF {
    styling: string;

    constructor() {
        this.styling = `
        <style>

            body {
                font-family: 'Open Sans', sans-serif;
            }

            img {
                display: block;
                width: 200px;
                margin: auto;
            }

            .section-label {
                font-family: "Montserrat", sans-serif;
                font-weight: 600;
                font-size: 16px;
                display: inline-block;
                margin-bottom: 18px;
            }

            .table {
              border-collapse: collapse;
              width: 100%;
              margin-bottom: 1em;
            }

            .table td, .table th {
              border: 1px solid #ddd;
              padding: 8px;
              font-size: 12px !important;
            }

            .table tr:nth-child(even){background-color: #f2f2f2;}

            .table th {
              padding-top: 12px;
              padding-bottom: 12px;
              text-align: left;
              background-color: #1C7293;
              color: white;
            }

            .table thead {
                display: none;
            }

            .table-label {
                font-weight: bold;
                width: 200px;
            }

            hr {
                display: none;
            }

            .pdf-header {
                padding-top: 12px;
                padding-bottom: 12px;
                text-align: left;
                background-color: #1C7293;
                color: white;
                font-weight: bold;
                border-bottom: 2px solid #dee2e6;
            }

            .purple-bg {
                background-color: #45335c;
            }

            .middle-header {
                text-align: center;
                vertical-align: middle;
            }

            .queryLink {
                color: #1C7293;
            }

            .queryLink:visited {
                color: #1C7293;
            }

            .text-center {
                text-align: center;
            }

            .inactive-section {
                display: none;
            }
        </style>
        `;
    }

    public printPDF(title: string, targetId: string, styling: string = this.styling): void {
        var divContents = $(`#${targetId}`).html();
        var printWindow = window.open('', '', 'height=400,width=800');
        printWindow.document.write(`<html><head><title>${title}</title>`);
        printWindow.document.write(styling);
        printWindow.document.write('</head><body >');
        printWindow.document.write(divContents);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
        printWindow.close();
    }

    // verifies that all the query numbers are loaded
    public readyToPDF(queryHTML: JQuery): boolean {

        let isReady = true;

        queryHTML.each(function () {
            if (!$(this).hasClass("pdf-ready")) {
                isReady = false;
                return false;
            }
        });

        return isReady;
    }

    public openModalPDF(id: string): void {
        $("#loading-pdf-id").find("span").text(id);
        $("#ready-to-pdf").addClass("inactive-section");
        $("#loading-icon").removeClass("inactive-section");
        $("#sample-info").modal("hide");
        $("#loading-pdf-modal").modal("show");
    }
}