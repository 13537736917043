import { RequestNavigation } from "./sample-request.navigation";
import { RequestValidation } from "./sample-request.validation";
import { RequestComponents } from "./sample-request.components";
import { RequestSpecific } from "./sample-request.specific";
import { RequestSamples } from "./sample-request.samples";
import { RequestPrepopulate } from "./sample-request.prepopulate";
import { plainToClass } from 'class-transformer';
import { SampleRequestFormData, SampleRequestsData } from '../../request-dashboard/scripts/sample-request-form-data.entities';
import { UserQuery } from '../../../shared/entities/user-query/user-query.entities';
import { SampleRow, UserContactObj } from './sample-request.entities';

export class SampleRequestPage {
    requestNavigation: RequestNavigation;
    requestValidation: RequestValidation;
    requestComponents: RequestComponents;
    requestSpecific: RequestSpecific;
    requestSamples: RequestSamples;
    requestPrepopulate: RequestPrepopulate;

    requestForm: SampleRequestFormData;
    requestFormSamples: SampleRequestsData[];
    savedQueries: UserQuery[];
    userContact: UserContactObj;

    constructor(formData: any, formSamples: any, savedQueries: any, userContact: any) {
        this.requestNavigation = new RequestNavigation();
        this.requestValidation = new RequestValidation();
        this.requestComponents = new RequestComponents();
        this.requestSpecific = new RequestSpecific();
        this.requestSamples = new RequestSamples();
        this.requestPrepopulate = new RequestPrepopulate();

        this.requestForm = plainToClass(SampleRequestFormData, <SampleRequestFormData>formData);
        this.requestFormSamples = plainToClass(SampleRequestsData, <SampleRequestsData[]>formSamples);
        this.savedQueries = plainToClass(UserQuery, <UserQuery[]>savedQueries);
        this.userContact = plainToClass(UserContactObj, <UserContactObj>userContact);

        //console.log(this.requestForm);
        //console.log(this.requestFormSamples);
        //console.log(this.savedQueries);
        
        //document.ready
        $(() => {
            $('[data-toggle="popover"]').popover();

            // variable declaration
            var requestNavigation: RequestNavigation = this.requestNavigation;
            var requestValidation: RequestValidation = this.requestValidation;
            var requestComponenets: RequestComponents = this.requestComponents;
            var requestSpecific: RequestSpecific = this.requestSpecific;
            var requestSamples: RequestSamples = this.requestSamples;
            var requestPrepopulate: RequestPrepopulate = this.requestPrepopulate;

            var requestForm: SampleRequestFormData = this.requestForm;
            var requestFormSamples: SampleRequestsData[] = this.requestFormSamples;

            // Set the Text for the Start Form Button (if they have any data saved)
            if (requestForm.requestorFirstName != null && requestForm.requestorFirstName != "") {
                $(".continue-form").find("span").text("Continue Biospecimen Request Form")
            }

            // prepopulate form (for demo)
            /*$(".start-form-pop").click(function () {
                requestPrepopulate.prepopulateForm();
                requestSamples.prepopulateSamples();
            })*/

            // prepopulate biospecimen row
            /*$(".prepopulate-modal").click(function () {
                requestPrepopulate.prepopulateModal();
            })*/

            // for next button, moving to next section of form
            $(".next").click(function () {
                let isValid = requestValidation.validateForm();
                if (isValid) {
                    requestNavigation.nextSection($(this), requestSamples);
                    let sampleRows: SampleRow[] = requestSamples.getSampleRows();
                    requestValidation.saveForm(sampleRows);
                }
            });


            // for prev button, moving to previous section of the form
            $(".prev").click(function () {
                requestNavigation.previousSection($(this));
            });

            // using navigation tabs 
            $(".progress-tabs a").click(function () {
                requestNavigation.tabNavigation($(this));
            });     

            // review section navigation
            $(".review-link").click(function () {
                requestNavigation.reviewNavigation($(this));
            })

            // word count validation
            $(".word-count").on("keypress input", function () {
                requestValidation.wordCount($(this));
            })

            // multi-add section
            $(".multi-add-btn").click(function () {
                requestComponenets.multiAddBtn($(this));
            })

            $(".multi-check-btn").click(function () {
                requestComponenets.multiCheckBtn($(this));
            })

            $("#submit-button").click(function () {
                let sampleRows: SampleRow[] = requestSamples.getSampleRows();
                requestValidation.submitForm(sampleRows);
            })

            // prevent submission of form when hitting enter (still allows for accessibility related functions)
            $(document).on("keydown", "form input", function (event) {
                if (event.keyCode == 13) {
                    // get focused element
                    let focusedElement = $(document.activeElement);

                    // if it is a checkbox, run checkbox accessibility
                    if (focusedElement.hasClass("small-check")) {
                        checkboxAccessibility(focusedElement);
                    }

                    event.preventDefault();
                    return false;
                }
            });
            $(document).on("keyup keypress", "form input", function (event) {
                if (event.keyCode == 13) {
                    event.preventDefault();
                    return false;
                }
            });

// NON GENERIC

            // opens request form from cover page
            $(".start-form").click(function () {
                $("#cover-page").hide();
                $(".request-form").show();
                requestNavigation.scrollToTop();
            })

            // show other institution field if selected
            $("#institution-type-select").change(function () {
                requestSpecific.institutionField($(this));
            })

            // clinical data, make the add reasoning for no clinical data approval
            $("#clinical-data").change(function () {
                requestSpecific.clinicalDataShow();
            })


            // project details, make the add reasoning for no funding input appear/disappear
            $("#funding-available").change(function () {
                requestSpecific.fundingAvailableShow();
            })

            $("#a-funding-available").change(function () {
                requestSpecific.aFundingAvailableShow();
            })

            // project details, checkboxes with additional inputs needed, appear/disappear
            $(".check-additional-input").change(function () {
                $(this).parent().parent().next().toggle("slow");
                // reset input value
                $(this).parent().parent().next().find("input").val("");
            })

            // sample request, save new sample row
            $("#save-sample").click(function () {
                requestSamples.saveSample();                          
            })

            // update a sample row
            $("#update-sample").click(function () {
                requestSamples.updateSample();
            })

            // updates dropdowns in sample request modal & update request modal based on selected study
            $("#sample-study").change(function () {
                requestSamples.changeStudyNew($(this));
            })

            $("#update-study").change(function () {
                requestSamples.changeStudyUpdate($(this));
            })           

            // when the user opens a new sample modal 
            $("#open-new-form").click(function () {
                requestSamples.openNewForm();
            })

            // allows users to navigate and use buttons when they are focused by hitting the "enter" key (accessibility)
            $("a").on("keypress", function (e) {
                if ($(this).is(":focus") && e.which == 13) {
                    buttonAccessibility($(this));
                }
            })

            // allows users to use the enter key to check/uncheck boxes
            /*$(".small-check").on("keypress", function (e) {
                if ($(this).is(":focus") && e.which == 13) {
                    checkboxAccessibility($(this));
                }
            })*/

            // ----- Prepopulate Form ------

            // Populates form with Data from Database
            requestPrepopulate.populateForm(requestForm, userContact);
            requestSamples.populateSampleTable(requestFormSamples, savedQueries);


            // ----- Accessibility Functions ------

            function buttonAccessibility(selectedElement) {
                if (selectedElement.hasClass("next")) {
                    let isValid = requestValidation.validateForm();
                    if (isValid) {
                        requestNavigation.nextSection(selectedElement, requestSamples);
                        let sampleRows: SampleRow[] = requestSamples.getSampleRows();
                        requestValidation.saveForm(sampleRows);
                    }
                }
                if (selectedElement.hasClass("prev")) {
                    requestNavigation.previousSection(selectedElement);
                }
                if (selectedElement.hasClass("review-link")) {
                    requestNavigation.reviewNavigation(selectedElement);
                }
                if (selectedElement.hasClass("submit")) {
                    $("section").children().not("#submit-form").remove();
                    $("#submit-form").show();
                }
                if (selectedElement.hasClass("multi-add-btn")) {
                    //console.log("Added")
                    requestComponenets.multiAddBtn(selectedElement);
                }
                if (selectedElement.hasClass("multi-check-btn")) {
                    requestComponenets.multiCheckBtn(selectedElement);
                }
                if (selectedElement.hasClass("start-form")) {
                    $("#cover-page").hide();
                    $(".request-form").show();
                    requestNavigation.scrollToTop();
                }
                if (selectedElement.attr("id") == "save-sample") {
                    requestSamples.saveSample();
                }
                if (selectedElement.attr("id") == "update-sample") {
                    requestSamples.updateSample();
                }
                if (selectedElement.attr("id") == "open-new-form") {
                    requestSamples.openNewForm();
                }
            }

            function checkboxAccessibility(selectedElement) {
                if (selectedElement.hasClass("check-additional-input")) {
                    selectedElement.parent().parent().next().toggle("slow");
                }
                if (selectedElement.is(":checked")) {
                    selectedElement.prop("checked", false);
                }
                else {
                    selectedElement.prop("checked", true);
                }
            }

        });

    }
}