import { RequestReview } from './sample-request.review';
import { RequestSamples } from './sample-request.samples';

export class RequestNavigation {
    currentTabNumber: number;
    maxTabNumber: number;
    requestReview: RequestReview;

    constructor() {
        this.currentTabNumber = 0;
        this.maxTabNumber = 6;
        this.requestReview = new RequestReview();

    }

    // function to move form to the next section. Pass in the current section as a jquery object $(this)
    nextSection(currentSectionObj:any, requestSamples: RequestSamples) {
        // get current section and next section
        let currentSection = currentSectionObj.parent().parent();
        let newSection = currentSection.next();

        // compile information into form object 
        if (currentSectionObj.hasClass("review")) {
            this.requestReview.runReview(requestSamples);
        }

        // hide the current section and show the new section
        currentSection.toggleClass("inactive-section");
        currentSection.toggleClass("active-section");
        newSection.toggleClass("inactive-section");
        newSection.toggleClass("active-section");

        // get the current tab and the new tab
        let currentTab = $(".active-tab");
        let newTab = currentTab.parent().next().children("a");
        let newTabParent = newTab.parent();

        // add active status to new tab, remove from current
        currentTab.toggleClass("active-tab");
        newTab.removeClass("disable-tab"); // if already removed, this will do nothing
        newTab.addClass("active-tab");
        newTabParent.nextAll().find("a").addClass("disable-tab");

        // get the progressBar
        let progressBar = $(".progress-bar");

        // add/remove progressBar width classes
        if (this.currentTabNumber < this.maxTabNumber) {
            let currentWidth = "pb-" + this.currentTabNumber;
            let nextTabNum = this.currentTabNumber + 1;
            let newWidth = "pb-" + nextTabNum;
            progressBar.removeClass(currentWidth);
            progressBar.addClass(newWidth);
        }

        // update current tab number
        this.currentTabNumber += 1;

        // scroll to top of the page
        this.scrollToTop();
    }

    previousSection(currentSectionObj:any) {
        // get the current section and the prev section
        let currentSection = currentSectionObj.parent().parent();
        let newSection = currentSection.prev();

        // hide the current section and show the new section
        currentSection.toggleClass("inactive-section");
        currentSection.toggleClass("active-section");
        newSection.toggleClass("inactive-section");
        newSection.toggleClass("active-section");


        // get the current tab and the new tab
        let currentTab = $(".active-tab");
        let newTab = currentTab.parent().prev().children("a");
        let newTabParent = newTab.parent();

        // add active status to new tab, remove from current
        currentTab.toggleClass("active-tab");
        newTab.removeClass("disable-tab"); // if already removed, this will do nothing
        newTab.addClass("active-tab");
        newTabParent.nextAll().find("a").addClass("disable-tab");

        // update current tab number
        this.currentTabNumber -= 1;

        // scroll to top of the page
        this.scrollToTop();
    }

    tabNavigation(selectedTab: any) {
        // get array of classes
        let tabClasses = selectedTab.attr("class");
        let classArr = tabClasses.split(/\s+/);

        // update progress tabs
        let currentTab = $(".active-tab");
        let newTab = selectedTab;
        currentTab.toggleClass("active-tab");
        newTab.toggleClass("active-tab");
        let newTabParent = newTab.parent();

        // update selected section
        classArr.forEach(el => {
            // ignore any classes that may be added to the <a> tag
            if (el != "active-tab" && el != "disable-tab") {
                let targetID = "#" + el;

                // make current section inactive, and make targeted section active
                let currentSection = $(".active-section");
                let newSection = $(targetID);

                currentSection.toggleClass("inactive-section");
                currentSection.toggleClass("active-section");
                newSection.toggleClass("inactive-section");
                newSection.toggleClass("active-section");

                // update the tab number
                let currentWidth = "pb-" + this.currentTabNumber;
                let nextTabNum = selectedTab.parent().index();
                let newWidth = "pb-" + nextTabNum;

                // get the progressBar
                let progressBar = $(".progress-bar");

                // add/remove progressBar width classes
                if (this.currentTabNumber < this.maxTabNumber) {
                    progressBar.removeClass(currentWidth);
                    progressBar.addClass(newWidth);
                    this.currentTabNumber = nextTabNum;
                }
            }
        })

        newTabParent.nextAll().find("a").addClass("disable-tab");

        // scroll to top of the page
        this.scrollToTop();
    }

    reviewNavigation(selectedSection: any) {
        // get the specified section
        let tabClasses = selectedSection.attr("class");
        let classArr = tabClasses.split(/\s+/);

        // show the correct content
        classArr.forEach((cls) => {
            if (cls != "review-link") {
                let targetID = "#" + cls;
                // hide review section
                $("#review").toggleClass("active-section");
                $("#review").toggleClass("inactive-section");
                // show specified section
                $(targetID).toggleClass("active-section");
                $(targetID).toggleClass("inactive-section");

                // adjust tabs accordingly
                // remove current active tab
                let currentTab = $(".progress-tabs").find(".active-tab");
                currentTab.toggleClass("active-tab");

                // set new active tab
                let targetClass = "." + cls;
                let targetTab = $(".progress-tabs").find(targetClass);
                targetTab.toggleClass("active-tab");
                targetTab.parent().nextAll().find("a").addClass("disable-tab");
            }
        })

        this.scrollToTop();
    }

    scrollToTop() {
        // scroll to top of the page
        $('html, body').animate({
            //scrollTop: $(".request-form").offset().top
            scrollTop: $("#show-save-section").offset().top
        }, "slow");
    }


}