import { TableColumnVisibilityController, TableColumnGroup } from "../../../shared/scripts/table-column-visibility";
import { Unique } from "../../../shared/scripts/array-functions";
import { RenderDateYMD } from "../../../shared/scripts/table-functions"
import { GetSelectedDropdownValues, IFilter, InitializeFilterDropdown } from "../../../shared/scripts/dropdown-functions";
import { SampleRequestFormData, SampleRequestCommentData, SampleRequestsData } from '../scripts/sample-request-form-data.entities'

export class RequestFormDataTable implements IFilter {

    sampleRequestForms: SampleRequestFormData[];
    dataTable: any;
    tableColumnController: TableColumnVisibilityController;
    isAdmin: boolean;

    constructor(requestList: SampleRequestFormData[], isAdmin: boolean = false) {
        this.sampleRequestForms = requestList;
        this.isAdmin = isAdmin;
        this.initializeTable();
        this.initializeFilters();
    }

    initializeFilters(): void {
        InitializeFilterDropdown('#status-dropdown', Unique(this.sampleRequestForms.map((sampleRequestForm: SampleRequestFormData) => sampleRequestForm.status), true), this);
        //InitializeFilterDropdown('#institution-dropdown', Unique(this.sampleRequestForms.map((sampleRequestForm: SampleRequestFormData) => sampleRequestForm.institutionName), true), this);
    }

    initializeTable(): void {

        let fixedColumns: number = 1;
        let sortColumn: number = 0;

        let columns: any[];
        if (!this.isAdmin) {
            columns = [
                { data: { _: "combinedId", display: "combinedIdDisplay" }, title: "ID", className: "id text-left font-size12 nowrap", width: '110px' },
                { data: "status", title: "Status", className: "status text-left font-size12 nowrap", width: '110px' },
                { data: "submissionDate", title: "Date Submitted", className: "date-submitted text-left font-size12 nowrap", width: '110px', render: RenderDateYMD },
                { data: "requestorFullName", title: "Applicant", className: "requestor-name text-left font-size12", width: '110px' },
                { data: "institutionName", title: "Institution", className: "institution-name text-left font-size12", width: '150px' },
                { data: "shortTitle", title: "Project Title (Short)", className: "short-title text-left font-size12", width: '200px' },
                { data: "projectTitle", title: "Project Title (Full)", className: "project-title text-left font-size12", width: '200px' },
                { data: "lastUpdated", title: "Last Updated", className: "last-updated text-left font-size12 nowrap", width:'110px', render: RenderDateYMD },
                { data: "studyTable", title: "Studies Requested", className: "studies-requested text-left font-size12", width: '110px' },
                { data: "treatmentArmsTable", title: "Treatment Arms Requested", className: "treatment-arms-requested text-left font-size12", width: '110px' },
                { data: "sampleTypesTable", title: "Biospecimen Types Requested", className: "sample-types-requested text-left font-size12", width: '110px' },
            ];
        }
        else {
            columns = [
                { data: { _: "combinedId", display: "combinedIdDisplay" }, title: "ID", className: "id text-left font-size12 nowrap" },
                { data: "status", title: "Status", className: "status text-left font-size12 nowrap" },
                { data: "submissionDate", title: "Date Submitted", className: "date-submitted text-left font-size12 nowrap", render: RenderDateYMD },
                { data: "requestorFullName", title: "Applicant", className: "requestor-name text-left font-size12" },
                { data: "institutionName", title: "Institution", className: "institution-name text-left font-size12" },
                { data: "shortTitle", title: "Project Title (Short)", className: "short-title text-left font-size12" },
                { data: "projectTitle", title: "Project Title (Full)", className: "project-title text-left font-size12" },
                { data: "lastUpdated", title: "Last Updated", className: "last-updated text-left font-size12 nowrap", render: RenderDateYMD },
                { data: "studyTable", title: "Studies Requested", className: "studies-requested text-left font-size12"},
                { data: "treatmentArmsTable", title: "Treatment Arms Requested", className: "treatment-arms-requested text-left font-size12" },
                { data: "sampleTypesTable", title: "Biospecimen Types Requested", className: "sample-types-requested text-left font-size12" },
                { data: "source", title: "Source", className: "request-source text-left font-size12" },
            ];
        }
        /*let columns: any[] = [
            { data: "combinedId", title: "ID", className: "id text-left font-size12 nowrap", width: '110px' },
            { data: "status", title: "Status", className: "status text-left font-size12 nowrap", width: '110px' },
            { data: "submissionDate", title: "Date Submitted", className: "date-submitted text-left font-size12 nowrap", width: '110px', render: RenderDateYMD },
            { data: "requestorFullName", title: "Applicant", className: "requestor-name text-left font-size12 nowrap", width: '110px' },
            { data: "institutionName", title: "Institution", className: "institution-name text-left font-size12 nowrap", width: '110px' },
            { data: "shortTitle", title: "Project Title (Short)", className: "short-title text-left font-size12 nowrap", width: '200px' },
            { data: "projectTitle", title: "Project Title (Full)", className: "project-title text-left font-size12 nowrap", width: '200px' },
            { data: "lastUpdated", title: "Last Updated", className: "last-updated text-left font-size12 nowrap", width: '110px', render: RenderDateYMD },
            { data: "studyTable", title: "Studies Requested", className: "studies-requested text-left font-size12 nowrap", width: '110px' },
            { data: "treatmentArmsTable", title: "Treatment Arms Requested", className: "treatment-arms-requested text-left font-size12 nowrap", width: '110px' },
            { data: "sampleTypesTable", title: "Biospecimen Types Requested", className: "sample-types-requested text-left font-size12 nowrap", width: '110px' },
        ];*/


        this.dataTable = $('#sampleRequestForms-table').DataTable({
            //"dom": '<"top-controls"<"column-select"><"search-bar"f><"spacer"><"count-found"B>>rtip',
            "dom": '<"top-controls"<"column-select"><"reset-section">fB>rtip',
            autoWidth: true,
            info: false,
            paging: false,
            search: true,
            scrollX: true,
            scrollY: '60vh',
            scrollCollapse: true,
            orderCellsTop: true,
            language:
            {
                search: "Search:"
            },
            fixedColumns: {
                leftColumns: fixedColumns
            },
            columns: columns,
            buttons: [
                {
                    extend: 'csv',
                    text: '<i class="fas fa-file-download"></i>',
                    titleAttr: 'CSV',
                    charset: 'utf-8',
                    //exportOptions: {
                    //    columns: [1, 2, 3, 4]
                    //}
                }
            ],
            order: [[sortColumn, 'desc']],
            data: this.sampleRequestForms
        });

        $(".top-controls").addClass('row mx-0');

        $(".column-select").addClass('col-12 col-md-3 mr-3 px-0');
        $(".column-select").empty().html('<div id="column-select"></div>');

        $("#sampleRequestForms-table_filter").before($("#status-select"));
        $("#sampleRequestForms-table_filter").addClass("mr-auto");

        //$(".search-bar").addClass('col-12 col-md-3');

        //$(".spacer").addClass('col-12 col-md-3'); 

        $(".dt-buttons").addClass("align-self-end");
        $(".dt-buttons").before(`<span class="align-self-end mb-1 pl-1 table-message" id="sampleRequestForms-found-count"></span>`);

        /*$(".count-found").addClass('col-12 col-md-3 d-flex justify-content-end vertical-align-middle align-self-end mt-2 pr-0');
        //<span id="people-found-count" class="font-italic font-size12" > </span>
        $(".count-found").prepend('<div class=\"table-message d-inline-block align-self-center mr-2\"><span id=\"sampleRequestForms-found-count\"></span></div>');
        $(".count-found").prepend('<div class=\"table-message d-inline-block align-self-center mr-2\"></div>');*/

        if (!this.isAdmin) {
            this.tableColumnController = new TableColumnVisibilityController('#sampleRequestForms-table', '#column-select', [
                new TableColumnGroup("Status", true, [], "status"),
                new TableColumnGroup("Date Submitted", true, [], "date-submitted"),
                new TableColumnGroup("Applicant", true, [], "requestor-name"),
                new TableColumnGroup("Institution", true, [], "institution-name"),
                new TableColumnGroup("Project Title (Short)", true, [], "short-title"),
                new TableColumnGroup("Project Title (Full)", false, [], "project-title"),
                new TableColumnGroup("Last Updated", true, [], "last-updated"),
                new TableColumnGroup("Studies Requested", false, [], "studies-requested"),
                new TableColumnGroup("Treatment Arms Requested", false, [], "treatment-arms-requested"),
                new TableColumnGroup("Biospecimen Types Requested", false, [], "sample-types-requested")
            ]);
        }
        else {
            this.tableColumnController = new TableColumnVisibilityController('#sampleRequestForms-table', '#column-select', [
                new TableColumnGroup("Status", true, [], "status"),
                new TableColumnGroup("Date Submitted", true, [], "date-submitted"),
                new TableColumnGroup("Applicant", true, [], "requestor-name"),
                new TableColumnGroup("Institution", true, [], "institution-name"),
                new TableColumnGroup("Project Title (Short)", true, [], "short-title"),
                new TableColumnGroup("Project Title (Full)", false, [], "project-title"),
                new TableColumnGroup("Last Updated", true, [], "last-updated"),
                new TableColumnGroup("Studies Requested", false, [], "studies-requested"),
                new TableColumnGroup("Treatment Arms Requested", false, [], "treatment-arms-requested"),
                new TableColumnGroup("Biospecimen Types Requested", false, [], "sample-types-requested"),
                new TableColumnGroup("Request Source", true, [], "request-source")
            ]);
        }

        $('#sampleRequestForms-table').on('search.dt', (e, settings) => {
            this.setCountFoundLabel(this.dataTable.rows({ search: 'applied' }).count());
        });
        this.setCountFoundLabel(this.sampleRequestForms.length);
    }

    setCountFoundLabel(count: number): void {

        switch (count) {
            case 0: $('#sampleRequestForms-found-count').text("No Requests Found"); break;
            case 1: $('#sampleRequestForms-found-count').text("1 Request Found"); break;
            default: $('#sampleRequestForms-found-count').text(count + " Requests Found");
        }
    }

    refresh(): void {

        ['#status-dropdown'].forEach((id: string) => $(id).selectpicker("refresh"));

        this.dataTable.columns.adjust();
        this.tableColumnController.refresh();
    }

    triggerFilter(): void {

        let matchingTasks: SampleRequestFormData[] = [];

        let taskMatches: boolean;
        this.sampleRequestForms.forEach((sampleRequestForm: SampleRequestFormData) => {

            taskMatches = true; //must match all filters

            if (!sampleRequestForm.matchesFilter(GetSelectedDropdownValues('#status-dropdown'))) {
                taskMatches = false;
            }

            if (taskMatches) { matchingTasks.push(sampleRequestForm); }
        });

        this.updateTable(matchingTasks);
    }

    updateTable(matchingTasks: any[]): void {

        this.dataTable.clear();
        this.dataTable.rows.add(matchingTasks).draw();
        this.setCountFoundLabel(matchingTasks.length);
    }
}