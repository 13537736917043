export class RequestContact {
    FirstName: string;
    LastName: string;
    Institution: string;
    InstitutionType: string;
    Telephone: string;
    Email: string;

    constructor(firstname, lastname, institution, institutiontype, telephone, email) {
        this.FirstName = firstname;
        this.LastName = lastname;
        this.Institution = institution;
        this.InstitutionType = institutiontype;
        this.Telephone = telephone;
        this.Email = email;
    }
}

export class ShippingAddress {
    Street: string;
    Street2?: string;
    City: string;
    State: string;
    Zip: string;

    toString() {
        return `${this.Street}, ${this.Street2 ? this.Street2 +"," : ""} ${this.City}, ${this.State}, ${this.Zip}`
    }
}

export class RequestShipping {
    LaboratoryName?: string;
    FirstName: string;
    LastName: string;
    Telephone?: string;
    Email: string;
    Country: string;
    ShippingAddress?: ShippingAddress;
    ShippingAccountName: string;
    AccountNumber: string;
    SpecialInstructions?: string;
    AgreementContactFirstName: string;
    AgreementContactLastName: string;
    AgreementContactTelephone?: string;
    AgreementContactEmail: string;
}

export class RequestProjectDetails {
    ProjectTitle: string;
    ShortTitle: string;
    PINames: string[];
    Background: string;
    ScientificAims: string;
    Significance: string;
    Methods: string;
    Approach: string;
    SharingPlan: string;
    Timepoint: string;
    StartDate: string;
    CompletionDate: string;
    FundingAvailable: string;
    NIHCheck: boolean;
    AnticipatedNIHCheck: boolean;
    NIHAwardNumber?: string;
    //AnticipatedNIHAwardNumber?: string;
    OtherFederalAgencyCheck: boolean;
    AnticipatedOtherFederalAgencyCheck: boolean;
    OtherFederalAgencyName?: string;
    AnticipatedOtherFederalAgencyName: string;
    FoundationCheck: boolean;
    AnticipatedFoundationCheck: boolean;
    IndustryCheck: boolean;
    AnticipatedIndustryCheck: boolean;
    OtherCheck: boolean;
    AnticipatedOtherCheck: boolean;
    OtherFundingName?: string;
    AnticipatedOtherFudingName?: string;
    AnticipatedFundingCheck: string;
    AnticipatedFundingSubmission: string;
    AnticipatedFundingAward: string;
    ClinicalData: string;
    AnticipatedClinicalDataDate: string;
    AdditionalClinicalData: string;
}

export class SampleRow {
    Id?: number;
    Nickname: string;
    Study: string;
    SampleType: string;
    TreatmentArm: string;
    Quantity: string;
    Volume: string;
    Unit: string;
    Queries: string;
    Timepoint: string;
    OtherSpecifications?: string;
}

export class RequestAdditionalInfo {
    ParticipantDescription: string;
    AdditionalDetail?: string;
}

export class SampleRequestForm {
    RequestContact: RequestContact;
    RequestShipping: RequestShipping;
    RequestProjectDetails: RequestProjectDetails;
    SampleRows: SampleRow[];
    RequestAdditionalInfo: RequestAdditionalInfo;
}

export class SampleRequestStudy {
    Name: string;
    SampleType: string[];
    Timepoint: string[];
    TreatmentArm: string[];
}

export class SaveForm {
    Id?: number;
    Status: string;
    SubmissionDate?: Date;
    LastUpdated: Date;
    User_Id: number;

    StudyTable?: string;
    TreatmentArmsTable?: string;
    SampleTypesTable?: string;

    RequestorFirstName?: string;
    RequestorLastName?: string;
    InstitutionName?: string;
    InstitutionType?: string;
    RequestorTelephone?: string;
    RequestorEmail?: string;

    LaboratoryName?: string;
    ShippingFirstName?: string;
    ShippingLastName?: string;
    ShippingTelephone?: string;
    ShippingEmail?: string;
    ShippingCountry?: string;
    ShippingStreet?: string;
    ShippingStreet2?: string;
    ShippingCity?: string;
    ShippingState?: string;
    ShippingZip?: string;
    ShippingAccountName?: string;
    ShippingAccountNumber?: string;
    ShippingSpecialInstructions?: string;
    AgreementContactFirstName: string;
    AgreementContactLastName: string;
    AgreementContactTelephone?: string;
    AgreementContactEmail: string;

    ProjectTitle?: string;
    ShortTitle?: string;
    PINames?: string;
    Background?: string;
    ScientificAims?: string;
    Significance?: string;
    Methods?: string;
    Approach?: string;
    ParticipantDescription?: string;
    ClinicalData?: string;
    AnticipatedClinicalDataDate: Date;
    ClinicalDataAdditional?: string;

    SharingPlan?: string;
    BiospecimenUse?: string;
    StartDate: Date;
    CompletionDate: Date;
    FundingAvailable?: boolean;
    NIHFunding?: boolean;
    AnticipatedNIHFunding?: boolean;
    NIHAwardNumber?: string;
    OtherFederalFunding?: boolean;
    AnticipatedOtherFederalFunding?: boolean;
    OtherFederalName?: string;
    AnticipatedOtherFederalName?: string;
    FoundationFunding?: boolean;
    AnticipatedFoundationFunding?: boolean;
    IndustryFunding?: boolean;
    AnticipatedIndustryFunding?: boolean;
    OtherFunding?: boolean;
    AnticipatedOtherFunding?: boolean;
    OtherFundingName?: string;
    AnticipatedOtherFundingName?: string;
    AnticipatedFundingSubmissionCheck?: string;
    AnticipatedFundingSubmission?: Date;
    AnticipatedFundingAward?: Date;

    AdditionalDetail?: string;

}

export class SaveSampleForm {
    Id: number;
    UserId: number;
    SampleQueryName: string;
    Study: string;
    SampleType: string;
    TreatmentArm: string;
    Quantity: string;
    Volume: string;
    Unit: string;
    Timepoint: string;
    Queries: string;
    OtherSpecifications?: string;

    SampleRequestFormId?: number;
}

export class UserContactObj {
    firstName: string;
    lastName: string;
    email: string;
}
