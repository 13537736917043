import { CountUp } from 'countup.js';
import { plainToClass } from "class-transformer";
import 'waypoints/lib/jquery.waypoints.min';


export class SecureHomePage {

    constructor(summaryStatsJson: any) {

        //document.ready
        $(() => {

            //console.log(summaryStatsJson);

            let summaryStats: SummaryStats = plainToClass(SummaryStats, <SummaryStats>summaryStatsJson);

            $('#study-count').text(summaryStats.Studies)
            $('#participant-count').text(summaryStats.Participants)
            $('#types-count').text(summaryStats.SampleTypes)
            $('#sample-count').text(summaryStats.Samples)

            this.initializeCounters();

        });

    }

    initializeCounters(): void {

        (<any>$('.countup')).waypoint({
            handler: function () {
                const counter = new CountUp(this.element, this.element.textContent, { useEasing: false, duration: 1 });
                if (!counter.error) {
                    counter.start();
                }
                this.destroy();
            },
            offset: 'bottom-in-view'
        });
    }
}

export class SummaryStats {
    Studies: number;
    Participants: number;
    SampleTypes: number;
    Samples: number;
}