import { plainToClass } from 'class-transformer';
import { CountUp } from 'countup.js';
import { SpecimenSearchResult } from './sample-search.entities';
import { UserQuery } from '../../../shared/entities/user-query/user-query.entities';
import { SearchManagement } from './sample-search.search-management';

declare var $: any;

export class SampleSearchPage {

    filters: any[];
    rules: any;
    variableMap: Map<string, string>; //to map db variables to human readable
    filterMap: Map<string, string>; //to map numeric db options to human readable
    updatingRules: boolean = false;
    resultsTable: any;

    searchManagement: SearchManagement // for savedQueries
    adminSearchManagement: SearchManagement; // for all queries

    //constructor(filters: any[], rules: any) {
    constructor(filters: any[], savedQueries: any, allQueries: any, startInAdmin: boolean = false, queryId: string = "-1", isAdmin: boolean = false, summaryStatsJson: any) {

        $(() => {
            $('.search-results').hide();

            let summaryStats: SummaryStats = plainToClass(SummaryStats, <SummaryStats>summaryStatsJson);

            this.startCounter('participant-count', summaryStats.Participants);
            this.startCounter('sample-count', summaryStats.Samples);
            this.startCounter('aliquot-count', summaryStats.Aliquots);

            this.searchManagement = new SearchManagement(savedQueries, this);

            this.initFilters(filters);
            this.initRules();
            this.initQueryBuilder();
            this.initializeTable(isAdmin); // show download button if isAdmin/Reviewer

            this.adminSearchManagement = new SearchManagement(allQueries, this, true, startInAdmin, queryId);
        });
    }

    initFilters(filters: any[]) {

        this.variableMap = new Map();
        this.filterMap = new Map();

        this.filters = filters.map((filter: any) => {
            
            if (filter.plugin == "slider") {
                filter.plugin_config.min = +filter.plugin_config.min; //cast to number
                filter.plugin_config.max = +filter.plugin_config.max;
                filter.plugin_config.value = +filter.plugin_config.value;

                filter.onAfterSetValue = function (rule, value) {
                    var input = rule.$el.find('.rule-value-container input');
                    input.slider('setValue', value);
                    input.val(value); // don't know why I need it

                    //input.slider('tooltip', 'always');
                    //input.slider.addClass('test');
                }
            }

            //console.log(filter);
            this.variableMap.set(filter.id, filter.label.replace(/ /g, "_"));

            //configure filterMap to facilitate showing human-readable SQL
            if ((filter.type == "integer" || filter.type == "string") && (filter.input == "radio" || filter.input == "select")) {
                for (let key in filter.values) {
                    let value: any = "";
                    let label: string = "";
                    let optionRange: number[] = [-99, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 31, 99];
                    for (let i: number = 0; i <= optionRange.length; ++i) {
                        if (filter.values[key][optionRange[i] + ""] != undefined) {
                            label = filter.values[key][optionRange[i] + ""];
                            value = optionRange[i];
                            break;
                        }
                    }
                    if (label == "") {
                        console.log("Could not find label:", filter.values[key]);
                        console.log(filter.id + "@" + key, filter.values[key], label);
                    }
                    //console.log(filter.id + "@" + key, filter.values[key], label);
                    this.filterMap.set(filter.id + "@" + value, label);
                }
            }

            return filter;
        });

        //console.log(this.variableMap);
        //console.log(this.filterMap);
    }

    initRules(): any {
        this.rules = {
            condition: 'AND',
            rules: [
                {
                    "field": "PortalStatus",
                    "id": "PortalStatus",
                    "input": "select",
                    "operator": "equal",
                    "type": "integer",
                    "value": 1
                }
            ]
        };
    }

    initQueryBuilder() {

        //$('#builder').empty();

        $('#builder').queryBuilder({
            filters: this.filters,
            rules: this.rules,
            icons: {
                add_group: 'fas fa-plus-square',
                add_rule: 'fas fa-plus',
                remove_group: 'fas fa-minus-square',
                remove_rule: 'fas fa-trash-alt',
                error: 'fas fa-exclamation-circle'
            },
            lang: {
                delete_group: ' ',
                delete_rule: ' ',

            }
            //lang: {
            //    "conditions": {
            //        "AND": "ALL",
            //        "OR": "ANY"
            //    },
            //}
        });

        $('#builder').on('rulesChanged.queryBuilder', () => {

            //need to disable rule change listener while clearing and resetting rules
            if (this.updatingRules) {
                return;
            }

            var rules = $('#builder').queryBuilder('getSQL', 'named');
            //var rules = $('#builder').queryBuilder('getSQL', false);

            if (!rules) {
                $('.generated-sql').text("");
                $('#save-button').prop('disabled', true);
                return;
            }

            let formattedSql: string = rules.sql;

            //console.log(formattedSql);
            //console.log(rules);

            let labelsMap: Map<string, string> = new Map<string, string>();

            for (var key in rules.params) {
                if (rules.params.hasOwnProperty(key)) {

                    formattedSql = formattedSql.replace(":" + key, this.getFilterValue(key, rules.params[key]));

                    //update variables with human-readable
                    let filterId = this.extractFilterId(key);

                    if (!labelsMap.has(filterId)) {
                        labelsMap.set(filterId, this.variableMap.get(filterId));
                    }
                }
            }
            labelsMap.forEach((value, key, map) => {
                //formattedSql = formattedSql.replace(key, value);
                formattedSql = formattedSql.split(key).join(value);
            });


            $('#generated-sql').text(formattedSql);
            $('#save-button').prop('disabled', false);

            this.rules = $('#builder').queryBuilder('getRules');

        });
        $('#builder').trigger('rulesChanged.queryBuilder');

    }

    extractFilterId(key: string) {

        let filterId: string = key.substring(0, key.lastIndexOf("_"));
        return filterId;
    }

    getFilterValue(key: string, value: any) {

        let filterId: string = this.extractFilterId(key);
        let filterMapKey: string = filterId + "@" + value;

        let mappedValue: string = this.filterMap.get(filterMapKey);

        if (mappedValue) return "\"" + mappedValue + "\"";
        else return value;
    }

    search(): void {

        //console.log("search", this.rules);

        //contentType: "application/json; charset=utf-8",
        $.ajax({
            type: "POST",
            url: '/secure/sample-search/sample-search?handler=Search',
            dataType: "json",
            contentType: "application/json",
            data: JSON.stringify(this.rules),
            headers: { "RequestVerificationToken": $('input[name="__RequestVerificationToken"]').val() },
            success: (response) => {

                //console.log(response);

                let results: SpecimenSearchResult[] = plainToClass(SpecimenSearchResult, <SpecimenSearchResult[]>response);
                let patientSet: Set<string> = new Set<string>();
                let sampleSet: Set<string> = new Set<string>();

                results.forEach((result: SpecimenSearchResult) => {
                    result.initialize();
                    patientSet.add(result.Patient);
                    sampleSet.add(result.SampleId);
                });

                this.resultsTable.clear();
                this.resultsTable.rows.add(results);
                this.resultsTable.draw();

                $('.search-results').show();
                $('#query-results-table').DataTable().columns.adjust()

                this.startCounter('participant-count', patientSet.size);
                $('#participants-title').text("Participants");
                this.startCounter('sample-count', sampleSet.size);
                $('#samples-title').text("Samples");
                this.startCounter('aliquot-count', results.length);
                $('#aliquots-title').text("Aliquots");


                //console.log(results);
            },
            error: (response) => {
                console.log("error", response);
                alert("An unexpected error occurred. Please try again.");
            }
        });

        //JSON.stringify(this.getFormattedRules())
        //
    }

    saveSearch(): void {
        this.searchManagement.saveSearch(JSON.stringify(this.rules), $('#generated-sql').text() + "");
    }

    deleteSearch(): void {
        this.searchManagement.deleteSearch();
    }

    updateQueryBuilder(query: UserQuery): void {

        this.updatingRules = true;
        $('#builder').queryBuilder('reset');

        if (query == undefined) {
            this.initRules();
            $('#builder').queryBuilder('setRules', this.rules);
        }
        else {
            $('#builder').queryBuilder('setRules', JSON.parse(query.queryJson));
        }

        this.updatingRules = false;
        $('#builder').trigger('rulesChanged.queryBuilder');
    }

    initializeTable(isAdmin: boolean): void {
        let domString = '<"clear">lfrti';

        if (isAdmin) {
            domString = 'B<"clear">lfrtip'
        }

        //console.log(isAdmin);

        this.resultsTable = $('#query-results-table').DataTable({
            retrieve: true,
            dom: domString,
            autoWidth: false,
            info: false,
            paging: true,
            scrollX: true,
            searching: false,
            //scrollY: "500px",
            //scrollCollapse: true,
            language: {
                lengthMenu: "Show _MENU_ Aliquots"
            },
            columns: [
                { data: "Study", class: "text-left" },
                { data: "Barcode", },
                { data: "VialId", },
                { data: "Patient", class: "text-left"  },
                { data: "TimepointDisplay" },
                { data: "SampleType" },
                { data: "Status" },
                { data: "Volume" },
                { data: "Units" },
                { data: "ThawCount" }, //className: "text-center" },
                { data: "Quality" }
            ],
            buttons: [
                {
                    extend: 'csv',
                    text: '<i class="fas fa-file-download"></i>',
                    titleAttr: 'CSV',
                    charset: 'utf-8',
                    //exportOptions: {
                    //    columns: [1, 2, 3, 4]
                    //}
                }
            ],
        });
    }

    startCounter(elementId: string, value: number): void {
        if (value == undefined) { value = 0; }
        let counter = new CountUp(elementId, value, { useEasing: false, duration: 1 });
        counter.start();
    }

    searchById(): void {
        this.adminSearchManagement.searchById($("#search-input-id").val());
    }
}

export class SummaryStats {
    Studies: number;
    Participants: number;
    SampleTypes: number;
    Samples: number;
    Aliquots: number;
}