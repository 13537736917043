import { SampleRequestFormData } from './sample-request-form-data.entities'
import { SampleRequestCommentData } from './sample-request-form-data.entities'
import { FormatDate } from '../../../shared/scripts/date-functions'
import { plainToClass } from 'class-transformer';

declare var $: any;

export class RequestDashboardAdminModal {
    currentForm: SampleRequestFormData;
    allComments: SampleRequestCommentData[];
    currentComment: SampleRequestCommentData;

    constructor(allComments: SampleRequestCommentData[]) {
        this.allComments = allComments.sort((a, b) => b.id - a.id);   
        let self = this;

        // --- Tabs ---
        $("#admin-view-tabs").find(".nav-link").click(function () {
            // hide all sections
            $("#admin-modal-feedback").addClass("inactive-section");
            $("#admin-modal-update-status").addClass("inactive-section");
            $("#admin-modal-comments").addClass("inactive-section");

            // show the correct tab
            if ($(this).hasClass("admin-feedback-tab")) {
                $("#admin-modal-feedback").removeClass("inactive-section");
            }
            else if ($(this).hasClass("admin-update-tab")) {
                $("#admin-modal-update-status").removeClass("inactive-section");
            }
            else if ($(this).hasClass("admin-comment-tab")) {
                $("#admin-modal-comments").removeClass("inactive-section");
            }

            // make all nav-links inactive
            $("#admin-view-tabs").find(".nav-link").removeClass("active");

            // add active tab to the tab that was clicked
            $(this).addClass("active");
        });

        // --- Update Status ---
        $("#update-status-select").change(function () {
            $("#update-status-after").text($(this).val());

            if (self.validateUpdateStatus()) {
                $("#update-status-btn").removeClass("disabled");
            }
            else {
                $("#update-status-btn").addClass("disabled");
            }
        });

        $("#update-status-comment-input").on("keyup", function () {
            if (self.validateUpdateStatus()) {
                $("#update-status-btn").removeClass("disabled");
            }
            else {
                $("#update-status-btn").addClass("disabled");
            }
        });

        $("#update-status-btn").click(function () {
            // update the SampleRequestFormArray
            self.updateDatabase(self.currentForm, $("#update-status-select").val())
        })

        $("#update-status-source").change(function () {

            if (self.validateUpdateStatus()) {
                $("#update-status-btn").removeClass("disabled");
            }
            else {
                $("#update-status-btn").addClass("disabled");
            }
        })

        // --- Comments ---

        // only allow users to add a new comment if there is content in the new comment input
        $("#add-comment-input").on("keyup", function () {
            if ($("#add-comment-input").val() != "") {
                $("#add-comment-btn").removeClass("disabled");
            }
            else {
                $("#add-comment-btn").addClass("disabled");
            }
        });

        // create comment and post to database when button is clicked
        $("#add-comment-btn").click(function () {
            self.createCommentObj();
        })
    }

    public openUpdateStatusModal(formData: SampleRequestFormData) {
        // reset existing fields
        $("#update-status-comment-input").val("");
        $("#update-status-btn").addClass("disabled");

        $("#update-status-formId").text(formData.combinedId);
        $("#update-status-title-status").text(formData.status);
        $("#update-status-before").text(formData.status);

        $("#before-source").val(formData.source);
        $("#update-status-select").val(formData.status).change();

        if (formData.source != "" && formData.source != null) {
            $("#update-status-source").val(formData.source).change();
            $("#before-source-text").text(formData.source)
        }
        else {
            $("#update-status-source").val("--- No Source Selected ---").change();
            $("#before-source-text").text("None");
        }

        // set current form
        this.currentForm = formData;
    }

    validateUpdateStatus(): boolean {

        if ($("#update-status-comment-input").val() != "" && $("#before-source").val() != $("#update-status-source").val()) {
            if ($("#before-source").val() == "" && $("#update-status-source").val() == "--- No Source Selected ---") {
                // do nothing
            }
            else {
                return true
            }
        }

        if ($("#update-status-comment-input").val() != "" && $("#update-status-after").text() != $("#update-status-before").text()) {
            return true;
        }

        return false;

    }

    updateDatabase(formData: SampleRequestFormData, newStatus: string) {
        // update the formStatus
        let updatedForm = formData;
        updatedForm.status = newStatus;

        if ($("#update-status-source").val() == "--- No Source Selected ---") {
            updatedForm.source = null;
        }
        else {
            updatedForm.source = $("#update-status-source").val(); 
        }


        let parsedFormId = parseInt(formData.id);

        if (parsedFormId == NaN) {
            return;
        }

        let comment: SampleRequestCommentData = {
            id: 0,
            creationDate: new Date(),
            userId: 0, // set on backend
            username: "user", // set on backend
            beforeStatus: $("#update-status-before").text(),
            afterStatus: newStatus,
            comment: $("#update-status-comment-input").val(),
            sampleRequestFormId: parsedFormId
        }

        $("#loading-update-status").removeClass("inactive-section");
        $("#update-status-comment-input").val("");
        $("#update-status-btn").addClass("disabled");

        // ajax request to controller
        $.ajax({
            url: '/secure/request-dashboard/request-dashboard?handler=UpdateStatus',
            method: "POST",
            //dataType: "json",
            //contentType: "application/json",
            data: { formData: JSON.stringify(updatedForm), commentData: JSON.stringify(comment) },
            headers: { "RequestVerificationToken": $('input[name="__RequestVerificationToken"]').val() },
            success: (response) => {

                $("#loading-update-status").addClass("inactive-section");
                /*console.log(response);
                let udpatedForm: SampleRequestFormData = plainToClass(SampleRequestFormData, <SampleRequestFormData>response);
                console.log(updatedForm);*/
                $("#update-status-modal").modal("hide");

                // refresh page
                location.reload();

            },
            error: (response) => {
                console.log("error", response);
                alert("An unexpected error occurred. Please try again.");
            }
        });
    }

    public populateComments(formId: string) {
        // clear existing comments
        $("#admin-modal-comments-view").empty();
        $("#add-comment-input").val("");

        // parse Id
        let parsedId = parseInt(formId);

        if (parsedId != NaN) {

            let formComments = this.allComments.filter(comment => comment.sampleRequestFormId == parsedId);

            let commentHTML = ``;

            // create HTML object
            if (formComments.length > 0) {
                formComments.forEach((comment, i) => {
                    let evenClass = "odd";
                    if (i % 2 == 0) {
                        evenClass = "even";
                    }

                    commentHTML += `
                    <div class="comment-card ${evenClass}">
                        <div class="comment-content">
                            <div class="row justify-content-between">
                                <div class="col text-left">
                                    <p><b>${FormatDate(comment.creationDate)}</b></p>
                                </div>
                                <div class="col text-right">
                                    <p><span>${comment.beforeStatus}</span> <span><i class="fa-solid fa-arrow-right px-1"></i></span> <span>${comment.afterStatus}</span></p>
                                </div>
                            </div>
                            <div class="col-12 px-0">
                                <p>${comment.comment}</p>
                            </div>
                            <div class="col-12 px-0">
                                <p class="blue-icon">- <span>${comment.username}</span></p>
                            </div>
                        </div>
                    </div>
                    `;
                    if (i + 1 != formComments.length) {
                        commentHTML += `<hr />`;
                    }

                });


            }
            else {
                commentHTML += `
                <div class="comment-card">
                    <p>There are currently no comments for this form.</p>
                </div>
                `;
            }

            // fill comment modal
            $("#admin-modal-comments-view").append(commentHTML);
        }

    }

    createCommentObj() {
        let formIdString = $("#request-id").text().split("_")[0];

        let parsedId = parseInt(formIdString);

        if (parsedId != NaN) {
            let newComment: SampleRequestCommentData = {
                id: 0, // new item
                sampleRequestFormId: parsedId,
                comment: $("#add-comment-input").val(),
                creationDate: new Date(),
                userId: 0, // set on backend,
                username: "", // set on backend,
                afterStatus: $("#request-status").text(),
                beforeStatus: $("#request-status").text()
            }

            this.addComment(newComment);
        }

    }

    addComment(commentData: SampleRequestCommentData) {
        // show loading icon
        $("#loading-add-comment").removeClass("inactive-section");

        // reset comment field
        $("#add-comment-input").val("")
        $("#add-comment-btn").addClass("disabled");

        // ajax request to controller
        $.ajax({
            url: '/secure/request-dashboard/request-dashboard?handler=AddComment',
            method: "POST",
            //dataType: "json",
            //contentType: "application/json",
            data: { commentData: JSON.stringify(commentData) },
            headers: { "RequestVerificationToken": $('input[name="__RequestVerificationToken"]').val() },
            success: (response) => {

                console.log(response);
                // remove loading icon
                $("#loading-add-comment").addClass("inactive-section");

                // add comment to all comments object
                let newComment: SampleRequestCommentData = plainToClass(SampleRequestCommentData, <SampleRequestCommentData>response);
                // newest comment goes first
                this.allComments.unshift(newComment);

                // add comment to comment modal
                this.populateComments(newComment.sampleRequestFormId.toString());

            },
            error: (response) => {
                console.log("error", response);
                alert("An unexpected error occurred. Please try again.");
            }
        });

    }

    public populateFeedback(formId: string) {
        // clear existing comments
        $("#admin-feedback-table").empty();

        // parse Id
        let parsedId = parseInt(formId);

        if (parsedId != NaN) {

            let formComments = this.allComments.filter(comment => comment.sampleRequestFormId == parsedId && comment.beforeStatus != comment.afterStatus);

            let commentHTML = ``;

            // create HTML object
            if (formComments.length > 0) {
                commentHTML += `
                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th class="purple-bg" scope="col">Status</th>
                                <th class="purple-bg" scope="col">Days in Status</th>
                                <th class="purple-bg" scope="col">Date</th>
                                <th class="purple-bg" scope="col">Feedback</th>
                            </tr>
                        </thead>
                        <tbody>
                `;

                formComments.forEach((comment, i) => {
                    let startDate = new Date(comment.creationDate);
                    let endDate = new Date(Date.now());

                    if (i > 0) {
                        endDate = new Date(formComments[i - 1].creationDate);
                    }

                    let timeDifference = endDate.getTime() - startDate.getTime();
                    let dayDifference = Math.round(timeDifference / (1000 * 3600 * 24));

                    commentHTML += `
                        <tr>
                            <td>${comment.afterStatus}</td>
                            <td>${dayDifference}</td>
                            <td>${FormatDate(comment.creationDate)}</td>
                            <td>${comment.comment}</td>
                        </tr>
                    `;
                });

                commentHTML += `
                        </tbody>
                    </table>
                `;


            }
            else {
                commentHTML += `
                <div>
                    <p>There is currently no feedback for this request.</p>
                </div>
                `;
            }

            // fill comment modal
            $("#admin-feedback-table").append(commentHTML);
        }

    }

}