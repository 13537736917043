import { DoArraysOverlap, FormatStringByWordLength, Split, Join } from "../../../shared/scripts/array-functions";

export class SampleRequestFormData {
    // Database Info
    id: string;
    submissionDate: string;
    lastUpdated: string;
    status: string;
    user_Id: number;
    source: string;
    parentFormId: number;

    // Table Date
    studyTable: string;
    treatmentArmsTable: string;
    sampleTypesTable: string;

    // Requestor Info
    requestorFirstName: string;
    requestorLastName: string;
    institutionType: string;
    institutionName: string;
    requestorTelephone: string;
    requestorEmail: string;

    // Shipping Info
    laboratoryName: string;
    shippingFirstName: string;
    shippingLastName: string;
    shippingTelephone: string;
    shippingEmail: string;
    shippingCountry: string;
    shippingStreet: string;
    shippingStreet2: string;
    shippingCity: string;
    shippingZip: string;
    shippingState: string;
    shippingAccountName: string;
    shippingAccountNumber: string;
    shippingSpecialInstructions: string;
    agreementContactFirstName: string;
    agreementContactLastName: string;
    agreementContactTelephone: string;
    agreementContactEmail: string;

    // Project Approach
    projectTitle: string;
    shortTitle: string;
    piNames: string;
    background: string;
    scientificAims: string;
    significance: string;
    methods: string;
    approach: string;
    participantDescription: string;
    clinicalData: string;
    anticipatedClinicalDataDate: string;
    clinicalDataAdditional: string;

    // Admin Details
    sharingPlan: string;
    biospecimenUse: string;
    startDate: string;
    completionDate: string;
    fundingAvailable: boolean;
    nihFunding: boolean;
    nihAwardNumber: string;
    otherFederalFunding: boolean;
    otherFederalName: string;
    foundationFunding: boolean;
    industryFunding: boolean;
    otherFunding: boolean;
    otherFundingName: string;
    anticipatedNIHFunding: boolean;
    anticipatedOtherFederalFunding: boolean;
    anticipatedOtherFederalName: string;
    anticipatedFoundationFunding: boolean;
    anticipatedIndustryFunding: boolean;
    anticipatedOtherFunding: boolean;
    anticipatedOtherFundingName: string;
    anticipatedFundingSubmissionCheck: boolean;
    anticipatedFundingSubmission: string;
    anticipatedFundingAward: string;


    // Additional Info
    additionalDetail: string;
    

    // Virtuals
    requestorFullName: string;
    combinedId: string;
    combinedIdDisplay: string;

    constructor() { }

    matchesFilter(filters: string[]) {
        let taskMatches = false;

        filters.forEach(filter => {
            if (filter == this.status) {
                taskMatches = true;
            }
        })

        if (taskMatches) {
            return true;
        }
        else {
            return false;
        }


        /*if (!DoArraysOverlap(filter, this.status)) {
            //console.log("Status Update:")
            //console.log(status, this.status)
            return false;
        } else {
            return true;
        }*/
    }

    initializeData() {
        // RequestorFullName
        if (this.requestorFirstName == null || this.requestorLastName == null) {
            this.requestorFullName = "";
        }
        else {
            this.requestorFullName = `${this.requestorFirstName} ${this.requestorLastName}`;
        }

        // CombinedId
        let maxIdLength = 4;
        let leadingZeroId = "000" + this.id;
        let shortenedZeroId = leadingZeroId.substr(leadingZeroId.length - maxIdLength);

        if (this.requestorLastName == null) {
            this.combinedId = `${shortenedZeroId}`;
        }
        else {
            this.combinedId = shortenedZeroId + "_" + this.requestorLastName;
        }

        this.combinedIdDisplay = `<a onclick="page.openRequestModal('${this.combinedId}');">${this.combinedId}</a>`
    }

}

export class SampleRequestsData {
    id: number;
    sampleRequestFormId: number;
    sampleQueryName: string;
    study: string;
    sampleType: string;
    treatmentArm: string;
    quantity: string;
    volume: string;
    unit: string;
    timepoint: string;
    queries: string;
    otherSpecifications: string;
}

export class SampleRequestCommentData {
    id: number;
    sampleRequestFormId: number;
    creationDate: Date;
    comment: string;
    beforeStatus: string;
    afterStatus: string;
    username: string;
    userId: number;
}