import { SampleRequestStudy, SampleRow, ShippingAddress } from "./sample-request.entities";
import { RequestContact } from "./sample-request.entities";
import { RequestShipping } from "./sample-request.entities";
import { RequestProjectDetails } from "./sample-request.entities";
import { RequestAdditionalInfo } from "./sample-request.entities";
import { SampleRequestForm } from "./sample-request.entities";
import { RequestSamples } from "./sample-request.samples";

export class RequestReview {
    SampleRequestForm: SampleRequestForm;

    constructor() {

    }

    // generates review page
    runReview(requestSamples:RequestSamples) {
        this.createFormObj(requestSamples);

        // Contact Info
        $("#review-requestor-first-name").text(this.SampleRequestForm.RequestContact.FirstName);
        $("#review-requestor-last-name").text(this.SampleRequestForm.RequestContact.LastName);
        $("#review-institution-name").text(this.SampleRequestForm.RequestContact.Institution);
        $("#review-institution-type").text(this.SampleRequestForm.RequestContact.InstitutionType);
        $("#review-requestor-telephone").text(this.SampleRequestForm.RequestContact.Telephone);
        $("#review-requestor-email").text(this.SampleRequestForm.RequestContact.Email);

        // Shipment Info
        // convert shipping address
        let shippingAddress = this.SampleRequestForm.RequestShipping.ShippingAddress;
        let shippingString = `${shippingAddress.Street ? shippingAddress.Street + ", " : ""}${shippingAddress.Street2 ? shippingAddress.Street2 + ", " : ""}${shippingAddress.City ? shippingAddress.City + ", " : ""}${shippingAddress.State ? shippingAddress.State + ", " : ""}${shippingAddress.Zip ? shippingAddress.Zip : ""}`;

        $("#review-shipping-laboratory-name").text(this.SampleRequestForm.RequestShipping.LaboratoryName);
        $("#review-shipping-first-name").text(this.SampleRequestForm.RequestShipping.FirstName);
        $("#review-shipping-last-name").text(this.SampleRequestForm.RequestShipping.LastName);
        $("#review-shipping-telephone").text(this.SampleRequestForm.RequestShipping.Telephone);
        $("#review-shipping-email").text(this.SampleRequestForm.RequestShipping.Email);
        $("#review-shipping-country").text(this.SampleRequestForm.RequestShipping.Country);
        $("#review-shipping-address").text(shippingString);
        $("#review-shipping-special-instructions").text(this.SampleRequestForm.RequestShipping.SpecialInstructions);
        $("#review-shipping-account-name").text(this.SampleRequestForm.RequestShipping.ShippingAccountName);
        $("#review-shipping-account-number").text(this.SampleRequestForm.RequestShipping.AccountNumber);
        $("#review-shipping-agreement-first-name").text(this.SampleRequestForm.RequestShipping.AgreementContactFirstName);
        $("#review-shipping-agreement-last-name").text(this.SampleRequestForm.RequestShipping.AgreementContactLastName);
        $("#review-shipping-agreement-telephone").text(this.SampleRequestForm.RequestShipping.AgreementContactTelephone);
        $("#review-shipping-agreement-email").text(this.SampleRequestForm.RequestShipping.AgreementContactEmail);

        // Project Details
        $("#review-project-title").text(this.SampleRequestForm.RequestProjectDetails.ProjectTitle);
        $("#review-short-title").text(this.SampleRequestForm.RequestProjectDetails.ShortTitle);
        $("#review-pi-names").text(this.SampleRequestForm.RequestProjectDetails.PINames.join(", "));
        $("#review-background").text(this.SampleRequestForm.RequestProjectDetails.Background);
        $("#review-scientific-aims").text(this.SampleRequestForm.RequestProjectDetails.ScientificAims);
        $("#review-significance").text(this.SampleRequestForm.RequestProjectDetails.Significance);
        $("#review-methods").text(this.SampleRequestForm.RequestProjectDetails.Methods);
        $("#review-sample-size").text(this.SampleRequestForm.RequestProjectDetails.Approach);
        $("#review-clinical-data").text(this.SampleRequestForm.RequestProjectDetails.ClinicalData);
        $("#review-additional-clinical-data").text(this.SampleRequestForm.RequestProjectDetails.AdditionalClinicalData);
        $("#review-clinical-data-date").text(this.SampleRequestForm.RequestProjectDetails.AnticipatedClinicalDataDate == "" ? "N/A" : this.SampleRequestForm.RequestProjectDetails.AnticipatedClinicalDataDate);

        // Admin Details
        $("#review-data-sharing-plan").text(this.SampleRequestForm.RequestProjectDetails.SharingPlan);
        $("#review-timepoint").text(this.SampleRequestForm.RequestProjectDetails.Timepoint);
        $("#review-start-date").text(this.SampleRequestForm.RequestProjectDetails.StartDate);
        $("#review-end-date").text(this.SampleRequestForm.RequestProjectDetails.CompletionDate);
        $("#review-funding-available").text(this.SampleRequestForm.RequestProjectDetails.FundingAvailable);
        $("#review-nih-check").text(this.SampleRequestForm.RequestProjectDetails.NIHCheck ? "Yes" : "No");
        $("#review-nih-award-number").text(this.SampleRequestForm.RequestProjectDetails.NIHAwardNumber);
        $("#review-other-federal-check").text(this.SampleRequestForm.RequestProjectDetails.OtherFederalAgencyCheck ? "Yes" : "No");
        $("#review-other-agency").text(this.SampleRequestForm.RequestProjectDetails.OtherFederalAgencyName);
        $("#review-foundation-check").text(this.SampleRequestForm.RequestProjectDetails.FoundationCheck ? "Yes" : "No");
        $("#review-industry-check").text(this.SampleRequestForm.RequestProjectDetails.IndustryCheck ? "Yes" : "No");
        $("#review-other-check").text(this.SampleRequestForm.RequestProjectDetails.OtherCheck ? "Yes" : "No");
        $("#review-other-funding").text(this.SampleRequestForm.RequestProjectDetails.OtherFundingName);

        let isFunded: boolean;
        this.SampleRequestForm.RequestProjectDetails.FundingAvailable == "Yes" ? isFunded = true : isFunded = false;


        //Anticipated Funding
        $("#a-review-nih-check").text(this.SampleRequestForm.RequestProjectDetails.AnticipatedNIHCheck ? "Yes" : "No");
        //$("#a-review-nih-award-number").text(SampleRequestForm.RequestProjectDetails.AnticipatedNIHAwardNumber);
        $("#a-review-other-federal-check").text(this.SampleRequestForm.RequestProjectDetails.AnticipatedOtherFederalAgencyCheck ? "Yes" : "No");
        $("#a-review-other-agency").text(this.SampleRequestForm.RequestProjectDetails.AnticipatedOtherFederalAgencyName);
        $("#a-review-foundation-check").text(this.SampleRequestForm.RequestProjectDetails.AnticipatedFoundationCheck ? "Yes" : "No");
        $("#a-review-industry-check").text(this.SampleRequestForm.RequestProjectDetails.AnticipatedIndustryCheck ? "Yes" : "No");
        $("#a-review-other-check").text(this.SampleRequestForm.RequestProjectDetails.AnticipatedOtherCheck ? "Yes" : "No");
        $("#a-review-other-funding").text(this.SampleRequestForm.RequestProjectDetails.AnticipatedOtherFudingName);
        $("#a-review-application-submit").text(isFunded ? "N/A" : this.SampleRequestForm.RequestProjectDetails.AnticipatedFundingCheck);
        $("#a-review-application-submit-date").text(isFunded ? "N/A" : this.SampleRequestForm.RequestProjectDetails.AnticipatedFundingCheck == "Yes" ? this.SampleRequestForm.RequestProjectDetails.AnticipatedFundingSubmission : "");
        $("#a-review-application-award-date").text(isFunded ? "N/A" : this.SampleRequestForm.RequestProjectDetails.AnticipatedFundingAward);

        // Samples Requested

        // clear out any data that was previously on the page
        $("#review-samples-requested").find("table").remove();
        $("#hidden-sample-fields").empty();

        // Remove Unnecessary Rows

        // Clinical Data Anticipated Date
        if (this.SampleRequestForm.RequestProjectDetails.ClinicalData == "Requested" || this.SampleRequestForm.RequestProjectDetails.ClinicalData == "Approved") {
            $("#review-clinical-data-date-table-row").removeClass("inactive-section");

            if (this.SampleRequestForm.RequestProjectDetails.ClinicalData == "Requested") {
                $("#review-clinical-data-date-label").text("Anticipated Date of Approval for Clinical Data Request:");
            }

            if (this.SampleRequestForm.RequestProjectDetails.ClinicalData == "Approved") {
                $("#review-clinical-data-date-label").text("Anticipated Date for Receipt of Dataset:");
            }
        }
        else {
            $("#review-clinical-data-date-table-row").addClass("inactive-section");
        }

        // Clinical Data Additional Info
        if (this.SampleRequestForm.RequestProjectDetails.ClinicalData == "Not Yet Requested" || this.SampleRequestForm.RequestProjectDetails.ClinicalData == "Not Applicable") {
            $("#review-additional-clinical-table-row").removeClass("inactive-section");
        }
        else {
            $("#review-additional-clinical-table-row").addClass("inactive-section");
        }

        // Funding Sources
        if (this.SampleRequestForm.RequestProjectDetails.FundingAvailable == "Yes") {
            // funding available
            $("#review-funding-sources-table-row").removeClass("inactive-section");
            $("#a-review-funding-sources-table-row").addClass("inactive-section");
            $("#a-review-application-submit-table-row").addClass("inactive-section");
            $("#a-review-application-award-date-table-row").addClass("inactive-section");
            $("#a-review-application-submit-date-table-row").addClass("inactive-section");
        }
        else {
            // anticipated funding available
            $("#review-funding-sources-table-row").addClass("inactive-section");
            $("#a-review-funding-sources-table-row").removeClass("inactive-section");
            $("#a-review-application-submit-table-row").removeClass("inactive-section");
            $("#a-review-application-award-date-table-row").removeClass("inactive-section");

            if (this.SampleRequestForm.RequestProjectDetails.AnticipatedFundingCheck == "Yes") {
                $("#a-review-application-submit-date-table-row").removeClass("inactive-section");
            }
            else {
                $("#a-review-application-submit-date-table-row").addClass("inactive-section");
            }
        }

        // creates table for samples requested
        this.SampleRequestForm.SampleRows.forEach((sample, i) => {
            // format timepoint(s) and query string
            let timepointString = sample.Timepoint.split(",").join(", "); //remove

            let timepointHTMLTableContent = "";
            sample.Timepoint.split(",").forEach(timepoint => {
                timepointHTMLTableContent += `<tr><td>${timepoint}</td></tr>`;
            })

            let timepointHTMLTable = `
                <table class="table table-striped table-bordered inner-table">
                    <thead>
                        <tr>
                            <th>Timepoint(s)</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${timepointHTMLTableContent}
                    </tbody>
                </table> 
            `;

            let queryString = sample.Queries.split(",").join(", "); //remove

            let queryHTMLTableContent = "";
            sample.Queries.split(",").forEach(query => {
                queryHTMLTableContent += `<tr><td>${query}</td></tr>`;
            });

            let queryHTMLTable = `
                <table class="table table-striped table-bordered inner-table">
                    <thead>
                        <tr>
                            <th>Queries</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${queryHTMLTableContent}
                    </tbody>
                </table> 
            `;

            $("#review-samples-requested").append(
                `<table class="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col" colspan="2">Request #${i + 1}: ${sample.Nickname}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="table-label">Study:</td>
                                        <td>${sample.Study}</td>
                                    </tr>
                                    <tr>
                                        <td class="table-label">Biospecimen Type:</td>
                                        <td>${sample.SampleType}</td>
                                    </tr>
                                    <tr>
                                        <td class="table-label">Treatment Arm:</td>
                                        <td>${sample.TreatmentArm}</td>
                                    </tr>
                                    <tr>
                                        <td class="table-label">Number of Biospecimens Requested per Timepoint:</td>
                                        <td>${sample.Quantity}</td>
                                    </tr>
                                    <tr>
                                        <td class="table-label">Amount Needed per Biospecimen:</td>
                                        <td>${sample.Volume} ${sample.Unit}</td>
                                    </tr>
                                    <tr>
                                        <td class="table-label">Timepoint(s):</td>
                                        <td>
                                            ${timepointHTMLTable}                  
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="table-label">Queries:</td>
                                        <td>
                                            ${queryHTMLTable}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="table-label">Other Specifications:</td>
                                        <td>${sample.OtherSpecifications}</td>
                                    </tr>
                                </tbody>
                            </table>`
            );

            let sampleId = 0;
            if (sample.Id != null || sample.Id != undefined) {
                sampleId = sample.Id;
            }

            // used for data binding
            $("#hidden-sample-fields").append(`
                            <input name="UForm.SRId" value="${sampleId}" />
                            <input name="UForm.SRNickname" value="${sample.Nickname}" />
                            <input name="UForm.SRStudy" value="${sample.Study}" />
                            <input name="UForm.SRTreatmentArm" value="${sample.TreatmentArm}" />
                            <input name="UForm.SRSampleType" value="${sample.SampleType}" />
                            <input name="UForm.SRQuantity" value="${sample.Quantity}" />
                            <input name="UForm.SRVolume" value="${sample.Volume}" />
                            <input name="UForm.SRUnit" value="${sample.Unit}" />
                            <input name="UForm.SRTimepoint" value="${sample.Timepoint}" />
                            <input name="UForm.SRQueries" value="${sample.Queries}" />
                            <input name="UForm.SROtherSpecs" value="${sample.OtherSpecifications}" />
                        `);

        })

        // Additional Info
        $("#review-additional-population").text(this.SampleRequestForm.RequestAdditionalInfo.ParticipantDescription);
        $("#review-additional-detail").text(this.SampleRequestForm.RequestAdditionalInfo.AdditionalDetail);

        // Hidden Fields
        $("#hiddenPINames").val(this.SampleRequestForm.RequestProjectDetails.PINames.join("|"))
    }

    // creates object from the form input fields
    createFormObj(requestSamples: RequestSamples) {
        // Contact information
        // check to see if other is checked for institution type
        let institutionType = $("#institution-type-select").val().toString();
        if ($("#other-institution-type-input").val() != "") {
            institutionType = $("#other-institution-type-input").val().toString();
        }

        // create an object for the Requestor Contact
        let contactTelephone = "+" + $("#requestor-telephone-code").val().toString() + " " + $("#requestor-telephone").val().toString();
        let ContactInfoObj: RequestContact = {
            FirstName: $("#requestor-first-name").val().toString(),
            LastName: $("#requestor-last-name").val().toString(),
            Institution: $("#institution").val().toString(),
            InstitutionType: institutionType,
            Telephone: contactTelephone,
            Email: $("#requestor-email").val().toString()
        }

        // Shipment Information
        // Shipping Address
        let ShipmentAddressObj: ShippingAddress = {
            Street: $("#shipping-address").val().toString(),
            Street2: $("#shipping-address2").val().toString(),
            City: $("#shipping-city").val().toString(),
            State: $("#shipping-state").val().toString(),
            Zip: $("#shipping-zip").val().toString()
        }

        let shipTelephone = "+" + $("#shipping-telephone-code").val().toString() + " " + $("#shipping-telephone").val().toString();

        let ShipmentInfoObj: RequestShipping = {
            LaboratoryName: $("#shipping-laboratory-name").val().toString(),
            FirstName: $("#shipping-first-name").val().toString(),
            LastName: $("#shipping-last-name").val().toString(),
            Telephone: shipTelephone,
            Email: $("#shipping-email").val().toString(),
            Country: $("#shipping-country").val().toString(),
            ShippingAddress: ShipmentAddressObj,
            ShippingAccountName: $("#shipping-account-name").val().toString(),
            AccountNumber: $("#shipping-account").val().toString(),
            SpecialInstructions: $("#shipping-special-instructions").val().toString(),
            AgreementContactFirstName: $("#shipping-agreement-first-name").val().toString(),
            AgreementContactLastName: $("#shipping-agreement-last-name").val().toString(),
            AgreementContactTelephone: $("#shipping-agreement-telephone").val().toString(),
            AgreementContactEmail: $("#shipping-agreement-email").val().toString()
        }

        // Project Details Information
        // get PI names and place them into an array
        let piNames = [];
        $("#pi-names").find("span").map(function () {
            piNames.push($(this).text().toString());
        })

        let ProjectDetailsObj: RequestProjectDetails = {
            ProjectTitle: $("#project-title").val().toString(),
            ShortTitle: $("#project-short-title").val().toString(),
            PINames: piNames,
            Background: $("#project-background").val().toString(),
            ScientificAims: $("#project-scientific-aims").val().toString(),
            Significance: $("#project-significance").val().toString(),
            Methods: $("#project-methods").val().toString(),
            Approach: $("#project-sample-size").val().toString(),
            SharingPlan: $("#project-sharing-plan").val().toString(),
            Timepoint: $("#project-ability").val().toString(),
            StartDate: $("#project-start-date").val().toString(),
            CompletionDate: $("#project-end-date").val().toString(),
            FundingAvailable: $("#funding-available").val().toString(),
            NIHCheck: $("#funding-NIH").is(":checked"),
            AnticipatedNIHCheck: $("#a-funding-NIH").is(":checked"),
            NIHAwardNumber: $("#project-specify-award").val().toString(),
            //AnticipatedNIHAwardNumber: $("#a-project-specify-award").val().toString(),
            OtherFederalAgencyCheck: $("#funding-other-US").is(":checked"),
            AnticipatedOtherFederalAgencyCheck: $("#a-funding-other-US").is(":checked"),
            OtherFederalAgencyName: $("#project-specify-agency").val().toString(),
            AnticipatedOtherFederalAgencyName: $("#a-project-specify-agency").val().toString(),
            FoundationCheck: $("#funding-foundation").is(":checked"),
            AnticipatedFoundationCheck: $("#a-funding-foundation").is(":checked"),
            IndustryCheck: $("#funding-industry").is(":checked"),
            AnticipatedIndustryCheck: $("#a-funding-industry").is(":checked"),
            OtherCheck: $("#funding-other").is(":checked"),
            AnticipatedOtherCheck: $("#a-funding-other").is(":checked"),
            OtherFundingName: $("#project-other-specify").val().toString(),
            AnticipatedOtherFudingName: $("#a-project-other-specify").val().toString(),
            AnticipatedFundingCheck: $("#a-funding-available").val().toString(),
            AnticipatedFundingSubmission: $("#a-project-funding-submission").val().toString(),
            AnticipatedFundingAward: $("#a-project-funding-award").val().toString(),
            ClinicalData: $("#clinical-data").val().toString(),
            AnticipatedClinicalDataDate: $("#clinical-data-date").val().toString(),
            AdditionalClinicalData: $("#clinical-data-additional").val().toString()
        }

        let AdditionalInfoObj: RequestAdditionalInfo = {
            ParticipantDescription: $("#additional-info-participant-pop").val().toString(),
            AdditionalDetail: $("#additional-info-detail").val().toString(),
        }

        this.SampleRequestForm = {
            RequestContact: ContactInfoObj,
            RequestShipping: ShipmentInfoObj,
            RequestProjectDetails: ProjectDetailsObj,
            SampleRows: requestSamples.sampleRows,
            RequestAdditionalInfo: AdditionalInfoObj
        }
    }
}