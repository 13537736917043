var Highcharts = require('highcharts/highcharts');

Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    }
})

import { plainToClass } from 'class-transformer';
import { Patient, ComputedDisplayValues, DropdownOption, Sample, Aliquot } from './inventory-summary.entities';
import { FormatNumberWithCommas } from '../../../shared/scripts/number-functions';
import { BiorepositoryLookup } from './inventory-lookup';

export class InventorySummaryPage {

    allPatients: Patient[];
    ageRange: number[] = [];
    patientsByVisitChart: any;
    patientsBySampleType: any;
    samplesByVisitChart: any;
    aliquotsByVisitChart: any;

    ageSlider: any;
    minMaxAge: number[] = [];

    displayData: ComputedDisplayValues = new ComputedDisplayValues();

    colorBaseline: string = "#1C7293"
    colorArray: string[] = [
        "#152850",
        "#AEDCC0",
        "#628288",
        "#FBE2B2",
        "#5B5987",
        "#9BC8E9",
        "#A6979C",
        "#F4B942"
    ]

    timepoints: string[] = ["Baseline", "Day1", "Day3", "Day7or8", "Day14or15", "Day29or31", "Unscheduled"];

    totalColorArray: string[] = [];
    nonActiv1ColorArray: string[] = [];
    constructor(data: any) {
        $(() => {
            //console.log(data);

            this.allPatients = plainToClass(Patient, <Patient[]>data);
            this.allPatients.map((patient: Patient) => {
                patient.initialize();
                return patient;
            });

            //console.log(this.allPatients);
            this.initializeColorArrays();
            this.initializeFilters();

            this.initializeParticipantsByVisitChart();
            this.initializeParticipantsBySampleTypeChart();
            this.samplesByVisitChart = this.initializeSampleTypeByVisitChart('samples-byvisit-chart', 'Biospecimen');
            this.aliquotsByVisitChart = this.initializeSampleTypeByVisitChart('aliquot-byvisit-chart', 'Aliquot');

            this.updateDisplay();

        });
    }

    initializeColorArrays(): void {
        let colorCount = 0;
        for (let i = 0; i < this.timepoints.length; i++) {
            if (this.timepoints[i] == "Baseline") {
                this.totalColorArray.push(this.colorBaseline);
            }
            else {
                this.totalColorArray.push(this.colorArray[colorCount]);
                colorCount++
                if (colorCount >= this.colorArray.length) {
                    colorCount = 0;
                }
            }
        }
    }

    updateDisplay(): void {

        let studyValues: string[] = <string[]>($('#study-dropdown').val());
        let extendStudyIds: string[] = ["8", "10", "11"]
        let extendStudyMappingIds: string[][] = [["8", "9", "12", "13"], ["10", "9", "13", "14"], ["11", "12", "13", "14"]];
        for (let i = 0; i < studyValues.length; i++) {
            if (extendStudyIds.includes(studyValues[i])) {
                let mappingVals: string[] = extendStudyMappingIds[extendStudyIds.indexOf(studyValues[i])];
                mappingVals.forEach(val => {
                    if (!studyValues.includes(val)) {
                        studyValues.push(val);
                    }
                })
                
            }
        }

        this.displayData.Update(this.allPatients, studyValues, <string[]>($('#study-type-dropdown').val()), <string[]>($('#specimen-status-dropdown').val()), <string[]>($('#age-dropdown').val()), <string[]>($('#sex-dropdown').val()), <string[]>($('#race-dropdown').val()), <string[]>($('#ethnicity-dropdown').val()));

        //let data: ComputedDisplayValues = new ComputedDisplayValues(this.allPatients, <string[]>($('#study-dropdown').val()), <string[]>($('#study-type-dropdown').val()), <string[]>($('#specimen-status-dropdown').val()), this.ageRange[0], this.ageRange[1], $('#includeMissingAgeCheckbox').is(":checked"), <string[]>($('#sex-dropdown').val()), <string[]>($('#race-dropdown').val()), <string[]>($('#ethnicity-dropdown').val()));

        $('#value-total-participants').text(FormatNumberWithCommas(this.displayData.ParticipantCount + ""));
        $('#value-total-samples').text(FormatNumberWithCommas(this.displayData.SampleCount + ""));
        $('#value-total-aliquots').text(FormatNumberWithCommas(this.displayData.AliquotCount + ""));

        
        let patientsByVisitData: number[] = [];
        for (let i = 0; i < this.timepoints.length; i++) {
            patientsByVisitData.push(this.displayData[`ParticipantsByVisit_${this.timepoints[i]}`])
        }
        this.patientsByVisitChart.series[0].setData(patientsByVisitData);

        this.patientsBySampleType.series[6].setData(this.displayData.Participants_Timepoint1.GetDataArray(), false, true, false);
        this.patientsBySampleType.series[5].setData(this.displayData.Participants_Timepoint2.GetDataArray(), false, true, false);
        this.patientsBySampleType.series[4].setData(this.displayData.Participants_Timepoint3.GetDataArray(), false, true, false);
        this.patientsBySampleType.series[3].setData(this.displayData.Participants_Timepoint4.GetDataArray(), false, true, false);
        this.patientsBySampleType.series[2].setData(this.displayData.Participants_Timepoint5.GetDataArray(), false, true, false);
        this.patientsBySampleType.series[1].setData(this.displayData.Participants_Timepoint6.GetDataArray(), false, true, false);
        this.patientsBySampleType.series[0].setData(this.displayData.Participants_Timepoint7.GetDataArray(), false, true, false);
        this.patientsBySampleType.redraw();

        for (let i = 0; i < this.timepoints.length; i++) {
            this.samplesByVisitChart.series[i].setData(this.displayData[`Samples_Visit${this.timepoints[i]}`].GetDataArray(), false, true, false);
        }

        this.samplesByVisitChart.redraw();

        for (let i = 0; i < this.timepoints.length; i++) {
            this.aliquotsByVisitChart.series[i].setData(this.displayData[`Aliquots_Visit${this.timepoints[i]}`].GetDataArray(), false, true, false);
        }

        this.aliquotsByVisitChart.redraw();
    }

    initializeFilters(): void {
        //let minAge: number = Number.MAX_VALUE;
        //let maxAge: number = Number.MIN_VALUE;
        let studies: DropdownOption[] = []; let studyIds: number[] = [];
        let studyTypes: DropdownOption[] = []; let studyTypeIds: number[] = [];
        let specimenStatuses: DropdownOption[] = []; let specimenStatusIds: number[] = [];
        let sexes: DropdownOption[] = []; let sexIds: number[] = [];
        let races: DropdownOption[] = []; let raceIds: number[] = [];
        let ages: DropdownOption[] = []; let ageIds: number[] = [];

        let ethnicities: DropdownOption[] = []; let ethnicityIds: number[] = [];

        this.allPatients.forEach((patient: Patient) => {
            //if (patient.Age < minAge) { minAge = patient.Age; }
            //if (patient.Age > maxAge) maxAge = patient.Age;

            if (!studyIds.includes(patient.StudyId)) { studies.push(new DropdownOption(patient.StudyId, 'study')); studyIds.push(patient.StudyId); }
            if (!studyTypeIds.includes(patient.StudyTypeId)) { studyTypes.push(new DropdownOption(patient.StudyTypeId, 'study-type')); studyTypeIds.push(patient.StudyTypeId); }
            if (!sexIds.includes(patient.Sex)) { sexes.push(new DropdownOption(patient.Sex, 'sex')); sexIds.push(patient.Sex); }
            if (!raceIds.includes(patient.Race)) { races.push(new DropdownOption(patient.Race, 'race')); raceIds.push(patient.Race); }
            if (!ethnicityIds.includes(patient.Ethnicity)) { ethnicities.push(new DropdownOption(patient.Ethnicity, 'ethnicity')); ethnicityIds.push(patient.Ethnicity); }
            if (!ageIds.includes(patient.Age)) { ages.push(new DropdownOption(patient.Age, 'age')); ageIds.push(patient.Age); }

            patient.Samples.forEach((sample: Sample) => {

                sample.Aliquots.forEach((aliquot: Aliquot) => {
                    if (!specimenStatusIds.includes(aliquot.PortalStatus)) { specimenStatuses.push(new DropdownOption(aliquot.PortalStatus, 'specimen-status')); specimenStatusIds.push(aliquot.PortalStatus); }
                });
            });

        });

        // Remove 'Extra' ACTIV1 Studies
        let updatedStudies: DropdownOption[] = [];
        let extraActiv1StudyIds: number[] = [9, 12, 13, 14]
        studies.forEach(study => {
            if (!extraActiv1StudyIds.includes(study.Id)) {
                updatedStudies.push(study)
            }
        })

        //this.initializeAgeSlider(minAge, maxAge);
        this.initializeDropdown("#age-dropdown", ages);
        this.initializeDropdown('#study-dropdown', updatedStudies);
        this.initializeDropdown('#study-type-dropdown', studyTypes);
        this.initializeDropdown("#specimen-status-dropdown", specimenStatuses);
        this.initializeDropdown('#sex-dropdown', sexes);
        this.initializeDropdown('#race-dropdown', races);
        this.initializeDropdown('#ethnicity-dropdown', ethnicities);

    }

    initializeDropdown(dropdownId: string, entries: DropdownOption[]): void {

        //console.log(entries);

        $(dropdownId).selectpicker({
            dropupAuto: false,
            //actionsBox: true,
            selectedTextFormat: "count",

            countSelectedText: (numSelected, numTotal) => {
                if (numSelected == numTotal) { return "All   (n = " + numSelected + ")"; }
                else { return numSelected + " of " + numTotal + " Selected"; }
            }
        });

        $(dropdownId).on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => { this.updateDisplay(); });

        entries.sort((a, b) => (a.Value > b.Value) ? 1 : -1);

        entries.forEach((element: DropdownOption) => {
            let option: any = new Option(element.Value, element.Id + "", false, true);
            $(dropdownId).append(option);
        });
        $(dropdownId).selectpicker("refresh");

    }

    initializeAgeSlider(minAge: number, maxAge: number): void {

        this.minMaxAge = [minAge, maxAge];

        this.ageSlider = (<any>$("#age-slider")).slider({
            id: "age-slider",
            min: minAge, max: maxAge,
            range: true,
            value: [minAge, maxAge],
            tooltip: 'hide'
        });
        $("#age-slider").on("slide", (value: any) => {
            $('#age-values').text(value.value[0] + ' to ' + value.value[1]);
        });
        $("#age-slider").on("slideStop", (value: any) => {
            this.ageRange[0] = value.value[0];
            this.ageRange[1] = value.value[1];
            this.updateDisplay();
        });
        $('#age-values').text(minAge + ' to ' + maxAge);
        this.ageRange = [minAge, maxAge];
    }

    initializeParticipantsByVisitChart(): void {
        this.patientsByVisitChart = Highcharts.chart('participants-byvisit-chart', {
            chart: {
                type: 'column',
                styledMode: true,
                colorCount: 26,
            },
            credits: { enabled: false },
            title: { text: null },
            legend: { enabled: false },
            xAxis: {
                categories: [
                    'Baseline', //Baseline
                    'Day 1', //1
                    'Day 3', //2
                    'Day 7/8', //3
                    'Day 14/15', //4
                    'Day 29/31', //5
                    'Unscheduled' //6
                    //'Treatment<br>End',
                ],
                crosshair: true,
                labels: {
                    autoRotation: undefined
                },
                reversed: false
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Unique Participants'
                },
                labels: {
                    formatter: function () {
                        return Highcharts.numberFormat(this.value, 0, '.', ',');
                    }
                }
            },
            tooltip: {
                enabled: true,
                borderColor: "black",
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
                series: {
                    colorByPoint: true,
                    dataLabels: {
                        enabled: true,
                        crop: false,
                        overflow: 'none',
                        format: undefined,
                        formatter: function () {
                            return Highcharts.numberFormat(this.y, 0, '.', ',');
                        }
                    }
                }
            },
            series: [{
                data: new Array(6).fill(0)
            }]
        });
    }

    initializeParticipantsBySampleTypeChart(): void {

        this.patientsBySampleType = Highcharts.chart('participants-bysampletype-chart', {
            chart: {
                type: 'bar',
            },
            credits: { enabled: false },
            title: { text: null },
            xAxis: {
                type: 'category',
                categories: BiorepositoryLookup.SAMPLE_TYPES,
                title: { text: 'Biospecimen Types' },
                min: 0,
                max: BiorepositoryLookup.SAMPLE_TYPES.length - 1,
                tickInterval: 1,
                tickLength: 0,
            },
            yAxis: {
                min: 0,
                endOnTick: false, // helps with cropping data labels
                maxPadding: 0.2, // larger value has more space for data labels (default is 0.05)
                reversedStacks: false,
                title: { text: 'Participant Count' },
                labels: {
                    formatter: function () {
                        return Highcharts.numberFormat(this.value, 0, '.', ',');
                    }
                },
                stackLabels: {
                    enabled: true,
                    formatter: function () {
                        return Highcharts.numberFormat(this.total, 0, '.', ',');
                    },
                    inside: false, //label issues
                    //crop: false,
                    //overflow: 'none'
                }
            },
            legend: {
                enabled: true,
                reversed: false,
                title: {
                    text: '<div style="font-size: 10px; color: #666; font-weight: normal; width: 100%; text-align: center">Number of Biospecimen Timepoints</span>',
                },
                align: 'center',
                verticalAlign: 'top',
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                },
                bar: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    dataLabels: { enabled: false },
                }
            },
            series: [
            {
                name: '7',
                data: [4, 9, 2, 2, 1, 8, 9],
                legendColor: this.colorArray[5],
                color: this.colorArray[5]
            },
            {
                name: '6',
                data: [4, 9, 2, 2, 1, 8, 9],
                legendColor: this.colorArray[4],
                color: this.colorArray[4]
            },
            {
                name: '5',
                data: [4, 9, 2, 2, 1, 8, 9],
                legendColor: this.colorArray[3],
                color: this.colorArray[3]
            },
            {
                name: '4',
                data: [4, 9, 2, 2, 1, 8, 9],
                legendColor: this.colorArray[2],
                color: this.colorArray[2]
            },
            {
                name: '3',
                data: [1, 6, 2, 11, 6, 2, 1],
                legendColor: this.colorArray[1],
                color: this.colorArray[1]
            },
            {
                name: '2',
                data: [18, 2, 7, 1, 4, 2, 6],
                legendColor: this.colorArray[0],
                color: this.colorArray[0]
            },
            {
                name: '1',
                data: [8, 5, 3, 9, 2, 5, 8,],
                legendColor: this.colorBaseline,
                color: this.colorBaseline
            }]
        });
    }

    initializeSampleTypeByVisitChart(chartId: string, label: string): any {

        let chart: any = Highcharts.chart(chartId, {
            chart: {
                type: 'bar',
                styledMode: true,
            },
            credits: { enabled: false },
            title: { text: null },
            xAxis: {
                type: 'category',
                categories: BiorepositoryLookup.SAMPLE_TYPES,
                title: { text: 'Biospecimen Types' },
                min: 0,
                max: BiorepositoryLookup.SAMPLE_TYPES.length - 1,
                tickInterval: 1,
                tickLength: 0,
            },
            yAxis: {
                min: 0,
                endOnTick: false, // helps with cropping data labels
                maxPadding: 0.2, // larger value has more space for data labels (default is 0.05)
                reversedStacks: false,
                title: { text: label + ' Count' },
                labels: {
                    formatter: function () {
                        return Highcharts.numberFormat(this.value, 0, '.', ',');
                    }
                },
                stackLabels: {
                    enabled: true,
                    formatter: function () {
                        return Highcharts.numberFormat(this.total, 0, '.', ',');
                    },
                    inside: false, //label issues
                    //crop: false,
                    //overflow: 'none'
                }
            },
            legend: {
                enabled: false,
                reversed: false,
                title: {
                    text: '<div style="font-size: 10px; color: #666; font-weight: normal; width: 100%; text-align: center">Visits</span>',
                },
                align: 'center',
                verticalAlign: 'bottom',
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                },
                bar: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    dataLabels: { enabled: false },
                },
            },
            series: [
                {
                    name: 'Baseline',
                    data: [8, 5, 3, 9, 2, 5, 8,],
                    legendColor: this.colorBaseline
                    //legendColor: '#1E7394'
                },
                {
                    name: 'Day 1',
                    data: [18, 2, 7, 1, 4, 2, 6],
                    legendColor: this.colorArray[0]
                    //legendColor: '#152850'
                },
                {
                    name: 'Day 3',
                    data: [1, 6, 2, 11, 6, 2, 1],
                    legendColor: this.colorArray[1]
                    //legendColor: '#F4B942'
                },
                {
                    name: 'Day 7/8',
                    data: [4, 9, 2, 2, 1, 8, 9],
                    legendColor: this.colorArray[2]
                    //legendColor: '#AEDCC0'
                },
                {
                    name: 'Day 14/15',
                    data: [16, 9, 1, 6, 6, 2, 1],
                    legendColor: this.colorArray[3]
                    //legendColor: '#A6979C'
                },
                {
                    name: 'Day 29/31',
                    data: [16, 9, 1, 6, 6, 2, 1],
                    legendColor: this.colorArray[4]
                    //legendColor: '#A6979C'
                },         
                {
                    name: 'Unscheduled',
                    data: [11, 18, 9, 8, 4, 9, 2],
                    legendColor: this.colorArray[5]
                    //legendColor: '#DE4947'
                }
            ]
        });
        return chart;
    }

    clickedSampleTypeByVisitChartLegend(index: number): void {

        if (this.samplesByVisitChart.series[index].visible) {
            this.samplesByVisitChart.series[index].hide();
            this.aliquotsByVisitChart.series[index].hide();
            $('.item' + index).addClass('inactive');
        }
        else {
            this.samplesByVisitChart.series[index].show();
            this.aliquotsByVisitChart.series[index].show();
            $('.item' + index).removeClass('inactive');
        }
    }

    resetFilters(): void {
        //$('#includeMissingAgeCheckbox').is(":checked")

        ['#study-dropdown', '#study-type-dropdown', '#specimen-status-dropdown', '#sex-dropdown', '#race-dropdown', '#ethnicity-dropdown', '#age-dropdown'].forEach((dropdownId: string) => {
            $(dropdownId).selectpicker('selectAll');
            $(dropdownId).selectpicker('refresh');
        });

        /*this.ageSlider.slider("option", "values", this.minMaxAge);
        this.ageSlider.slider("refresh");

        $('#age-values').text(this.minMaxAge[0] + ' to ' + this.minMaxAge[1]);
        this.ageRange[0] = this.minMaxAge[0];
        this.ageRange[1] = this.minMaxAge[1];

        $('#includeMissingAgeCheckbox').prop("checked", true);*/

        this.updateDisplay();
    }
}
