import { RequestNavigation } from "./sample-request.navigation";
import { SaveForm, SaveSampleForm } from "./sample-request.entities";
import { SampleRequestFormData, SampleRequestsData } from '../../request-dashboard/scripts/sample-request-form-data.entities';
import { SampleRow } from './sample-request.entities';

declare var $: any;

export class RequestValidation {
    requestNavigation: RequestNavigation;

    constructor() {
        this.requestNavigation = new RequestNavigation();
    }

    // form validation
    validateForm() {

        // select all required input/select fields (custom word-count validation)
        let fields = $("input, select, textarea").filter("[required]:visible").not(".word-count");
        let allFields = $("input, select, textarea").filter(":visible");
        fields.map(function () {
            // check and see if required values are blank
            let inputValue = $(this).val()
            // show error message if the required fields are left blank
            if (inputValue == "" || inputValue == null) {
                $(this).siblings(".error-msg")?.show();
                $(this).addClass("is-invalid");
            }
            // hide any visible error messages if the required fields are completed
            else {
                $(this).siblings(".error-msg")?.hide();
                $(this).removeClass("is-invalid");
                $(this).addClass("is-valid");
            }
        })

        // word count validation
        this.wordCountValidate();

        // multi add validation
        this.multiAddValidate();

        // checkbox validation
        this.multicheckValidate();

        // samples requested validation (at least one)
        this.sampleRequestValidate();

        // check to see if there are any error messages present
        let errorMsgs = $(".error-msg").filter(":visible");
        //console.log(errorMsgs.length);
        // if errors are present, scroll to the top of the section
        if (errorMsgs.length > 0) {
            this.requestNavigation.scrollToTop();
            return false;
        } else {
            // if no errors, remove the validation classes
            allFields.map(function () {
                $(this).removeClass("is-invalid");
                $(this).removeClass("is-valid");
            })
            return true;
        }
    }


    wordCountValidate() {
        // get all the items that need to be validated
        let wordCountItems = $(".word-count:visible")

        // map through the elements
        wordCountItems.map(function () {
            // compare the word count to the allowed word count
            let maxCount = $(this).data("wordCount");
            let currentCount = $(this).val().toString().split(" ").length

            // if over the max word count or if the textbox is empty, add error msg
            if (currentCount > maxCount || $(this).val().toString() == "") {
                $(this).removeClass("is-valid");
                $(this).addClass("is-invalid");
                $(this).siblings(".error-msg").show();
            }
            else {
                $(this).removeClass("is-invalid");
                $(this).addClass("is-valid");
                $(this).siblings(".error-msg").hide();
            }
        })
    }

    multiAddValidate() {
        // select any visible multi add boxes
        let addedConent = $(".added-content:visible").find(".added-item");
        if (addedConent.length == 0) {
            $(".multi-add:visible").find(".error-msg").show();
            $(".multi-add-input:visible").addClass("is-invalid");
            $(".multi-add-input:visible").removeClass("is-valid");
        }
        else {
            $(".multi-add:visible").find(".error-msg").hide();
            $(".multi-add-input:visible").removeClass("is-invalid");
            $(".multi-add-input:visible").addClass("is-valid");
        }
    }

    multicheckValidate() {
        // check if funding sources is visible
        let isFundingSources = $("#funding-sources").is(":visible");
        let isAFundingSources = $("#a-funding-sources").is(":visible");

        // if funding-sources is visible, get all checkboxes in that div
        if (isFundingSources) {
            let fundingChecks = $("#funding-sources").find("input:checkbox");
            let isValid = false;
            // loop through existing checks and make sure at least one is checked
            fundingChecks.map(function () {
                if ($(this).is(":checked")) {
                    isValid = true;
                }
            })
            if (!isValid) {
                $("#funding-source-error").show();
            }
            else {
                $("#funding-source-error").hide();
            }
        }

        if (isAFundingSources) {
            let fundingChecks = $("#a-funding-sources").find("input:checkbox");
            let isValid = false;
            fundingChecks.map(function () {
                if ($(this).is(":checked")) {
                    isValid = true;
                }
            })
            if (!isValid) {
                $("#a-funding-source-error").show();
            }
            else {
                $("#a-funding-source-error").hide();
            }
        }
    }

    // checks to see that at least one sample is requested
    sampleRequestValidate() {
        // if the section of the form is visible
        let isSamplesSection = $("#samples-request").is(":visible");
        let isNewModal = $("#new-sample-form").is(":visible");
        let isUpdateModal = $("#update-sample-form").is(":visible");

        // verify that table data exists
        if (isSamplesSection && !isNewModal && !isUpdateModal) {
            let isPopulated = !$("#no-samples-text").is(":visible");

            // if no, add error msg
            if (!isPopulated) {
                $("#samples-request").find(".error-msg").show();
            }
            else {
                $("#samples-request").find(".error-msg").hide();
            }
        }

    }

    wordCount(textElement) {
        // get the current wordcount
        let textString = textElement.val().toString();
        let textArr = [];
        if (textString != "") {
            textArr = textString.split(" ");
            //console.log(textArr.length);
        }
        let wordCount = textArr.length;

        // display current wordcount
        let wordCountDisplay = textElement.next().find(".word-count-num");
        wordCountDisplay.text(wordCount);

        // get the allowed wordcount
        let maxWordCount = textElement.data("wordCount");

        // validate
        if (wordCount > maxWordCount) {
            textElement.addClass("is-invalid");
            wordCountDisplay.addClass("error-msg");
        }
        else {
            textElement.removeClass("is-invalid");
            wordCountDisplay.removeClass("error-msg");
        }
    }

    // updates database with current form data
    saveForm(requestedSamples: SampleRow[]) {
        // Form Data
        let formData: SaveForm = this.createSaveFormObject();
        let jsonData = JSON.stringify(formData);

        // Requested Samples
        let sampleArray: SaveSampleForm[] = [];
        requestedSamples.forEach(sample => {
            let requestedSample: SaveSampleForm = {
                Id: sample.Id,
                UserId: 0, // Fix on Backend
                SampleRequestFormId: 0, // Fix on Backend
                SampleQueryName: sample.Nickname,
                Study: sample.Study,
                SampleType: sample.SampleType,
                TreatmentArm: sample.TreatmentArm,
                Quantity: sample.Quantity,
                Volume: sample.Volume,
                Unit: sample.Unit,
                Timepoint: sample.Timepoint,
                Queries: sample.Queries,
                OtherSpecifications: sample.OtherSpecifications == "" ? null : sample.OtherSpecifications
            }

            sampleArray.push(requestedSample);
        })

        let jsonSampleData = JSON.stringify(sampleArray);

        // Show Saving Icon
        $("#saving-icon").removeClass("transparent-text");
        $("#saved-icon").addClass("transparent-text");
        $("#saving-text").text("Saving... ");
        $("#saving-text").removeClass("transparent-text");

        $.ajax({
            url: '/secure/sample-request/sample-request?handler=SaveForm',
            method: "POST",
            //dataType: "json",
            //contentType: "application/json",
            data: { formData: jsonData, sampleData: jsonSampleData },
            headers: { "RequestVerificationToken": $('input[name="__RequestVerificationToken"]').val() },
            success: (response) => {

                //console.log(response);

                // Display Saved Status
                $("#saving-icon").addClass("transparent-text");
                $("#saved-icon").removeClass("transparent-text");
                $("#saving-text").text("Saved ");
                // Hide the "Saved" text after 5 seconds (only if it still Says "Saved")
                setTimeout(() => {
                    if ($("#saving-text").text() == "Saved ") {
                        $("#saved-icon").addClass("transparent-text");
                        $("#saving-text").addClass("transparent-text");
                    }
                }, 3000)             

            },
            error: (response) => {
                console.log("error", response);
                alert("An unexpected error occurred. Please try again.");
            }
        });
    }

    submitForm(requestedSamples: SampleRow[]) {
        // Form Data
        let formData: SaveForm = this.createSaveFormObject();
        formData.Status = "Submitted";

        $("#loading-submit").removeClass("inactive-section");
        
        let jsonData = JSON.stringify(formData);

        // Requested Samples
        let sampleArray: SaveSampleForm[] = [];
        requestedSamples.forEach(sample => {
            let requestedSample: SaveSampleForm = {
                Id: sample.Id,
                UserId: 0, // Fix on Backend
                SampleRequestFormId: 0, // Fix on Backend
                SampleQueryName: sample.Nickname,
                Study: sample.Study,
                SampleType: sample.SampleType,
                TreatmentArm: sample.TreatmentArm,
                Quantity: sample.Quantity,
                Volume: sample.Volume,
                Unit: sample.Unit,
                Timepoint: sample.Timepoint,
                Queries: sample.Queries,
                OtherSpecifications: sample.OtherSpecifications == "" ? null : sample.OtherSpecifications
            }

            sampleArray.push(requestedSample);
        })

        let jsonSampleData = JSON.stringify(sampleArray);

        // Email Content
        let emailContent = $("#review").clone();
        $(emailContent).find(".inactive-section").remove();
        $(emailContent).find(".butn").remove();

        $.ajax({
            url: '/secure/sample-request/sample-request?handler=SaveForm',
            method: "POST",
            //dataType: "json",
            //contentType: "application/json",
            data: { formData: jsonData, sampleData: jsonSampleData, emailContent: emailContent.html() },
            headers: { "RequestVerificationToken": $('input[name="__RequestVerificationToken"]').val() },
            success: (response) => {

                //console.log(response);
                window.location.href = '/secure/submit';

            },
            error: (response) => {
                console.log("error", response);
                alert("An unexpected error occurred. Please try again.");
            }
        });
    }

    createSaveFormObject(): SaveForm {
        // Form Id
        let formId = 0;
        if ($("#hidden-form-id").val() != "") {
            let parsedId = parseInt($("#hidden-form-id").val());
            if (parsedId != NaN) {
                formId = parsedId;
            }
        }

        // Institution Type
        let institutionTypeString = $("#institution-type-select").val();
        if ($("#institution-type-select").val() == "Other") {
            institutionTypeString = $("#other-institution-type-input").val();
        }

        // Requestor Telephone
        let requestorTelephoneString = "";
        if ($("#requestor-telephone").val() != "") {
            requestorTelephoneString = "+" + $("#requestor-telephone-code").val() + " " + $("#requestor-telephone").val();
        }

        // Shipping Telephone
        let shippingTelephoneString = "";
        if ($("#shipping-telephone").val() != "") {
            shippingTelephoneString = "+" + $("#shipping-telephone-code").val() + " " + $("#shipping-telephone").val();
        }

        // Shipping Country
        let shippingCountry = null;
        if ($("#shipping-email").val() != "") {
            shippingCountry = $("#shipping-country").val();
        }

        // PI Names
        let piNamesString = "";
        let piNames = [];
        $("#pi-names").find("span").map(function () {
            piNames.push($(this).text().toString());
        })

        if (piNames.length > 0) {
            piNamesString = piNames.join("|");
        }

        // Date Formatting "2000-02-01"
        function parseDateString(stringToParse): Date {
            let timeString = "T00:00:00";
            stringToParse += timeString;
            return new Date(stringToParse);
        }

        let anticipatedClinicalDataDate = new Date("0001-01-01T00:00:00");
        if ($("#clinical-data-date").val() != "") {
            anticipatedClinicalDataDate = parseDateString($("#clinical-data-date").val());
        }

        let startDate = new Date("0001-01-01T00:00:00");
        if ($("#project-start-date").val() != "") {
            startDate = parseDateString($("#project-start-date").val());
        }

        let endDate = new Date("0001-01-01T00:00:00");
        if ($("#project-end-date").val() != "") {
            endDate = parseDateString($("#project-end-date").val());
        }

        let anticipatedFundingSubmissionDate = null;
        if ($("#a-project-funding-submission").val() != "") {
            anticipatedFundingSubmissionDate = parseDateString($("#a-project-funding-submission").val());
        }

        let anticipatedFundingAwardDate = null;
        if ($("#a-project-funding-award").val() != "") {
            anticipatedFundingAwardDate = parseDateString($("#a-project-funding-award").val());
        }

        // Bind Form Data to Object
        let formData: SaveForm = {
            Id: formId,
            Status: "In Draft",
            LastUpdated: new Date(),
            User_Id: 0, // Fix on Backend

            RequestorFirstName: $("#requestor-first-name").val() == "" ? null : $("#requestor-first-name").val(),
            RequestorLastName: $("#requestor-last-name").val() == "" ? null : $("#requestor-last-name").val(),
            InstitutionName: $("#institution").val() == "" ? null : $("#institution").val(),
            InstitutionType: institutionTypeString == "" ? null : institutionTypeString,
            RequestorTelephone: requestorTelephoneString == "" ? null : requestorTelephoneString,
            RequestorEmail: $("#requestor-email").val() == "" ? null : $("#requestor-email").val(),

            LaboratoryName: $("#shipping-laboratory-name").val() == "" ? null : $("#shipping-laboratory-name").val(),
            ShippingFirstName: $("#shipping-first-name").val() == "" ? null : $("#shipping-first-name").val(),
            ShippingLastName: $("#shipping-last-name").val() == "" ? null : $("#shipping-last-name").val(),
            ShippingTelephone: shippingTelephoneString == "" ? null : shippingTelephoneString,
            ShippingEmail: $("#shipping-email").val() == "" ? null : $("#shipping-email").val(),
            ShippingCountry: shippingCountry,
            ShippingStreet: $("#shipping-address").val() == "" ? null : $("#shipping-address").val(),
            ShippingStreet2: $("#shipping-address2").val() == "" ? null : $("#shipping-address2").val(),
            ShippingCity: $("#shipping-city").val() == "" ? null : $("#shipping-city").val(),
            ShippingState: $("#shipping-state").val() == "" ? null : $("#shipping-state").val(),
            ShippingZip: $("#shipping-zip").val() == "" ? null : $("#shipping-zip").val(),
            ShippingAccountName: $("#shipping-account-name").val() == "" ? null : $("#shipping-account-name").val(),
            ShippingAccountNumber: $("#shipping-account").val() == "" ? null : $("#shipping-account").val(),
            ShippingSpecialInstructions: $("#shipping-special-instructions").val() == "" ? null : $("#shipping-special-instructions").val(),
            AgreementContactFirstName: $("#shipping-agreement-first-name").val() == "" ? null : $("#shipping-agreement-first-name").val(),
            AgreementContactLastName: $("#shipping-agreement-last-name").val() == "" ? null : $("#shipping-agreement-last-name").val(),
            AgreementContactTelephone: $("#shipping-agreement-telephone").val() == "" ? null : $("#shipping-agreement-telephone").val(),
            AgreementContactEmail: $("#shipping-agreement-email").val() == "" ? null : $("#shipping-agreement-email").val(),


            ProjectTitle: $("#project-title").val() == "" ? null : $("#project-title").val(),
            ShortTitle: $("#project-short-title").val() == "" ? null : $("#project-short-title").val(),
            PINames: piNamesString == "" ? null : piNamesString,
            Background: $("#project-background").val() == "" ? null : $("#project-background").val(),
            ScientificAims: $("#project-scientific-aims").val() == "" ? null : $("#project-scientific-aims").val(),
            Significance: $("#project-significance").val() == "" ? null : $("#project-significance").val(),
            Methods: $("#project-methods").val() == "" ? null : $("#project-methods").val(),
            Approach: $("#project-sample-size").val() == "" ? null : $("#project-sample-size").val(),
            ParticipantDescription: $("#additional-info-participant-pop").val() == "" ? null : $("#additional-info-participant-pop").val(),
            ClinicalData: $("#clinical-data").val() == "" ? null : $("#clinical-data").val(),
            AnticipatedClinicalDataDate: anticipatedClinicalDataDate, // $("#clinical-data-date").val(),
            ClinicalDataAdditional: $("#clinical-data-additional").val() == "" ? null : $("#clinical-data-additional").val(),

            SharingPlan: $("#project-sharing-plan").val() == "" ? null : $("#project-sharing-plan").val(),
            BiospecimenUse: $("#project-ability").val() == "" ? null : $("#project-ability").val(),
            StartDate: startDate, //$("#project-start-date").val(),
            CompletionDate: endDate, //$("#project-end-date").val(),
            FundingAvailable: $("#funding-available").val() == "Yes" ? true : false,
            NIHFunding: $("#funding-NIH").is(":checked"),
            AnticipatedNIHFunding: $("#a-funding-NIH").is(":checked"),
            NIHAwardNumber: $("#project-specify-award").val() == "" ? null : $("#project-specify-award").val(),
            OtherFederalFunding: $("#funding-other-US").is(":checked"),
            AnticipatedOtherFederalFunding: $("#a-funding-other-US").is(":checked"),
            OtherFederalName: $("#project-specify-agency").val() == "" ? null : $("#project-specify-agency").val(),
            AnticipatedOtherFederalName: $("#a-project-specify-agency").val() == "" ? null : $("#a-project-specify-agency").val(),
            FoundationFunding: $("#funding-foundation").is(":checked"),
            AnticipatedFoundationFunding: $("#a-funding-foundation").is(":checked"),
            IndustryFunding: $("#funding-industry").is(":checked"),
            AnticipatedIndustryFunding: $("#a-funding-industry").is(":checked"),
            OtherFunding: $("#funding-other").is(":checked"),
            AnticipatedOtherFunding: $("#a-funding-other").is(":checked"),
            OtherFundingName: $("#project-other-specify").val() == "" ? null : $("#project-other-specify").val(),
            AnticipatedOtherFundingName: $("#a-project-other-specify").val() == "" ? null : $("#a-project-other-specify").val(),
            AnticipatedFundingSubmissionCheck: $("#a-funding-available").val() == "" ? null : $("#a-funding-available").val(),
            AnticipatedFundingSubmission: anticipatedFundingSubmissionDate, //$("#a-project-funding-submission").val(),
            AnticipatedFundingAward: anticipatedFundingAwardDate, //$("#a-project-funding-award").val(),

            AdditionalDetail: $("#additional-info-detail").val() == "" ? null : $("#additional-info-detail").val()

        }

        return formData;

    }
    
}