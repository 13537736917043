
// SCRIPTS

import { SecureHomePage } from './home/home.page'; export { SecureHomePage };
import { InventorySummaryPage } from './inventory-summary/scripts/inventory-summary.page'; export { InventorySummaryPage };
import { SampleSearchPage } from './sample-search/scripts/sample-search.page'; export { SampleSearchPage };
import { SampleRequestPage } from './sample-request/scripts/sample-request.page'; export { SampleRequestPage };
import { RequestDashboardPage } from './request-dashboard/request-dashboard.page'; export { RequestDashboardPage };
//import { ContactUsPage } from './contact-us/contact-us.page'; export { ContactUsPage };
//import { FundingPage } from './funding/funding.page'; export { FundingPage };
//import { NewsEventsPage } from './news-events/news-events.page'; export { NewsEventsPage };
////import { StudiesPage } from './studies/scripts/studies.page'; export { StudiesPage };
//import { AboutPage } from './about/about.page'; export { AboutPage };
//import { FaqsPage } from './faqs/faqs.page'; export { FaqsPage };
//import { ResearchPage } from './research/research.page'; export { ResearchPage };
//import { WhoWeArePage } from './who-we-are/who-we-are.page'; export { WhoWeArePage };
//import { ResearchWorksPage } from './research-works/research-works.page'; export { ResearchWorksPage };
//import { CommunityPage } from './community/community.page'; export { CommunityPage };
//import { LongCovidPage } from './long-covid/long-covid.page'; export { LongCovidPage };

// STYLES
import './home/home.scss';
import './inventory-summary/inventory-summary.scss';
import './sample-search/sample-search.scss';
import './request-dashboard/request-dashboard.scss';
import './resource/resource.scss';
//import './contact-us/contact-us.scss';
//import './funding/funding.scss';
//import './news-events/news-events.scss';
//import './about/about.scss';
//import './faqs/faqs.scss';
//import './research/research.scss';
//import './who-we-are/who-we-are.scss';
//import './research-works/research-works.scss';
//import './community/community.scss';
//import './long-covid/long-covid.scss';
import './sample-request/sample-request.scss';
import './sample-request/submit/submit.scss';