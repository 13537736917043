import { SampleRequestFormData, SampleRequestCommentData, SampleRequestsData } from './scripts/sample-request-form-data.entities'
import { RequestFormDataTable } from './scripts/data-table'
import { plainToClass } from "class-transformer";
import { RequestDashboardSampleInfo } from "./scripts/request-dashboard.samples";
import { SpecimenSearchResult } from '../sample-search/scripts/sample-search.entities';
import { SampleExportPDF } from './scripts/request-dashboard.pdf';
import { RequestDashboardAdminModal } from './scripts/request-dashboard.adminModal';
import { RequestDashboardStatusHistoryModal } from './scripts/request-dashboard.statusHistory';

declare var $: any;

export class RequestDashboardPage {
    userId: number;
    isAdmin: boolean;
    // General Form Data (Requestor Name, Project Details, etc)
    sampleRequestFormData: SampleRequestFormData[];
    // Requested Biospecimen Data (Study, Sample Type, etc)
    totalSampleRequests: SampleRequestsData[];
    // All Comments related to requests
    allComments: SampleRequestCommentData[];


    requestFormDataTable: RequestFormDataTable;
    requestDashboardSampleInfo: RequestDashboardSampleInfo;
    //requestDashboardUpdateStatus: RequestDashboardUpdateStatus;
    //requestDashboardComments: RequestDashboardComments;
    requestDashboardAdminModal: RequestDashboardAdminModal;
    requestDashboardStatusHistoryModal: RequestDashboardStatusHistoryModal;
    sampleExportPDF: SampleExportPDF;

    savedQueries: any[];

    constructor(userId: number, savedQueries: any[], userSampleRequestForms: any[], userSampleRequests: any[], userComments: any[], isAdmin: boolean, allQueries: any[], allSampleRequestForms: any[], allSampleRequests: any[], allRequestComments: any[], isReviewer: boolean) {
/*        console.log("UserId");
        console.log(userId);
        console.log("Saved Queries:");
        console.log(savedQueries);
        console.log("User Sample Request Forms");
        console.log(userSampleRequestForms);
        console.log("User Sample Requests");
        console.log(userSampleRequests);
        console.log("All Queries");
        console.log(allQueries);
        console.log("All Sample Request Forms");
        console.log(allSampleRequestForms);
        console.log("All Sample Requests");
        console.log(allSampleRequests);
        console.log("All Comments");
        console.log(allRequestComments);*/
        //console.log(isReviewer);
        //console.log(userComments);
        this.userId = userId;
        this.isAdmin = isAdmin;


        // data displayed in table based on Admin Status
        if (isAdmin || isReviewer) {
            this.sampleRequestFormData = plainToClass(SampleRequestFormData, <SampleRequestFormData[]>allSampleRequestForms);
            this.savedQueries = allQueries;
            this.totalSampleRequests = plainToClass(SampleRequestsData, <SampleRequestsData[]>allSampleRequests);
            this.allComments = plainToClass(SampleRequestCommentData, <SampleRequestCommentData[]>allRequestComments);
        }
        else {
            this.sampleRequestFormData = plainToClass(SampleRequestFormData, <SampleRequestFormData[]>userSampleRequestForms);
            this.savedQueries = savedQueries;
            this.totalSampleRequests = plainToClass(SampleRequestsData, <SampleRequestsData[]>userSampleRequests);
            this.allComments = plainToClass(SampleRequestCommentData, <SampleRequestCommentData[]>userComments);
        }

        // initialize data before sending it to the table generator (adds virtuals)
        this.sampleRequestFormData.forEach(form => form.initializeData());
        this.requestFormDataTable = new RequestFormDataTable(this.sampleRequestFormData, isAdmin);

        this.requestDashboardSampleInfo = new RequestDashboardSampleInfo();
        this.requestDashboardAdminModal = new RequestDashboardAdminModal(this.allComments);
        this.requestDashboardStatusHistoryModal = new RequestDashboardStatusHistoryModal(this.allComments);

        this.sampleExportPDF = new SampleExportPDF();

        //document.ready
        $(() => {

            let formData = this.sampleRequestFormData;
            let requestDashboardSampleInfo = this.requestDashboardSampleInfo;
            let requestDashboardSpecimens = this.totalSampleRequests;
            let requestDashboardAdminModal = this.requestDashboardAdminModal;
            let requestDashboardStatusHistoryModal = this.requestDashboardStatusHistoryModal;

            let sampleExportPDF = this.sampleExportPDF;
            let queries = this.savedQueries;

            // fixes scrolling issue when switching between modals
            $('.modal').on('shown.bs.modal', function (e) {
                $("body").addClass("modal-open")
            });

            $(".generate-new-form").click(function () {
                $("#loading-new-form").removeClass("inactive-section");
            })

            // printable PDF with <style> tags
            $(".pdf").click(function () {
                // is PDF ready?
                let readyToPDF = sampleExportPDF.readyToPDF($("#samples-requested").find(".query-number"));

                // send to print window
                if (readyToPDF) {
                    let pdfTitle = $("#request-id").text() + "_ACTIV_Biorepository_Request_PDF";
                    sampleExportPDF.printPDF(pdfTitle, "sample-info-printable");
                }
                else {
                    sampleExportPDF.openModalPDF($("#request-id").text());
                    setTimeout(runningCheckPDF, 1000); // takes a second for the loading modal to open
                }
            });

            $(".loaded-pdf").click(function () {
                // close the loading pdf modal
                $("#loading-pdf-modal").modal("hide");

                // reopen the request details modal
                $("#sample-info").modal("show");

                setTimeout(function () {
                    let pdfTitle = $("#request-id").text() + "_ACTIV_Biorepository_Request_PDF";
                    sampleExportPDF.printPDF(pdfTitle, "sample-info-printable");
                    //$("#sample-info").modal("hide");
                }, 500); // have to wait for modal to open

            });

            $(".continue-form").click(function () {
                // hide request details modal
                $("#sample-info").modal("hide");

                // show loading request modal
                $("#loading-form-modal").modal("show")

                // cancel any active HTTP requests (getting query numbers)
                let activeQueryHTTP = requestDashboardSampleInfo.getActiveQueries();
                activeQueryHTTP.forEach(request => {
                    request.abort();
                });
            });

            $(".delete-request").click(function () {
                // cancel any active HTTP requests (getting query numbers)
                let activeQueryHTTP = requestDashboardSampleInfo.getActiveQueries();
                activeQueryHTTP.forEach(request => {
                    request.abort();
                });

                // populate confirm delete modal
                let combinedId = $("#request-id").text();
                $("#confirm-delete-id").find("span").text(combinedId);
                $("#confirm-delete-id-text").text(combinedId);

                let formId = "0";
                let idString = parseInt(combinedId.split("_")[0]);

                if (idString != undefined && idString != NaN) {
                    formId = idString.toString();
                }

                $("#form-id-string").val(formId);

                // close the sample info modal
                $("#sample-info").modal("hide");

                // open the confirm delete request modal
                $("#confirm-delete-modal").modal("show");
            });

            $(".return-form").click(function () {
                // cancel any active HTTP requests (getting query numbers)
                let activeQueryHTTP = requestDashboardSampleInfo.getActiveQueries();
                activeQueryHTTP.forEach(request => {
                    request.abort();
                });

                // populate return request modal
                let combinedId = $("#request-id").text();
                $("#return-request-id-text").text(combinedId);

                let formId = "0";
                let idString = parseInt(combinedId.split("_")[0]);

                if (idString != undefined && idString != NaN) {
                    formId = idString.toString();
                }

                // set form fields
                $("#return-id-string").val(formId);

                // format email
                let emailHTML = $("#sample-info-printable").clone();
                $(emailHTML).find("img").remove();
                $(emailHTML).find(".inactive-section").remove();
                $(emailHTML).find("#request-status").text("Not Submitted");
                $(emailHTML).find("#request-submission-date").text("");

                $("#email-html-string").val(emailHTML.html());

                // close the sample info modal
                $("#sample-info").modal("hide");

                // open the confirm delete request modal
                $("#return-request-modal").modal("show");
            })

            $(".clone-form").click(function () {
                // cancel any active HTTP requests (getting query numbers)
                let activeQueryHTTP = requestDashboardSampleInfo.getActiveQueries();
                activeQueryHTTP.forEach(request => {
                    request.abort();
                });

                // populate return request modal
                let combinedId = $("#request-id").text();
                $("#clone-request-id-text").text(combinedId);

                let formId = "0";
                let idString = parseInt(combinedId.split("_")[0]);

                if (idString != undefined && idString != NaN) {
                    formId = idString.toString();
                }

                $("#clone-form-id-string").val(formId);

                // close the sample info modal
                $("#sample-info").modal("hide");

                // open the confirm delete request modal
                $("#clone-request-modal").modal("show");
            });

            $(".admin-modal").click(function () {
                // get current Id
                let formCombinedId = $("#request-id").text();
                let formIdString = formCombinedId.split("_")[0];

                let parsedId = parseInt(formIdString).toString();

                let form = formData.find(form => form.id == parsedId);

                requestDashboardAdminModal.populateFeedback(formIdString);
                requestDashboardAdminModal.openUpdateStatusModal(form);
                requestDashboardAdminModal.populateComments(formIdString);

                // close the sample info modal
                $("#sample-info").modal("hide");

                // open the update status modal
                $("#admin-edit-modal").modal("show");
            });

            $(".status-modal").click(function () {
                $("#status-history-id-text").text($("#request-id").text());

                // get current Id
                let formCombinedId = $("#request-id").text();
                let formIdString = formCombinedId.split("_")[0];

                let parsedId = parseInt(formIdString).toString();

                let form = formData.find(form => form.id == parsedId);

                requestDashboardStatusHistoryModal.populateStatusHistory(formIdString);

                // close the sample info modal
                $("#sample-info").modal("hide");

                // open the update status modal
                $("#status-history-modal").modal("show");
            });

            $(".return-request-btn").click(function () {
                $("#loading-return-form").removeClass("inactive-section");
            })

            $("#clone-request-btn").click(function () {
                $("#loading-clone-form").removeClass("inactive-section");
            })

            $(".confirm-delete-btn").click(function () {
                $("#loading-delete-form").removeClass("inactive-section");
            })

            function runningCheckPDF() {
                // see if the function still needs to be run
                let isLoadingModalOpen;

                // is the loading modal open? -> if not, stop the function
                if (!$("#loading-pdf-modal").hasClass("show")) {
                    isLoadingModalOpen = false;
                }
                else {
                    isLoadingModalOpen = true;
                }

                // is the content ready to pdf? -> if yes, stop the function
                let readyToPDF = sampleExportPDF.readyToPDF($("#samples-requested").find(".query-number"));
                //console.log("ready to pdf", readyToPDF);
                //console.log("modal open", isLoadingModalOpen)

                if (!readyToPDF && isLoadingModalOpen) {
                    setTimeout(runningCheckPDF, 1000);
                }

                if (readyToPDF && isLoadingModalOpen) {
                    //console.log("print");
                    $("#loading-icon").addClass("inactive-section");
                    $("#ready-to-pdf").removeClass("inactive-section");
                }

            }

        });

       
    }

    openRequestModal(id: string): void {
        // get Ids
        let combinedId = id
        let targetId = parseInt(combinedId.split("_")[0]);
        if (targetId == NaN) {
            targetId = 0;
        }

        // get form relevant form data based on id
        let targetFormData = this.sampleRequestFormData.find(x => x.combinedId == combinedId);
        // get all sample requests with matching formId
        let targetSampleData = this.totalSampleRequests.filter(x => x.sampleRequestFormId == targetId);
        // populate + display modal
        this.requestDashboardSampleInfo.openModal(this.userId, targetFormData, targetSampleData, this.savedQueries, this.isAdmin);
    }

}