import { UserQuery } from "../../../shared/entities/user-query/user-query.entities";
import { SampleSearchPage } from "../../secure.module";
import { plainToClass } from "class-transformer";


export class SearchManagement {

    savedQueries: UserQuery[];
    selectedSearchId: number;
    currentSearch: string;
    currentHumanReadable: string;

    constructor(savedQueries: any, private page: SampleSearchPage, isAdmin: boolean = false, startInAdmin: boolean = false, queryId: string = "-1") {

        this.savedQueries = [];
        this.savedQueries = plainToClass(UserQuery, <UserQuery[]>savedQueries);

        //console.log(isAdmin);
        //console.log(this.savedQueries);
        //console.log(`isAdmin: ${isAdmin}`);
        //console.log(`startInAdmin: ${startInAdmin}`);
        //console.log(`queryId: ${queryId}`);

        if (isAdmin) {
            this.initAdminMode(startInAdmin, queryId);
        }

        else {
            this.selectedSearchId = 0;
            $('#delete-button').hide();
            this.currentSearch = "";
            this.initDropdown();
        }

    }

    initDropdown(): void {

        var select = $('#searchOptions');

        select.empty();
        select.append($("<option></option>").attr("value", '0').attr("selected", "selected").text('--- New Search ---'));

        this.savedQueries.forEach((entry: UserQuery) => {
            select.append($("<option></option>").attr("value", entry.id).text(entry.queryName));
        });

        select.on('change', event => {
            let selected: string = <string>$(event.currentTarget).val();
            this.querySelected(+selected);
        });
    }

    querySelected(id: number): void {

        this.selectedSearchId = id;

        if (id == 0) {
            $('#delete-button').hide();
            this.page.updateQueryBuilder(undefined);
        }
        else {
            $('#delete-button').show();
            let selectedQuery: UserQuery = this.savedQueries.find((q: UserQuery) => q.id == id);
            if (selectedQuery != undefined) {
                this.page.updateQueryBuilder(selectedQuery);
            }
        }
    }

    saveSearch(search: string, humanReadable: string): void {

        this.selectedSearchId = +$('#searchOptions').val();

        this.currentSearch = search;
        this.currentHumanReadable = humanReadable;

        //a new search - prompt for name
        if (this.selectedSearchId == 0) {
            $("#inputName").val('');
            $("#userQueryTitleModal").modal({ backdrop: false });
        }
        else {
            this.persistSearch();
        }
    }

    persistSearch(): void {

        let query: UserQuery;

        if (this.selectedSearchId == 0) {
            query = new UserQuery();
            query.id = 0;
            query.queryName = $("#inputName").val() + "";
            $("#userQueryTitleModal").modal('hide');
        }
        else {
            query = this.savedQueries.find((q: UserQuery) => q.id == this.selectedSearchId);
        }

        query.queryJson = this.currentSearch;
        query.humanReadable = this.currentHumanReadable;

        //console.log("Query to Persist", query);
        
        $.ajax({
            type: 'POST',
            url: '/secure/sample-search/sample-search?handler=SaveSearch',
            dataType: "json",
            contentType: "application/json",            
            data: JSON.stringify(query),
            headers: { "RequestVerificationToken": $('input[name="__RequestVerificationToken"]').val() + "" },
            success: (response) => {

                if (query.id == 0) {
                    query.id = response;
                    this.savedQueries.push(query);
                    $('#searchOptions').append($("<option></option>").attr("value", query.id).attr("selected", "selected").text(query.queryName));
                    this.querySelected(query.id);
                }

                //console && console.log("save request succeeded", payload);
                //updateUserQueryMap(payload.id, payload.name, payload.query);
            },
            error: (error) => {
                console.log(error);
                //console && console.log("save request failed");
            }
            //processData: false,
            //async: true
        });
    }

    deleteSearch(): void {

        if (this.selectedSearchId != 0) {
            let query: UserQuery = new UserQuery();
            query.id = this.selectedSearchId;

            $.ajax({
                type: 'POST',
                url: '/secure/sample-search/sample-search?handler=DeleteSearch',
                dataType: "json",
                contentType: "application/json",
                data: JSON.stringify(query),
                headers: { "RequestVerificationToken": $('input[name="__RequestVerificationToken"]').val() + "" },
                success: (response) => {
                    //response is id of deleted 

                    this.savedQueries = this.savedQueries.filter((q: UserQuery) => q.id != response);
                    this.initDropdown();
                    this.querySelected(0);

                },
                error: (error) => {
                    console.log(error);
                    //console && console.log("save request failed");
                }
                //processData: false,
                //async: true
            });
        }
    }


    checkSearchName(): void {
        let name = $("#inputName").val().toString().trim();
        //if name is empty, don't allow save
        if (name.length == 0)
            $("#userQueryModalSaveButton").prop('disabled', true);
        else {
            let isUnique = true;
            //if a value entered, make sure is unique for this user
            this.savedQueries?.forEach((query: UserQuery) => {
                if (query.queryName == name) {
                    isUnique = false;
                }
            });
            $("#userQueryModalSaveButton").prop('disabled', !isUnique);
        }
    }

    initAdminMode(startInAdmin: boolean, queryId: string): void {
        let self = this;

        $("#review-mode-btn").click(function () {
            // switches format for admin mode
            self.toggleAdminMode();
        });

        // when the user enters content in the input field
        $("#search-input-id").on("input keypress", function () {
            // remove any error msgs
            $("#search-by-id-error").addClass("inactive-section");
            $("#search-input-id").removeClass("is-invalid");

            // check that field isn't blank
            if ($(this).val() != "") {
                $("#search-by-id-btn").removeClass("disabled");
            }
            else {
                $("#search-by-id-btn").addClass("disabled");
                $("#search-by-id-query-name-section").addClass("inactive-section");
            }
        });

        // if this page is being opened from the request dashboard, start in admin mode
        if (startInAdmin && queryId != "-1") {

            // show the admin mode
            this.toggleAdminMode();

            // toggle slider
            $("#review-mode-btn").prop("checked", true);

            // fill the search field with the Id
            $("#search-input-id").val(queryId).trigger("keypress");

            // search by id
            this.searchById(queryId);
        }
    }

    toggleAdminMode(): void {
        // remove any error msgs related to admin mode
        $("#search-by-id-error").addClass("inactive-section");
        $("#search-input-id").removeClass("is-invalid");
        $("#search-by-id-query-name-section").addClass("inactive-section");
        $("#search-by-id-query-name").text("");

        // reset input value
        $("#search-input-id").val("").trigger("keypress");

        // specific function based on on/off
        if ($(".toggle-text").hasClass("on")) {

            // reset query when turning admin mode off
            $('#searchOptions').val("0").change();
        }

        // toggle text color/bold
        $(".toggle-text").toggleClass("on");

        // swap out saved queries for search by id
        $("#my-searches-section").toggleClass("inactive-section");
        $("#search-by-id-section").toggleClass("inactive-section");
    }

    searchById(id: string): void {
        // convert to number
        let queryId : number = parseInt(id)

        // verify that number is valid (not NaN)
        if (queryId != NaN) {
            // check the query Database
            let selectedQuery: UserQuery = this.savedQueries.find((q: UserQuery) => q.id == queryId);

            // verify that id is valid
            if (selectedQuery != undefined) {
                // update query builder
                this.page.updateQueryBuilder(selectedQuery);
                $("#search-by-id-query-name").text(selectedQuery.queryName);
                $("#search-by-id-query-name-section").removeClass("inactive-section");
            }
            else {
                // if the query can't be found, show error msg
                $("#search-by-id-error").removeClass("inactive-section");
                $("#search-by-id-query-name-section").addClass("inactive-section");
                $("#search-by-id-query-name").text("");
                $("#search-input-id").addClass("is-invalid");
            }

        }

        // if the queryId is Not a Number, show error msg
        else {
            $("#search-by-id-error").removeClass("inactive-section");
            $("#search-input-id").addClass("is-invalid");
        }
    }
}