export class RequestComponents {
    constructor() {

    }

    multiAddBtn(selectedBtn: any) {
        // get the associated input field value associated with the multi add section
        let textVal = selectedBtn.parent().prev().children().children().val();
        // get the place where new content will be added
        let newAddSection = selectedBtn.parent().parent().parent().children(".added-content");

        // verify that text has been added into the input field
        if (textVal != "" && textVal != null) {
            // remove placeholder text if visible
            newAddSection.children(".added-content-placeholder:visible").hide();

            // new html element that will be added                   
            let newAdd = `<div class="added-item"><span><a class="rm-item"><i class="fa fa-times remove-item-x fa-lg"></i></a>${textVal}</span></div>`
            newAddSection.append(newAdd);

            // add event listener to remove button
            newAddSection.children().last().children().children().click(function () {
                let newAddSection = $(this).parent().parent().parent();
                $(this).parent().parent().remove();

                // show placeholder text if necessary
                if (newAddSection.children(".added-item").length == 0) {
                    newAddSection.children(".added-content-placeholder").show();
                }
            })
        }

        // reset the value of the input field to blank
        selectedBtn.parent().prev().children().children().val("");
    }

    multiCheckBtn(selectedBtn: any) {
        // get the associated input field value associated with the multi add section
        let textVal = $(".query-select:visible").val();

        // get the place where new content will be added
        let newAddSection = $(".added-queries:visible");

        // verify that text has been added into the input field
        if (textVal != "" && textVal != null) {
            // remove placeholder text if visible
            newAddSection.children(".added-query-placeholder:visible").hide();

            // new html element that will be added                   
            let newAdd = `<div class="added-item"><span><a class="rm-item"><i class="fa fa-times remove-item-x fa-lg"></i></a>${textVal}</span></div>`
            newAddSection.append(newAdd);

            // add event listener to remove button
            newAddSection.children().last().children().children().click(function () {
                // add the removed item back to the dropdown menu
                // link the Id to the text value
                let selectedText = $(this).parent().text();
                $(`.query-select:visible option[value="${selectedText}"]`).show();

                // remove item
                let newAddSection = $(this).parent().parent().parent();
                $(this).parent().parent().remove();


                // show placeholder text if necessary
                if (newAddSection.children(".added-item").length == 0) {
                    newAddSection.children(".added-query-placeholder").show();
                }
            })

            // remove the item from the dropdown list
            let selectedOption = $("#sample-queries").find(":selected");
            selectedOption.hide();
        }


        // reset the value of the input field to blank
        selectedBtn.parent().prev().children().children().val("");
    }
}