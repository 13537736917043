import { SampleRequestFormData, SampleRequestsData } from '../../request-dashboard/scripts/sample-request-form-data.entities';
import { UserQuery } from '../../../shared/entities/user-query/user-query.entities';
import { SampleRow, UserContactObj } from './sample-request.entities';
import { RequestSamples } from "./sample-request.samples";

export class RequestPrepopulate {
    requestSamples: RequestSamples;

    constructor() {
        this.requestSamples = new RequestSamples();
    }

    populateForm(formData: SampleRequestFormData, userData: UserContactObj) {

        // remove disabled progress tabs if data was entered
        if (formData.requestorFirstName != null) {
            $(".contact-info").removeClass("disable-tab");
            $(".shipping-info").removeClass("disable-tab");
        }

        if (formData.shippingFirstName != null) {
            $(".shipping-info").removeClass("disable-tab");
            $(".project-approach").removeClass("disable-tab");
        }

        if (formData.approach != null) {
            $(".project-approach").removeClass("disable-tab");
            $(".admin-details").removeClass("disable-tab");
        }

        if (formData.sharingPlan != null) {
            $(".admin-details").removeClass("disable-tab");
            $(".samples-request").removeClass("disable-tab");
        }

        if (formData.additionalDetail != null) {
            $(".additional-info").removeClass("disable-tab");
        }

        // set form id
        $("#hidden-form-id").val(formData.id);

        // Contact Info
        if (formData.requestorFirstName == null || formData.requestorFirstName == "") {
            $("#requestor-first-name").val(userData.firstName);
        }

        if (formData.requestorLastName == null || formData.requestorLastName == "") {
            $("#requestor-last-name").val(userData.lastName);
        }

        if (formData.requestorEmail == null || formData.requestorEmail == "") {
            $("#requestor-email").val(userData.email);
        }

        // Institution Type (if "Other")
        if (formData.institutionType != "Academic" && formData.institutionType != "Industry" && formData.institutionType != null) {
            $("#institution-type-select").val("Other").change();
            $("#other-institution-type-input").val(formData.institutionType);
        }

        // Country Codes / Telephone Numbers "+1 (919)-555-5555" || null
        if (formData.requestorTelephone != null) {
            let telephoneArr = formData.requestorTelephone.split(" ");
            let countryCode = telephoneArr.shift();
            let telephoneNumber = telephoneArr.join(" ");
            $("#requestor-telephone").val(telephoneNumber);
            $("#requestor-telephone-code").val(countryCode.replace("+", "")).change();
        }

        if (formData.shippingTelephone != null) {
            let telephoneArr = formData.shippingTelephone.split(" ");
            let countryCode = telephoneArr.shift();
            let telephoneNumber = telephoneArr.join(" ");
            $("#shipping-telephone").val(telephoneNumber);
            $("#shipping-telephone-code").val(countryCode.replace("+", "")).change();
        }


        // PI Names "Hannah Williams|John Smith" || null
        if (formData.piNames != null) {
            $("#no-pi-placeholder").hide(); // hide no-pi text

            let piNameArr = formData.piNames.split("|");
            piNameArr.forEach(name => {
                let newAdd = `<div class="added-item"><span><a class="rm-item"><i class="fa fa-times remove-item-x fa-lg"></i></a>${name}</span></div>`
                $("#pi-names").append(newAdd);
            });

            // add remove item functionality
            $("#pi-names").find(".rm-item").click(function () {
                $(this).parent().parent().remove();
                if ($("#pi-names").find(".added-item").length == 0) {
                    $("#no-pi-placeholder").show();
                }
            });
        }

        // Funding Available "true/false"
        if (formData.fundingAvailable == true) {
            $("#funding-available").val("Yes");
            $("#funding-sources").removeClass("inactive-section");

            if (formData.nihFunding == true) {
                $("#project-specify-award-section").removeClass("inactive-section");
            }

            if (formData.otherFederalFunding == true) {
                $("#project-specify-agency-section").removeClass("inactive-section");
            }

            if (formData.otherFunding == true) {
                $("#project-other-specify-section").removeClass("inactive-section");
            }
        }
        else if (formData.fundingAvailable == false && formData.sharingPlan != null) {
            $("#funding-available").val("No");
            $("#a-funding-sources").removeClass("inactive-section");

            if (formData.anticipatedOtherFederalFunding == true) {
                $("#a-project-specify-agency-section").removeClass("inactive-section");
            }

            if (formData.anticipatedOtherFunding == true) {
                $("#a-project-other-specify-section").removeClass("inactive-section");
            }
        }
        else {
            $("#funding-available").val("").change(); // no saved data for question
        }

        // Has Award Been Submitted
        if (formData.anticipatedFundingSubmissionCheck && formData.anticipatedFundingSubmission != "" && formData.anticipatedFundingSubmission != null) {
            $("#a-project-submission-section").removeClass("inactive-section");
        }

        // Non Nullable Dates "0001-01-01T00:00:00"
        // startDate
        if (formData.startDate == "0001-01-01T00:00:00") {
            $("#project-start-date").val("");
        }
        // completionDate
        if (formData.completionDate == "0001-01-01T00:00:00") {
            $("#project-end-date").val("");
        }
        // anticipatedClinicalDataDate
        if (formData.anticipatedClinicalDataDate == "0001-01-01T00:00:00") {
            $("#clinical-data-date").val("");
        }

        // Clinical Data 
        if (formData.clinicalData == "Not Yet Requested" || formData.clinicalData == "Not Applicable") {
            $("#clinical-data-additional-section").removeClass("inactive-section");
        }

        if (formData.clinicalData == "Requested" || formData.clinicalData == "Approved") {
            $("#clinical-data-date-section").removeClass("inactive-section");

            if (formData.clinicalData == "Requested") {
                $("#clinical-data-date-label").text("Anticipated Date of Approval for Clinical Data Request:");
            }

            if (formData.clinicalData == "Approved") {
                $("#clinical-data-date-label").text("Anticipated Date for Receipt of Dataset:");
            }
        }

        // activate word count for those that require it
        $(".word-count").trigger("keypress");
    }

    prepopulateModal() {
        $("#sample-nickname").val("Fostamatinib");
        $("#sample-study").val("ACTIV4 Host Tissue").change();
        $("#sample-type").val("EDTA Plasma").change();
        $("#sample-treatment-arm").val("Fostamatinib").change();
        $("#sample-quantity").val("200");
        $("#sample-volume").val("500");
        $("#sample-units").val("μl");
        // get all the timepoint-checks
        let timepointChecks = $(".timepoint-check");
        timepointChecks.each(function () {
            if ($(this).val() == "Day 1") {
                // no nothing
            } else {
                $(this).prop('checked', true);
            }
        })
        //$(".timepoint-check").first().prop('checked', true);
        $("#sample-query-list").children().hide();
        let newQueryAdd = `<div class="added-item"><span><a class="rm-item"><i class="fa fa-times remove-item-x fa-lg"></i></a>Fostamatinib</span></div>`
        $("#sample-query-list").append(newQueryAdd);

        $("#sample-query-list").find(".rm-item").click(function () {
            $(this).parent().parent().remove();
            if ($("#sample-query-list").find(".added-item").length == 0) {
                $("#sample-query-list").children(".added-query-placeholder").show();
            }
        });
        $("#sample-other-specifications").val("active treatment");
    }
}