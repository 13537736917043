import { SampleRow } from './sample-request.entities';
import { RequestValidation } from "./sample-request.validation";
import { SampleRequestStudy } from './sample-request.entities';

export class RequestSamples {
    requestValidation: RequestValidation;
    sampleRows: SampleRow[];
    ACTIV1: SampleRequestStudy;
    ACTIV4a: SampleRequestStudy;
    ACTIV4c: SampleRequestStudy;
    ACTIV4HostTissue: SampleRequestStudy;

    constructor() {
        this.requestValidation = new RequestValidation();
        this.sampleRows = [];
        this.ACTIV1 = {
            Name: "ACTIV1 Immune Modulators",
            SampleType: ["EDTA Plasma", "PAXgene", "Serum"],
            Timepoint: ["Baseline", "Day 3", "Day 8", "Day 15", "Day 29", "Unscheduled Visits"],
            TreatmentArm: ["Abatacept and Remdesivir", "Cenicriviroc and Remdesivir", "Infliximab and Remdesivir", "Placebo and Remdesivir"]
        }

        this.ACTIV4a = {
            Name: "ACTIV4a Inpatient",
            SampleType: ["Citrated Plasma", "EDTA Plasma", "P100", "Packed Cells", "PAXgene","Serum"],
            Timepoint: ["Baseline", "Day 3", "Day 7", "Day 14"],
            TreatmentArm: ["Crizanlizumab + SGLT2i Inhibitor + SOC", "Crizanlizumab + SOC", "Prophylactic Heparin", "Prophylactic Heparin + No P2Y12 Inhibitor (Arm B)", "Prophylactic Heparin + P2Y12 Inhibitor (Arm C)", "P2Y12 Inhibitor + SGLT2i Inhibitor + SOC", "P2Y12 Inhibitor + SOC", "SGLT2i Inhibitor + SOC", "SOC", "Therapeutic Heparin", "Therapeutic Heparin + No P2Y12 Inhibitor (Arm A)", "Therapeutic Heparin + P2Y12 Inhibitor (Arm D)", "Withdrawal Prior to Treatment"]
        }

        this.ACTIV4c = {
            Name: "ACTIV4c Convalescent",
            SampleType: ["Citrated Plasma", "Dried Blood Spot", "EDTA Plasma", "P100", "Packed Cells", "PAXgene", "Serum"],
            Timepoint: ["Baseline", "Day 31"],
            TreatmentArm: ["Apixaban", "Placebo", "Withdrawal Prior to Treatment"]
        }

        this.ACTIV4HostTissue = {
            Name: "ACTIV4d Host Tissue",
            SampleType: ["Citrated Plasma", "EDTA Plasma", "Serum"],
            Timepoint: ["Baseline", "Day 1", "Day 3", "Day 14"],
            TreatmentArm: ["TRV027", "TXA127", "Placebo", "Withdrawal Prior to Treatment"]
        }
    }

    populateSampleTable(formSampleData: any, savedQueries: any) {
        // Biospecimen Rows "SampleQuery[]" || length = 0
        if (formSampleData.length > 0 && formSampleData != null) {
            let sampleRowArr: SampleRow[] = [];

            formSampleData.forEach(sample => {
                // convert query ids to query names
                let queries = sample.queries.split(", ").join().split(",");
                let queryNames = [];
                queries.forEach(queryIdString => {
                    let queryId = parseInt(queryIdString);
                    if (queryId != NaN) {
                        let foundQuery = savedQueries.find(query => query.id == queryId);
                        if (foundQuery != null) {
                            queryNames.push(foundQuery.queryName);
                        }
                    }
                });


                let otherSpecsText = "";
                if (sample.otherSpecifications != null) {
                    otherSpecsText = sample.otherSpecifications;
                }

                // define new sample row
                let newSampleRow: SampleRow = {
                    Id: sample.id,
                    Nickname: sample.sampleQueryName,
                    Study: sample.study,
                    SampleType: sample.sampleType,
                    TreatmentArm: sample.treatmentArm,
                    Quantity: sample.quantity,
                    Unit: sample.unit,
                    Volume: sample.volume,
                    Timepoint: sample.timepoint.split(", ").join(","),
                    Queries: queryNames.join(","),
                    OtherSpecifications: otherSpecsText
                };

                // add sample to array (chance that a sample row has no valid queries[deleted])
                //console.log(queryNames);
                if (queryNames.length > 0) {
                    sampleRowArr.push(newSampleRow);
                }
            });

            // show data on page
            this.populateSamples(sampleRowArr);
        }
    }

    populateSamples(sampleRows: SampleRow[]) {
        if (sampleRows.length > 0) {
            $("#no-samples-text").hide();

            sampleRows.forEach(sample => {
                this.sampleRows.push(sample);
            });

            this.createTable(this.sampleRows);
        }
    }

    saveSample() {
        // verify that all required fields are filled out correctly
        let timepointString = this.sampleTimepointConversion(); // validation checking for timepoint checkboxes
        let queryString = this.queryStringConversion(); // validation checking for quereies
        let isValid = this.requestValidation.validateForm()

        // if all required fields are validated
        if (isValid) {
            // create a new sample row object
            let newSampleRow: SampleRow = {
                Nickname: $("#sample-nickname").val().toString(),
                Study: $("#sample-study").val().toString(),
                SampleType: $("#sample-type").val().toString(),
                TreatmentArm: $("#sample-treatment-arm").val().toString(),
                Quantity: $("#sample-quantity").val().toString(),
                Unit: $("#sample-units").val().toString(),
                Volume: $("#sample-volume").val().toString(),
                Timepoint: timepointString,
                Queries: queryString,
                OtherSpecifications: $("#sample-other-specifications").val().toString()
            }

            // add it to the SampleRows array
            this.sampleRows.push(newSampleRow);

            // add item to table
            this.createTable(this.sampleRows);

            // clear field inputs
            let fields = $("input, textarea").filter(":visible");
            fields.map(function () {
                $(this).val("");
            })
            this.emptyDropdowns($("#sample-type"), $("#sample-treatment-arm"), $("#sample-timepoint-group"));

            // reset query list
            $("#sample-queries").children().show();

            // remove no-sample text if necessary
            $("#no-samples-text").hide("slow");

            // hide modal
            $("#new-sample-form").modal("hide");
        }   
    }

    sampleTimepointConversion(): string {
        // find any checked timepoints
        let checkedTimepoints = $(".timepoint-check:checked").filter(":visible");

        // convert to string values
        let timepoints = [];
        checkedTimepoints.map(function () {
            // grab the text value for the label following the checkboxes
            timepoints.push($(this).next().text())
        })

        let timepointString = timepoints.join(",");

        if (timepointString == "") {
            // make sure there are not multiple error msgs
            $("#sample-timepoint-group:visible")?.find(".error-msg").remove();
            $("#sample-timepoint-group:visible")?.append(`
                            <p class="error-msg">Please select at least one timepoint for this request.</p>
                        `)

            $("#update-timepoint-group:visible")?.find(".error-msg").remove();
            $("#update-timepoint-group:visible")?.append(`
                            <p class="error-msg">Please select at least one timepoint for this request.</p>
                        `)
        }
        else {
            $("#sample-timepoint-group:visible")?.find(".error-msg").remove();
            $("#update-timepoint-group:visible")?.find(".error-msg").remove();
        }

        return timepointString;

    }

    queryStringConversion(): string {
        // get all listed queries in the box
        let queryElements = $(".added-item:visible");
        let queryElementsArr = [];
        queryElements.map(function () {
            queryElementsArr.push($(this).children().text());
        })
        let queryString = queryElementsArr.join(",");

        // if no query is selected
        if (queryElementsArr.length == 0) {
            $(".multi-check:visible")?.find(".error-msg").remove();
            $(".multi-check:visible")?.append(`
                            <p class="error-msg">Please select at least query for this request.</p>
                        `)
            $(".multi-check:visible")?.find("#sample-queries").addClass("is-invalid");
            $(".multi-check:visible")?.find("#update-queries").addClass("is-invalid");
        }
        else {
            $(".multi-check:visible")?.find(".error-msg").remove();
            $(".multi-check:visible")?.find("#sample-queries").addClass("is-valid");
            $(".multi-check:visible")?.find("#update-queries").addClass("is-valid");
            $(".multi-check:visible")?.find("#sample-queries").removeClass("is-invalid");
            $(".multi-check:visible")?.find("#update-queries").removeClass("is-invalid");
            return queryString;
        }
    }

    // converts the SampleRow Array into an HTML table
    createTable(rows) {
        // empty current table
        $("#samples-request").find("tbody").empty();

        // create new table with data in the array
        rows.forEach((rowObj, i) => {
            $("#samples-request").find("tbody").append(`
                                <tr>
                                    <td>${i + 1}</td>
                                    <td>${rowObj.Nickname}</td>
                                    <td><a class="btn btn-info review-line no-decor">Review</a></td>
                                    <td><a class="btn btn-danger rm-line no-decor">Delete</a></td>
                                </tr>
                            `)
        })

        // locally scope class object to use inside of callback
        var self = this;
        // add functionality to remove line items from table
        $(".rm-line").click(function() {
            self.removeLine($(this));
        })

        $(".review-line").click(function () {
            self.reviewLine($(this));
        })

    }

    emptyDropdowns(typeDropdown, treatmentDropdown, timepointDropdown) {
        // remove existing dropdown values
        typeDropdown.empty();
        typeDropdown.append(`<option value="">--Select a Sample Type--</option>`);
        treatmentDropdown.empty();
        treatmentDropdown.append(`<option value="">--Select a Treatment Arm--</option>`);
        timepointDropdown.empty();

        // empty query list
        let newAddSection = $(".added-queries");
        newAddSection.empty();
        newAddSection.append(`
                        <p class="added-query-placeholder">No Selected Queries</p>
                    `)

        // empty timepoint list
        $("#sample-timepoint-group").empty();

        // clear other fields
        let otherFields = $("#new-sample-form").find("input");
        otherFields.map(function () {
            $(this).val("");
        })

        // reshow dropdown list of queries
        $(".query-select").children().show();
    }

    // delete rows from the samples table
    removeLine(el) {
        // get the index of the item
        let index = parseInt(el.parent().prev().prev().prev().text()) - 1;

        // remove this index from the SampleRowArray
        this.sampleRows.splice(index, 1);

        // re-create table after removing data
        this.createTable(this.sampleRows);

        // add text stating to add items if SampleRowArray is empty
        if (this.sampleRows.length == 0) {
            $("#no-samples-text").show("slow");
        }
    }

    // updates information in the "review" modal for updating existing entered samples
    reviewLine(el) {
        // remove errors
        $("#update-sample-form").find(".error-msg").hide();

        // remove .is-invalid and is.valid
        $("#update-sample-form").find("input, select, textarea").removeClass("is-valid").removeClass("is-invalid");

        // get the index of the item
        let index = parseInt(el.parent().prev().prev().text()) - 1;

        // get the Sample Row associated with this line
        let updateinfo = this.sampleRows[index];

        // update dropdowns with correct fields
        let selectedStudy = updateinfo.Study;
        let updateTypeDropdown = $("#update-type");
        let updateTreatmentDropdown = $("#update-treatment-arm");
        let updateTimepointDropdown = $("#update-timepoint-group");

        this.createDropdowns(selectedStudy, updateTypeDropdown, updateTreatmentDropdown, updateTimepointDropdown);

        // fill the modal fields with the information from the line
        $("#update-row-index").text(index + 1)
        $("#update-nickname").val(updateinfo.Nickname);
        $("#update-study").val(updateinfo.Study);
        $("#update-type").val(updateinfo.SampleType);
        $("#update-treatment-arm").val(updateinfo.TreatmentArm);
        $("#update-quantity").val(updateinfo.Quantity);
        $("#update-volume").val(updateinfo.Volume);
        $("#update-units").val(updateinfo.Unit);
        $("#update-other-specifications").val(updateinfo.OtherSpecifications);

        // fill in timepoint checkboxes
        let timepointArr: string[] = updateinfo.Timepoint.split(",");
        timepointArr.forEach(el => {
            $(`.timepoint-check[value="${el}"]`).prop("checked", true)
        })

        // fill in query box
        $("#update-query-list").empty();
        $("#update-query-list").append(`
                        <p class="added-query-placeholder">No Selected Queries</p>
                    `);
        let queryArr: string[] = updateinfo.Queries.split(",");
        queryArr.forEach(el => {
            // make sure there is data in this field
            if (el != "") {
                $("#update-query-list").append(`
                                <div class="added-item"><span><a class="rm-item no-decor"><i class="fa fa-times remove-item-x fa-lg"></i></a>${el}</span></div>
                            `)
                $("#update-queries").find(`option[value="${el}"]`).hide();

                // add the event listener to the added queries
                $("#update-query-list").last().find(".rm-item").click(function () {
                    // add the removed item back to the dropdown menu
                    $(`.query-select:visible option[value="${$(this).parent().text()}"]`).show();

                    // remove item
                    let newAddSection = $(this).parent().parent().parent();
                    $(this).parent().parent().remove();


                    // show placeholder text if necessary
                    if (newAddSection.children(".added-item").length == 0) {
                        newAddSection.children(".added-query-placeholder").show();
                    }
                });

                // remove the added-query-placeholder
                $(".added-query-placeholder").addClass("inactive-section");
            } 
        })


        // show modal
        $("#update-sample-form").modal("show");
    }

    createDropdowns(selectedStudy, typeDropdown, treatmentDropdown, timepointDropdown) {
        // remove any existing values in the dropdown menus
        this.emptyDropdowns(typeDropdown, treatmentDropdown, timepointDropdown);

        // ACTIV4a
        if (selectedStudy == "ACTIV4a") {
            this.ACTIV4a.SampleType.forEach(el => {
                typeDropdown.append(`<option value="${el}">${el}</option>`)
            })
            typeDropdown.prop("disabled", false);

            this.ACTIV4a.TreatmentArm.forEach(el => {
                treatmentDropdown.append(`<option value="${el}">${el}</option>`)
            })
            treatmentDropdown.prop("disabled", false);

            this.ACTIV4a.Timepoint.forEach(el => {
                timepointDropdown.append(`
                                <div class="form-check">
                                    <input class="form-check-input small-check timepoint-check" type="checkbox" value="${el}"/>
                                    <label class="form-check-label">${el}</label>
                                </div>
                            `)
            })
        }

        // ACTIV4c
        else if (selectedStudy == "ACTIV4c") {
            this.ACTIV4c.SampleType.forEach(el => {
                typeDropdown.append(`<option value="${el}">${el}</option>`)
            })
            typeDropdown.prop("disabled", false);

            this.ACTIV4c.TreatmentArm.forEach(el => {
                treatmentDropdown.append(`<option value="${el}">${el}</option>`)
            })
            treatmentDropdown.prop("disabled", false);

            this.ACTIV4c.Timepoint.forEach(el => {
                timepointDropdown.append(`
                                <div class="form-check">
                                    <input class="form-check-input small-check timepoint-check" type="checkbox" value="${el}"/>
                                    <label class="form-check-label">${el}</label>
                                </div>
                            `)
            })
        }

        // ACTIV4 Host Tissue
        else if (selectedStudy == "ACTIV4 Host Tissue") {
            this.ACTIV4HostTissue.SampleType.forEach(el => {
                typeDropdown.append(`<option value="${el}">${el}</option>`)
            })
            typeDropdown.prop("disabled", false);

            this.ACTIV4HostTissue.TreatmentArm.forEach(el => {
                treatmentDropdown.append(`<option value="${el}">${el}</option>`)
            })
            treatmentDropdown.prop("disabled", false);

            this.ACTIV4HostTissue.Timepoint.forEach(el => {
                timepointDropdown.append(`
                                <div class="form-check">
                                    <input class="form-check-input small-check timepoint-check" type="checkbox" value="${el}"/>
                                    <label class="form-check-label">${el}</label>
                                </div>
                            `)
            })
        }

        // ACTIV1
        else if (selectedStudy == "ACTIV1") {
            this.ACTIV1.SampleType.forEach(el => {
                typeDropdown.append(`<option value="${el}">${el}</option>`)
            })
            typeDropdown.prop("disabled", false);

            this.ACTIV1.TreatmentArm.forEach(el => {
                treatmentDropdown.append(`<option value="${el}">${el}</option>`)
            })
            treatmentDropdown.prop("disabled", false);

            this.ACTIV1.Timepoint.forEach(el => {
                timepointDropdown.append(`
                                <div class="form-check">
                                    <input class="form-check-input small-check timepoint-check" type="checkbox" value="${el}"/>
                                    <label class="form-check-label">${el}</label>
                                </div>
                            `)
            })
        }

        // if none of the sample types are selected, disable the fields
        else {
            this.disableDropdowns(typeDropdown, treatmentDropdown, timepointDropdown);
        }

        // set up checkbox interaction on timepoint checkboxes (accessibility)
        $(".timepoint-check").on("keypress", function (e) {
            if ($(this).is(":focus") && e.which == 13) {
                if ($(this).is(":checked")) {
                    $(this).prop("checked", false);
                }
                else {
                    $(this).prop("checked", true);
                }
            }
        })
    }

    disableDropdowns(typeDropdown, treatmentDropdown, timepointDropdown) {
        typeDropdown.prop("disabled", true);
        treatmentDropdown.prop("disabled", true);
    }

    changeStudyNew(selectedElement:any) {
        // grab elements
        let selectedStudy = selectedElement.val().toString();
        let typeDropdown = $("#sample-type");
        let treatmentDropdown = $("#sample-treatment-arm");
        let timepointDropdown = $("#sample-timepoint-group");
        let nickname = $("#sample-nickname").val();

        this.createDropdowns(selectedStudy, typeDropdown, treatmentDropdown, timepointDropdown);
        $("#sample-nickname").val(nickname);
    }

    changeStudyUpdate(selectedElement:any) {
        let selectedStudy = selectedElement.val().toString();
        let typeDropdown = $("#update-type");
        let treatmentDropdown = $("#update-treatment-arm");
        let timepointDropdown = $("#update-timepoint-group");

        this.createDropdowns(selectedStudy, typeDropdown, treatmentDropdown, timepointDropdown);
    }

    openNewForm() {
        // reset the fields
        $("#sample-study").val("");
        this.emptyDropdowns($("#sample-type"), $("#sample-treatment-arm"), $("#sample-timepoint"));
        this.disableDropdowns($("#sample-type"), $("#sample-treatment-arm"), $("#sample-timepoint"));

        // remove error if present
        $("#samples-request").find(".error-msg").hide();

        // remove errors
        $("#new-sample-form").find(".error-msg").hide();

        // remove .is-invalid and is.valid
        $("#new-sample-form").find("input, select, textarea").removeClass("is-valid").removeClass("is-invalid");
    }

    updateSample() {
        // verify that all required fields are filled out correctly
        let timepointString = this.sampleTimepointConversion();
        let queryString = this.queryStringConversion();
        let isValid = this.requestValidation.validateForm();

        // if all required fields are validated
        if (isValid) {
            let updatedSampleRow: SampleRow;
            // see if updated sample has an existing id
            let index = parseInt($("#update-row-index").text()) - 1;
            let sampleId = this.sampleRows[index].Id;
            if (sampleId != undefined && sampleId != null) {
                // create a new sample row object with Id
                updatedSampleRow = {
                    Id: sampleId,
                    Nickname: $("#update-nickname").val().toString(),
                    Study: $("#update-study").val().toString(),
                    SampleType: $("#update-type").val().toString(),
                    TreatmentArm: $("#update-treatment-arm").val().toString(),
                    Quantity: $("#update-quantity").val().toString(),
                    Volume: $("#update-volume").val().toString(),
                    Unit: $("#update-units").val().toString(),
                    Timepoint: timepointString,
                    Queries: queryString,
                    OtherSpecifications: $("#update-other-specifications").val().toString()
                }
            }
            else {
                // create a new sample row object without Id
                updatedSampleRow = {
                    Nickname: $("#update-nickname").val().toString(),
                    Study: $("#update-study").val().toString(),
                    SampleType: $("#update-type").val().toString(),
                    TreatmentArm: $("#update-treatment-arm").val().toString(),
                    Quantity: $("#update-quantity").val().toString(),
                    Volume: $("#update-volume").val().toString(),
                    Unit: $("#update-units").val().toString(),
                    Timepoint: timepointString,
                    Queries: queryString,
                    OtherSpecifications: $("#update-other-specifications").val().toString()
                }
            }


            // replace the row with the updated row in SampleRows array
            this.sampleRows.splice(index, 1, updatedSampleRow);

            // update Table
            this.createTable(this.sampleRows);

            // clear field inputs
            let fields = $("input, textarea").filter(":visible");
            fields.map(function () {
                $(this).val("");
            })
            this.emptyDropdowns($("#update-type"), $("#update-treatment-arm"), $("#update-timepoint-group"));

            // reset query list
            $("#update-queries").children().show();

            // hide modal
            $("#update-sample-form").modal("hide");
        }
    }

    getSampleRows(): SampleRow[] {
        return this.sampleRows;
    }


}