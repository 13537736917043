import { SampleRequestFormData } from './sample-request-form-data.entities'
import { SampleRequestCommentData } from './sample-request-form-data.entities'
import { FormatDate } from '../../../shared/scripts/date-functions'
import { plainToClass } from 'class-transformer';

declare var $: any;

export class RequestDashboardStatusHistoryModal {
    currentForm: SampleRequestFormData;
    allComments: SampleRequestCommentData[];
    currentComment: SampleRequestCommentData;

    constructor(allComments: SampleRequestCommentData[]) {
        this.allComments = allComments.sort((a, b) => b.id - a.id);       
    }   

    public populateStatusHistory(formId: string) {
        // clear existing comments
        $("#status-history-table").empty();

        // parse Id
        let parsedId = parseInt(formId);

        if (parsedId != NaN) {

            let formComments = this.allComments.filter(comment => comment.sampleRequestFormId == parsedId && comment.beforeStatus != comment.afterStatus);

            let commentHTML = ``;

            // create HTML object
            if (formComments.length > 0) {
                commentHTML += `
                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th class="purple-bg" scope="col">Status</th>
                                <th class="purple-bg" scope="col">Days in Status</th>
                                <th class="purple-bg" scope="col">Date</th>
                                <th class="purple-bg" scope="col">Feedback</th>
                            </tr>
                        </thead>
                        <tbody>
                `;

                formComments.forEach((comment, i) => {
                    let startDate = new Date(comment.creationDate);
                    let endDate = new Date(Date.now());

                    if (i > 0) {
                        endDate = new Date(formComments[i - 1].creationDate);
                    }

                    let timeDifference = endDate.getTime() - startDate.getTime();
                    let dayDifference = Math.round(timeDifference / (1000 * 3600 * 24));

                    commentHTML += `
                        <tr>
                            <td>${comment.afterStatus}</td>
                            <td>${dayDifference}</td>
                            <td>${FormatDate(comment.creationDate)}</td>
                            <td>${comment.comment}</td>
                        </tr>
                    `;
                });

                commentHTML += `
                        </tbody>
                    </table>
                `;


            }
            else {
                commentHTML += `
                <div>
                    <p>There is currently no feedback for this request.</p>
                </div>
                `;
            }

            // fill comment modal
            $("#status-history-table").append(commentHTML);
        }

    }

}