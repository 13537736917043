

export class BiorepositoryLookup {

    //static SAMPLE_TYPES: string[] = ["Angio-EDTA", "Citrate", "DBS", "EDTA", "P100", "Packed Cells", "PaxGene", "Serum"]; //indices based on values from TaskRunner
    static SAMPLE_TYPES: string[] = ["Angio-EDTA", "Citrated Plasma", "Dried Blood Spot", "EDTA Plasma", "P100", "Packed Cells", "PAXgene", "Serum"]; //indices based on values from TaskRunner

    static GetValueFromIdAndType(id: number, type: string): string {
        //console.log(id, type);

        if (type == 'study') {
            switch (id) {
                case (1): return "ACTIV4a Inpatient (Phase 1)";
                case (2): return "ACTIV4a Inpatient (Phase 2: Moderate)";
                case (3): return "ACTIV4a Inpatient (Phase 2: Severe)";
                case (4): return "ACTIV4a Inpatient (Phase 3: Moderate)";
                case (5): return "ACTIV4a Inpatient (Phase 3: Severe)";
                case (6): return "ACTIV4c Convalescent";
                case (7): return "ACTIV4d Host Tissue";
                case (8): return "ACTIV1 Immune Modulators (B)";
                case (9): return "ACTIV1 Immune Modulators (B, C)";
                case (10): return "ACTIV1 Immune Modulators (C)";
                case (11): return "ACTIV1 Immune Modulators (A)";
                case (12): return "ACTIV1 Immune Modulators (A, B)";
                case (13): return "ACTIV1 Immune Modulators (A, B, C)";
                case (14): return "ACTIV1 Immune Modulators (A, C)";
                default: return "Unknown";
            }
        }
        else if (type == 'sample-type') {
            switch (id) {
                case (0): return "Angio-EDTA";
                case (1): return "Citrated Plasma";
                case (2): return "Dried Blood Spot";
                case (3): return "EDTA Plasma";
                case (4): return "P100";
                case (5): return "Packed Cells";
                case (6): return "PAXgene";
                case (7): return "Serum";
                default: return "Unknown";
            }
        }
        else if (type == 'study-type') {
            switch (id) {
                case (1): return "Inpatient";
                case (2): return "Post-discharge";
                default: return "Unknown";
            }
        }
        else if (type == 'specimen-status') {
            switch (id) {
                case (1): return "Available";
                case (2): return "Reserved";
                case (3): return "Allocated";
                case (9): return "QC/Other";
                default: return "Unknown";
            }
        }
        else if (type == 'sex') {
            switch (id) {
                case (1): return "Male";
                case (2): return "Female";
                case (-99): return "Unknown";
                default: return "Missing Value";
            }
        }
        else if (type == 'race') {
            switch (id) {
                case (1): return "White";
                case (2): return "Asian";
                case (3): return "Black";
                case (4): return "American Indian or Alaska Native";
                case (5): return "Native Hawaiian or Pacific Islander";
                case (6): return "Aboriginal/First Nations";
                case (7): return "Middle Eastern/North African";
                case (8): return "Other";
                case (9): return "Multiple";
                case (10): return "Unknown";
                default: return "Missing Value";
            }
        }
        else if (type == 'ethnicity') {
            switch (id) {
                case (1): return "Hispanic or Latino";
                case (2): return "Not Hispanic or Latino";
                case (-99): return "Unknown";
                default: return "Missing Value";
            }
        }
        else if (type == 'quality') {
            switch (id) {
                case (0): return "Normal";
                case (1): return "Hemolyzed";
                case (2): return "Clotted";
                case (3): return "Separated (DNA)";
                case (4): return "> 48 Hours";
                case (5): return "Ineligible";
                case (-99): return "Unknown";
                default: return "Missing Value";
            }
        }
        else if (type == 'age') {
            switch (id) {
                case (0): return "18 - <30";
                case (1): return "30 - <40";
                case (2): return "40 - <50";
                case (3): return "50 - <65";
                case (4): return "65 - <75";
                case (5): return "75 - <85";
                case (6): return "85+"
                case (-99): return "Unknown";
                default: return "Missing Value";
            }
        }
        return "Unknown Type";
    }
}