export class RequestSpecific {
    constructor() {

    }

    institutionField(selectedElement) {
        let selectedValue = selectedElement.find(":selected").val();
        let otherInstitutionField = $("#other-institution-type");

        if (selectedValue == "Other") {
            otherInstitutionField.removeClass("inactive-section").hide().show(500);
            otherInstitutionField.children("input").prop("required", "true");
        }
        else {
            otherInstitutionField.hide(500);
            otherInstitutionField.children("input").prop("required", "false");
            $("#other-institution-type-input").val("");
        }
    }

    fundingAvailableShow() {
        if ($("#funding-available").val() != "") {
            if ($("#funding-available").val() == "No") {
                // reset the fields for funding sources
                $("#funding-sources").find("input").not(":checkbox").val("");
                $("#funding-sources").find("input[type=checkbox]").prop("checked", false);
                $("#funding-sources").find("select").val("");

                // hide any text input fields that are dependent on checkboxes
                $("#a-project-specify-agency").parent().parent().hide();
                $("#a-project-other-specify").parent().parent().hide();
                

                // hide/show the correct sections
                $("#funding-sources").hide();
                $("#a-funding-sources").show();
            }
            else {
                $("#a-funding-sources").find("input").not(":checkbox").val("");
                $("#a-funding-sources").find('input[type=checkbox]').prop('checked', false);
                $("#a-funding-sources").find("select").val("");

                $("#project-specify-award").parent().parent().hide();
                $("#project-specify-agency").parent().parent().hide();
                $("#project-other-specify").parent().parent().hide();

                $("#funding-sources").show();
                $("#a-funding-sources").hide();
            }
        }
        else {
            $("#funding-sources").hide();
            $("#a-funding-sources").hide();
        }
    }

    aFundingAvailableShow() {
        if ($("#a-funding-available").val() != "") {
            if ($("#a-funding-available").val() == "No") {
                $("#a-project-funding-submission").val("");
                $("#a-project-submission-section").hide();
            }
            else {
                $("#a-project-submission-section").show();
            }
        }
        else {
            $("#a-project-funding-submission").val("");
            $("#a-project-submission-section").hide();
        }
    }

    clinicalDataShow() {
        // every time the applicant changes the dropdown option reset additional fields
        $("#clinical-data-date").val("");
        $("#clinical-data-additional").val("");

        if ($("#clinical-data").val() == "Not Yet Requested" || $("#clinical-data").val() == "Not Applicable") {
            $("#clinical-data-additional-section").show();
        }
        else {
            $("#clinical-data-additional-section").hide();
        }

        if ($("#clinical-data").val() == "Requested" || $("#clinical-data").val() == "Approved") {
            $("#clinical-data-date-section").show();

            if ($("#clinical-data").val() == "Requested") {
                $("#clinical-data-date-label").text("Anticipated Date of Approval for Clinical Data Request:");
            }

            if ($("#clinical-data").val() == "Approved") {
                $("#clinical-data-date-label").text("Anticipated Date for Receipt of Dataset:");
            }
        }
        else {
            $("#clinical-data-date-section").hide();
        }
        
    }
}