import { plainToClass } from 'class-transformer';
import { time } from 'highcharts';
import { BiorepositoryLookup } from './inventory-lookup';


export class Patient {
    PatientId: string;
    StudyId: number;
    StudyTypeId: number;
    //Visits: string;
    Age: number;
    Sex: number;
    Race: number;
    Ethnicity: number;
    Samples: Sample[];

    initialize(): void {
        this.Samples = plainToClass(Sample, <Sample[]>this.Samples);
        this.Samples = this.Samples.map((sample: Sample) => {
            sample.Aliquots = plainToClass(Aliquot, <Aliquot[]>sample.Aliquots);
            return sample;
        });
    }
}

export class Sample {
    SampleTypeId: number;
    Timepoint: string;
    Aliquots: Aliquot[];

    constructor(sampleTypeId: number, timepoint: string) {
        this.SampleTypeId = sampleTypeId;
        this.Timepoint = timepoint;
        this.Aliquots = [];
    }
}

export class Aliquot {
    Barcode: string;
    BiorepoStatus: number;
    PortalStatus: number;
}

export class DropdownOption {
    Id: number;
    Value: string;

    constructor(id: number, type: string) {
        this.Id = id;
        this.Value = BiorepositoryLookup.GetValueFromIdAndType(id, type);
    }
}

export class ComputedDisplayValues {

    ParticipantCount: number = 0;
    SampleCount: number = 0;
    AliquotCount: number = 0;

    timepoints: string[] = ["Baseline", "Day1", "Day3", "Day7or8", "Day14or15", "Day29or31", "Unscheduled", "TreatmentEnd"];
    allTimepoints: string[] = ["Baseline", "Day1", "Day2", "Day3", "Day4", "Day5", "Day6", "Day7", "Day8", "Day9", "Day10", "Day11", "Day12", "Day13", "Day14", "Day15", "Day16", "Day17", "Day18", "Day22", "Day24", "Day26", "Day27", "Day29", "Day31", "Day60", "TreatmentEnd"]

    ParticipantsByVisit_Baseline: number = 0;
    ParticipantsByVisit_Day1: number = 0;
    //ParticipantsByVisit_Day2: number = 0;
    ParticipantsByVisit_Day3: number = 0;
    /*ParticipantsByVisit_Day4: number = 0;
    ParticipantsByVisit_Day5: number = 0;
    ParticipantsByVisit_Day6: number = 0;*/
    ParticipantsByVisit_Day7: number = 0;
    ParticipantsByVisit_Day8: number = 0;
    /*ParticipantsByVisit_Day9: number = 0;
    ParticipantsByVisit_Day10: number = 0;
    ParticipantsByVisit_Day11: number = 0;
    ParticipantsByVisit_Day12: number = 0;
    ParticipantsByVisit_Day13: number = 0;*/
    ParticipantsByVisit_Day14: number = 0;
    ParticipantsByVisit_Day15: number = 0;
    /*ParticipantsByVisit_Day16: number = 0;
    ParticipantsByVisit_Day17: number = 0;
    ParticipantsByVisit_Day18: number = 0;
    ParticipantsByVisit_Day22: number = 0;
    ParticipantsByVisit_Day24: number = 0;
    ParticipantsByVisit_Day26: number = 0;
    ParticipantsByVisit_Day27: number = 0;*/
    ParticipantsByVisit_Day29: number = 0;
    ParticipantsByVisit_Day31: number = 0;
    //ParticipantsByVisit_Day60: number = 0;
    ParticipantsByVisit_TreatmentEnd: number = 0;
    ParticipantsByVisit_Day7or8: number = 0;
    ParticipantsByVisit_Day14or15: number = 0;
    ParticipantsByVisit_Day29or31: number = 0;
    ParticipantsByVisit_Unscheduled: number = 0;

    Participants_Timepoint1: CountsBySampleType = new CountsBySampleType();
    Participants_Timepoint2: CountsBySampleType = new CountsBySampleType();
    Participants_Timepoint3: CountsBySampleType = new CountsBySampleType();
    Participants_Timepoint4: CountsBySampleType = new CountsBySampleType();
    Participants_Timepoint5: CountsBySampleType = new CountsBySampleType();
    Participants_Timepoint6: CountsBySampleType = new CountsBySampleType();
    Participants_Timepoint7: CountsBySampleType = new CountsBySampleType();

    Samples_VisitBaseline: CountsBySampleType = new CountsBySampleType();
    Samples_VisitDay1: CountsBySampleType = new CountsBySampleType();
    //Samples_VisitDay2: CountsBySampleType = new CountsBySampleType();
    Samples_VisitDay3: CountsBySampleType = new CountsBySampleType();
    /*Samples_VisitDay4: CountsBySampleType = new CountsBySampleType();
    Samples_VisitDay5: CountsBySampleType = new CountsBySampleType();
    Samples_VisitDay6: CountsBySampleType = new CountsBySampleType();*/
    Samples_VisitDay7: CountsBySampleType = new CountsBySampleType();
    Samples_VisitDay8: CountsBySampleType = new CountsBySampleType();
    /*Samples_VisitDay9: CountsBySampleType = new CountsBySampleType();
    Samples_VisitDay10: CountsBySampleType = new CountsBySampleType();
    Samples_VisitDay11: CountsBySampleType = new CountsBySampleType();
    Samples_VisitDay12: CountsBySampleType = new CountsBySampleType();
    Samples_VisitDay13: CountsBySampleType = new CountsBySampleType();*/
    Samples_VisitDay14: CountsBySampleType = new CountsBySampleType();
    Samples_VisitDay15: CountsBySampleType = new CountsBySampleType();
    /*Samples_VisitDay16: CountsBySampleType = new CountsBySampleType();
    Samples_VisitDay17: CountsBySampleType = new CountsBySampleType();
    Samples_VisitDay18: CountsBySampleType = new CountsBySampleType();
    Samples_VisitDay22: CountsBySampleType = new CountsBySampleType();
    Samples_VisitDay24: CountsBySampleType = new CountsBySampleType();
    Samples_VisitDay26: CountsBySampleType = new CountsBySampleType();
    Samples_VisitDay27: CountsBySampleType = new CountsBySampleType();*/
    Samples_VisitDay29: CountsBySampleType = new CountsBySampleType();
    Samples_VisitDay31: CountsBySampleType = new CountsBySampleType();
    //Samples_VisitDay60: CountsBySampleType = new CountsBySampleType();
    Samples_VisitTreatmentEnd: CountsBySampleType = new CountsBySampleType();
    Samples_VisitDay7or8: CountsBySampleType = new CountsBySampleType();
    Samples_VisitDay14or15: CountsBySampleType = new CountsBySampleType();
    Samples_VisitDay29or31: CountsBySampleType = new CountsBySampleType();
    Samples_VisitUnscheduled: CountsBySampleType = new CountsBySampleType();

    Aliquots_VisitBaseline: CountsBySampleType = new CountsBySampleType();
    Aliquots_VisitDay1: CountsBySampleType = new CountsBySampleType();
    //Aliquots_VisitDay2: CountsBySampleType = new CountsBySampleType();
    Aliquots_VisitDay3: CountsBySampleType = new CountsBySampleType();
   /* Aliquots_VisitDay4: CountsBySampleType = new CountsBySampleType();
    Aliquots_VisitDay5: CountsBySampleType = new CountsBySampleType();
    Aliquots_VisitDay6: CountsBySampleType = new CountsBySampleType();*/
    Aliquots_VisitDay7: CountsBySampleType = new CountsBySampleType();
    Aliquots_VisitDay8: CountsBySampleType = new CountsBySampleType();
    /*Aliquots_VisitDay9: CountsBySampleType = new CountsBySampleType();
    Aliquots_VisitDay10: CountsBySampleType = new CountsBySampleType();
    Aliquots_VisitDay11: CountsBySampleType = new CountsBySampleType();
    Aliquots_VisitDay12: CountsBySampleType = new CountsBySampleType();
    Aliquots_VisitDay13: CountsBySampleType = new CountsBySampleType();*/
    Aliquots_VisitDay14: CountsBySampleType = new CountsBySampleType();
    Aliquots_VisitDay15: CountsBySampleType = new CountsBySampleType();
    /*Aliquots_VisitDay16: CountsBySampleType = new CountsBySampleType();
    Aliquots_VisitDay17: CountsBySampleType = new CountsBySampleType();
    Aliquots_VisitDay18: CountsBySampleType = new CountsBySampleType();
    Aliquots_VisitDay22: CountsBySampleType = new CountsBySampleType();
    Aliquots_VisitDay24: CountsBySampleType = new CountsBySampleType();
    Aliquots_VisitDay26: CountsBySampleType = new CountsBySampleType();
    Aliquots_VisitDay27: CountsBySampleType = new CountsBySampleType();*/
    Aliquots_VisitDay29: CountsBySampleType = new CountsBySampleType();
    Aliquots_VisitDay31: CountsBySampleType = new CountsBySampleType();
    //Aliquots_VisitDay60: CountsBySampleType = new CountsBySampleType();
    Aliquots_VisitTreatmentEnd: CountsBySampleType = new CountsBySampleType();
    Aliquots_VisitDay7or8: CountsBySampleType = new CountsBySampleType();
    Aliquots_VisitDay14or15: CountsBySampleType = new CountsBySampleType();
    Aliquots_VisitDay29or31: CountsBySampleType = new CountsBySampleType();
    Aliquots_VisitUnscheduled: CountsBySampleType = new CountsBySampleType();

    constructor() { }

    Reset() {
        this.ParticipantCount = 0; this.SampleCount = 0; this.AliquotCount = 0;

        for (let i = 0; i < this.timepoints.length; i++) {
            this[`ParticipantsByVisit_${this.timepoints[i]}`] = 0;
            this[`Samples_Visit${this.timepoints[i]}`].Reset();
            this[`Aliquots_Visit${this.timepoints[i]}`].Reset();
        }
        /*this.ParticipantsByVisit_Baseline = 0; this.ParticipantsByVisit_Day1 = 0; this.ParticipantsByVisit_Day3 = 0; this.ParticipantsByVisit_Day7 = 0;
        this.ParticipantsByVisit_Day14 = 0; this.ParticipantsByVisit_Day31 = 0; this.ParticipantsByVisit_TreatmentEnd = 0;*/

        this.Participants_Timepoint1.Reset(); this.Participants_Timepoint2.Reset(); this.Participants_Timepoint3.Reset(); this.Participants_Timepoint4.Reset();
        this.Participants_Timepoint5.Reset(); this.Participants_Timepoint6.Reset(); this.Participants_Timepoint7.Reset();

        /*this.Samples_VisitBaseline.Reset(); this.Samples_VisitDay1.Reset(); this.Samples_VisitDay3.Reset(); this.Samples_VisitDay7.Reset();
        this.Samples_VisitDay14.Reset(); this.Samples_VisitDay31.Reset(); this.Samples_VisitTreatmentEnd.Reset();*/

        /*this.Aliquots_VisitBaseline.Reset(); this.Aliquots_VisitDay1.Reset(); this.Aliquots_VisitDay3.Reset(); this.Aliquots_VisitDay7.Reset();
        this.Aliquots_VisitDay14.Reset(); this.Aliquots_VisitDay31.Reset(); this.Aliquots_VisitTreatmentEnd.Reset();*/

    }

    Update(patients: Patient[], studies: string[], studyTypes: string[], specimenStatuses: string[], ages: string[], sexes: string[], races: string[], ethnicities: string[]) {
        //console.log("Patients n=" + patients.length);
        //console.log("Studies", studies);
        //console.log("Study Types", studyTypes);
        //console.log("Specimen Statuses", specimenStatuses);
        //console.log("Age", ageMin, ageMax, " | Include missing", ageIncludeMissing);
        //console.log("Sexes", sexes);
        //console.log("Races", races);
        //console.log("Ethnicities", ethnicities);
        //console.log("------------------------");
        this.Reset();

        let activ1StudyIds: number[] = [8, 9, 10, 11, 12, 13, 14];

        //let filteredList: Patient[] = [];
        for (const patient of patients) {
            if (patient.Samples == undefined || patient.Samples.length == 0) continue;
            if (!studies.includes(patient.StudyId + "") || !studyTypes.includes(patient.StudyTypeId + "") || !sexes.includes(patient.Sex + "") || !races.includes(patient.Race + "") || !ethnicities.includes(patient.Ethnicity + "") || !ages.includes(patient.Age + "")) {
                continue;
            }

            /*if (patient.Age == -99 && !ageIncludeMissing) { continue; }
            if (patient.Age != -99 && !(ageMin <= patient.Age && patient.Age <= ageMax)) { continue; }*/

            let visitsByParticipant: boolean[] = new Array(this.timepoints.length).fill(false); //baseline, day1, day3, day7, day14, day31, treatmentend
            let visitsByParticipantAllTimepoints: boolean[] = new Array(this.allTimepoints.length).fill(false);
            let sampleTypesByVisit_Patient: SampleTypeByVisitTracker = new SampleTypeByVisitTracker();
            let sampleTypesByVisit_Sample: SampleTypeByVisitTracker = new SampleTypeByVisitTracker();
            let sampleTypesByVisit_Aliquot: SampleTypeByVisitTracker = new SampleTypeByVisitTracker();

            //if (patient.PatientId == 'US-2115-0009') {
            //    console.log(patient);
            //}

            let matchingSamples: number = 0;
            let matchingAliquots: number = 0;
            for (const sample of patient.Samples) {
                let foundAliquot: boolean = false;

                let visitIndex: number = -1;
                let countVisitIndex: number = -1;
                if (sample.Timepoint == "0") { visitIndex = 0; countVisitIndex = 0; }
                else if (sample.Timepoint == "1") { visitIndex = 1; countVisitIndex = 1; }
                else if (sample.Timepoint == "3") { visitIndex = 2; countVisitIndex = 3; }
                else if (sample.Timepoint == "7" && !activ1StudyIds.includes(patient.StudyId)) { visitIndex = 3; countVisitIndex = 7; } // ACTIV 4 Study -> Day 7 is included in Day 7/8
                else if (sample.Timepoint == "7" && activ1StudyIds.includes(patient.StudyId)) { visitIndex = 6; countVisitIndex = 7; } // ACTIV 1 Study -> Day 7 is 'Unscheduled'
                else if (sample.Timepoint == "8" && !activ1StudyIds.includes(patient.StudyId)) { visitIndex = 6; countVisitIndex = 8; } // ACTIV 4 Study -> Day 8 is 'Unscheduled'
                else if (sample.Timepoint == "8" && activ1StudyIds.includes(patient.StudyId)) { visitIndex = 3; countVisitIndex = 8; }  // ACTIV 1 Study -> Day 8 is included in Day 7/8
                else if (sample.Timepoint == "14" && !activ1StudyIds.includes(patient.StudyId)) { visitIndex = 4; countVisitIndex = 14; } // ACTIV 4 Study -> Day 14 is included in Day 14/15
                else if (sample.Timepoint == "14" && activ1StudyIds.includes(patient.StudyId)) { visitIndex = 6; countVisitIndex = 14; } // ACTIV1 Study -> Day 14 is 'Unscheduled'
                else if (sample.Timepoint == "15" && !activ1StudyIds.includes(patient.StudyId)) { visitIndex = 6; countVisitIndex = 15; } // ACTIV 4 Study -> Day 15 is 'Unscheduled'
                else if (sample.Timepoint == "15" && activ1StudyIds.includes(patient.StudyId)) { visitIndex = 4; countVisitIndex = 15; } // ACTIV 1 Study -> Day 15 is included in Day 14/15
                else if (sample.Timepoint == "29" && !activ1StudyIds.includes(patient.StudyId)) { visitIndex = 6; countVisitIndex = 23; } // ACTIV 4 Study -> Day 29 is 'Unscheduled'
                else if (sample.Timepoint == "29" && activ1StudyIds.includes(patient.StudyId)) { visitIndex = 5; countVisitIndex = 23; } // ACTIV 1 Study -> Day 29 is included in Day 29/31
                else if (sample.Timepoint == "31" && !activ1StudyIds.includes(patient.StudyId)) { visitIndex = 5; countVisitIndex = 24; } // ACTIV 4 Study -> Day 31 is included in Day 29/31
                else if (sample.Timepoint == "31" && activ1StudyIds.includes(patient.StudyId)) { visitIndex = 6; countVisitIndex = 24; } // ACTIV 1 Study -> Day 31 is 'Unscheduled'
                else if (sample.Timepoint == "2") { visitIndex = 6; countVisitIndex = 2;}
                else if (sample.Timepoint == "4") { visitIndex = 6; countVisitIndex = 4; }
                else if (sample.Timepoint == "5") { visitIndex = 6; countVisitIndex = 5; }
                else if (sample.Timepoint == "6") { visitIndex = 6; countVisitIndex = 6; }
                else if (sample.Timepoint == "9") { visitIndex = 6; countVisitIndex = 9; }
                else if (sample.Timepoint == "10") { visitIndex = 6; countVisitIndex = 10; }
                else if (sample.Timepoint == "11") { visitIndex = 6; countVisitIndex = 11; }
                else if (sample.Timepoint == "12") { visitIndex = 6; countVisitIndex = 12; }
                else if (sample.Timepoint == "13") { visitIndex = 6; countVisitIndex = 13; }
                else if (sample.Timepoint == "16") { visitIndex = 6; countVisitIndex = 16; }
                else if (sample.Timepoint == "17") { visitIndex = 6; countVisitIndex = 17; }
                else if (sample.Timepoint == "18") { visitIndex = 6; countVisitIndex = 18; }
                else if (sample.Timepoint == "22") { visitIndex = 6; countVisitIndex = 19; }
                else if (sample.Timepoint == "24") { visitIndex = 6; countVisitIndex = 20; }
                else if (sample.Timepoint == "26") { visitIndex = 6; countVisitIndex = 21; }
                else if (sample.Timepoint == "27") { visitIndex = 6; countVisitIndex = 22; }
                else if (sample.Timepoint == "60") { visitIndex = 6; countVisitIndex = 25; }
                else if (sample.Timepoint == "99") { visitIndex = 7; countVisitIndex = 26; }
                else { console.log("Unknown sample timepoint = " + sample.Timepoint); continue; }

                for (const aliquot of sample.Aliquots) {
                    //if (patient.PatientId == 'US-2115-0009' && sample.SampleTypeId == 3) {
                    //if (sample.SampleTypeId == 2) {
                    //    console.log(aliquot);
                    //}

                    if (specimenStatuses.includes(aliquot.PortalStatus + "")) {
                        matchingAliquots++;
                        sampleTypesByVisit_Aliquot.CountData[sample.SampleTypeId][visitIndex] += 1;
                        foundAliquot = true;
                    }
                }
                if (matchingAliquots > 0 && foundAliquot) {
                     matchingSamples++;

                    if (visitIndex >= 0) {
                        visitsByParticipant[visitIndex] = true;
                        visitsByParticipantAllTimepoints[countVisitIndex] = true;
                        sampleTypesByVisit_Patient.BooleanData[sample.SampleTypeId][visitIndex] = true;
                        sampleTypesByVisit_Patient.AllBooleanData[sample.SampleTypeId][countVisitIndex] = true;

                        sampleTypesByVisit_Sample.CountData[sample.SampleTypeId][visitIndex] += 1;
                    }

                    //if (patient.PatientId == 'US-2115-0009' && sample.SampleTypeId == 3) {
                    //    console.log(patient, sample, specimenStatuses);
                    //}
                }
            }

            if (matchingSamples > 0) {
                this.ParticipantCount += 1;
                this.SampleCount += matchingSamples;
                this.AliquotCount += matchingAliquots;

                //if (patient.PatientId == 'US-3019-0003') {
                //    console.log(matchingSamples, matchingAliquots);
                //    console.log(sampleTypesByVisit_Patient.BooleanData[0][0]);
                //    console.log(sampleTypesByVisit_Patient.BooleanData[6][0]);
                //}

                for (let i = 0; i < this.timepoints.length; i++) {
                    if (visitsByParticipant[i]) { this[`ParticipantsByVisit_${this.timepoints[i]}`]++ }
                }
                /*if (visitsByParticipant[0]) { this.ParticipantsByVisit_Baseline++; }
                if (visitsByParticipant[1]) { this.ParticipantsByVisit_Day1++; }
                if (visitsByParticipant[2]) { this.ParticipantsByVisit_Day3++; }
                if (visitsByParticipant[3]) { this.ParticipantsByVisit_Day7++; }
                if (visitsByParticipant[4]) { this.ParticipantsByVisit_Day14++; }
                if (visitsByParticipant[5]) { this.ParticipantsByVisit_Day31++; }
                if (visitsByParticipant[6]) { this.ParticipantsByVisit_TreatmentEnd++; }*/

                for (let sampleTypeId: number = 0; sampleTypeId < sampleTypesByVisit_Patient.AllBooleanData.length; ++sampleTypeId) {
                    let timepointCount: number = 0;
                    for (let i: number = 0; i < sampleTypesByVisit_Patient.AllBooleanData[sampleTypeId].length; ++i) {
                        if (sampleTypesByVisit_Patient.AllBooleanData[sampleTypeId][i] == true) { timepointCount++; }
                    }

                    //if (sampleTypeId == 3 && timepointCount == 1) {
                    //    console.log(patient.PatientId);
                    //}

                    if (timepointCount == 1) { this.Participants_Timepoint1.IncrementValueBySampleTypeIndex(sampleTypeId, 1); }
                    else if (timepointCount == 2) { this.Participants_Timepoint2.IncrementValueBySampleTypeIndex(sampleTypeId, 1); }
                    else if (timepointCount == 3) { this.Participants_Timepoint3.IncrementValueBySampleTypeIndex(sampleTypeId, 1); }
                    else if (timepointCount == 4) { this.Participants_Timepoint4.IncrementValueBySampleTypeIndex(sampleTypeId, 1); }
                    else if (timepointCount == 5) { this.Participants_Timepoint5.IncrementValueBySampleTypeIndex(sampleTypeId, 1); }
                    else if (timepointCount == 6) { this.Participants_Timepoint6.IncrementValueBySampleTypeIndex(sampleTypeId, 1); }
                    else if (timepointCount == 7) { this.Participants_Timepoint7.IncrementValueBySampleTypeIndex(sampleTypeId, 1); }
                    else if (timepointCount > 7) { console.log("Number participant timepoints for person out-of-range = " + timepointCount + " person = " + patient); }

                    for (let i = 0; i < this.timepoints.length; i++) {
                        this[`Samples_Visit${this.timepoints[i]}`].IncrementValueBySampleTypeIndex(sampleTypeId, sampleTypesByVisit_Sample.CountData[sampleTypeId][i]);
                        this[`Aliquots_Visit${this.timepoints[i]}`].IncrementValueBySampleTypeIndex(sampleTypeId, sampleTypesByVisit_Aliquot.CountData[sampleTypeId][i]);
                    }

                    /*this.Samples_VisitBaseline.IncrementValueBySampleTypeIndex(sampleTypeId, sampleTypesByVisit_Sample.CountData[sampleTypeId][0]);
                    this.Samples_VisitDay1.IncrementValueBySampleTypeIndex(sampleTypeId, sampleTypesByVisit_Sample.CountData[sampleTypeId][1]);
                    this.Samples_VisitDay3.IncrementValueBySampleTypeIndex(sampleTypeId, sampleTypesByVisit_Sample.CountData[sampleTypeId][2]);
                    this.Samples_VisitDay7.IncrementValueBySampleTypeIndex(sampleTypeId, sampleTypesByVisit_Sample.CountData[sampleTypeId][3]);
                    this.Samples_VisitDay14.IncrementValueBySampleTypeIndex(sampleTypeId, sampleTypesByVisit_Sample.CountData[sampleTypeId][4]);
                    this.Samples_VisitDay31.IncrementValueBySampleTypeIndex(sampleTypeId, sampleTypesByVisit_Sample.CountData[sampleTypeId][5]);
                    this.Samples_VisitTreatmentEnd.IncrementValueBySampleTypeIndex(sampleTypeId, sampleTypesByVisit_Sample.CountData[sampleTypeId][6]);

                    this.Aliquots_VisitBaseline.IncrementValueBySampleTypeIndex(sampleTypeId, sampleTypesByVisit_Aliquot.CountData[sampleTypeId][0]);
                    this.Aliquots_VisitDay1.IncrementValueBySampleTypeIndex(sampleTypeId, sampleTypesByVisit_Aliquot.CountData[sampleTypeId][1]);
                    this.Aliquots_VisitDay3.IncrementValueBySampleTypeIndex(sampleTypeId, sampleTypesByVisit_Aliquot.CountData[sampleTypeId][2]);
                    this.Aliquots_VisitDay7.IncrementValueBySampleTypeIndex(sampleTypeId, sampleTypesByVisit_Aliquot.CountData[sampleTypeId][3]);
                    this.Aliquots_VisitDay14.IncrementValueBySampleTypeIndex(sampleTypeId, sampleTypesByVisit_Aliquot.CountData[sampleTypeId][4]);
                    this.Aliquots_VisitDay31.IncrementValueBySampleTypeIndex(sampleTypeId, sampleTypesByVisit_Aliquot.CountData[sampleTypeId][5]);
                    this.Aliquots_VisitTreatmentEnd.IncrementValueBySampleTypeIndex(sampleTypeId, sampleTypesByVisit_Aliquot.CountData[sampleTypeId][6]);*/

                    //if (sampleTypeId == 0) {
                    //    if (sampleTypesByVisit_Sample.CountData[sampleTypeId][0] > 0) {
                    //        console.log(patient);
                    //    }
                    //}
                }
            }
        }

        //console.log(this.Participants_Timepoint1);
    }
}

export class CountsBySampleType {

    constructor(public AngioEDTA: number = 0, public Citrate: number = 0, public DBS: number = 0, public EDTA: number = 0, public P100: number = 0, public PackedCells: number = 0, public PaxGene: number = 0, public Serum: number = 0) { }

    Reset() {
        this.AngioEDTA = 0; this.Citrate = 0; this.DBS = 0; this.EDTA = 0; this.P100 = 0;
        this.PackedCells = 0; this.PaxGene = 0; this.Serum = 0;
    }

    AddValues(values: number[]) {
        this.AngioEDTA += values[0];
        this.Citrate += values[1];
        this.DBS += values[2];
        this.EDTA += values[3];
        this.P100 += values[4];
        this.PackedCells += values[5];
        this.PaxGene += values[6];
        this.Serum += values[7];
    }

    IncrementValueBySampleTypeIndex(index: number, amount: number = 1) {
        if (index == 0) { this.AngioEDTA += amount; }
        else if (index == 1) { this.Citrate += amount; }
        else if (index == 2) { this.DBS += amount; }
        else if (index == 3) { this.EDTA += amount; }
        else if (index == 4) { this.P100 += amount; }
        else if (index == 5) { this.PackedCells += amount; }
        else if (index == 6) { this.PaxGene += amount; }
        else if (index == 7) { this.Serum += amount; }
    }

    GetDataArray(): number[] {
        return [this.AngioEDTA, this.Citrate, this.DBS, this.EDTA, this.P100, this.PackedCells, this.PaxGene, this.Serum];
    }
}

export class SampleTypeByVisitTracker {
    timepoints: string[] = ["Baseline", "Day1", "Day3", "Day7or8", "Day14or15", "Day29or31", "Unscheduled", "TreatmentEnd"];
    allTimepoints: string[] = ["Baseline", "Day1", "Day2", "Day3", "Day4", "Day5", "Day6", "Day7", "Day8", "Day9", "Day10", "Day11", "Day12", "Day13", "Day14", "Day15", "Day16", "Day17", "Day18", "Day22", "Day24", "Day26", "Day27", "Day29", "Day31", "Day60", "TreatmentEnd"]

    BooleanData: boolean[][] = [ //[sampleType],[visit] == has sample
        new Array(this.timepoints.length).fill(false),
        new Array(this.timepoints.length).fill(false),
        new Array(this.timepoints.length).fill(false),
        new Array(this.timepoints.length).fill(false),
        new Array(this.timepoints.length).fill(false),
        new Array(this.timepoints.length).fill(false),
        new Array(this.timepoints.length).fill(false),
        new Array(this.timepoints.length).fill(false),
        new Array(this.timepoints.length).fill(false)

        /*[false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false]*/
    ];

    AllBooleanData: boolean[][] = [ //[sampleType],[visit] == has sample
        new Array(this.allTimepoints.length).fill(false),
        new Array(this.allTimepoints.length).fill(false),
        new Array(this.allTimepoints.length).fill(false),
        new Array(this.allTimepoints.length).fill(false),
        new Array(this.allTimepoints.length).fill(false),
        new Array(this.allTimepoints.length).fill(false),
        new Array(this.allTimepoints.length).fill(false),
        new Array(this.allTimepoints.length).fill(false),
        new Array(this.allTimepoints.length).fill(false)
    ];

    CountData: number[][] = [ //[sampleType],[visit] == count
        new Array(this.timepoints.length).fill(0),
        new Array(this.timepoints.length).fill(0),
        new Array(this.timepoints.length).fill(0),
        new Array(this.timepoints.length).fill(0),
        new Array(this.timepoints.length).fill(0),
        new Array(this.timepoints.length).fill(0),
        new Array(this.timepoints.length).fill(0),
        new Array(this.timepoints.length).fill(0),
        new Array(this.timepoints.length).fill(0)

        /*[0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0]*/
    ];

    constructor() { }
}