import { BiorepositoryLookup } from "../../inventory-summary/scripts/inventory-lookup";


export class SpecimenSearchResult {
    Barcode: string;
    QualityId: number;
    SampleTypeId: number;
    StatusId: number;
    StudyId: number;
    ThawCount: number;
    VialId: string;
    Volume: number;
    UnitsId: number;
    Patient: string;
    Timepoint: number;

    SampleType: string;
    Quality: string;
    Status: string;
    Study: string;
    TimepointDisplay: string;
    Units: string;

    SampleId: string;

    constructor() { }

    initialize(): void {
        this.SampleType = BiorepositoryLookup.GetValueFromIdAndType(this.SampleTypeId, 'sample-type');
        this.Status = BiorepositoryLookup.GetValueFromIdAndType(this.StatusId, 'specimen-status');
        this.Study = BiorepositoryLookup.GetValueFromIdAndType(this.StudyId, 'study');
        this.Quality = BiorepositoryLookup.GetValueFromIdAndType(this.QualityId, 'quality');

        switch (this.Timepoint) {
            case 0: this.TimepointDisplay = "Baseline"; break;
            case 1: this.TimepointDisplay = "Day 1"; break;
            case 2: this.TimepointDisplay = "Day 2"; break;
            case 3: this.TimepointDisplay = "Day 3"; break;
            case 4: this.TimepointDisplay = "Day 4"; break;
            case 5: this.TimepointDisplay = "Day 5"; break;
            case 6: this.TimepointDisplay = "Day 6"; break;
            case 7: this.TimepointDisplay = "Day 7"; break;
            case 8: this.TimepointDisplay = "Day 8"; break;
            case 9: this.TimepointDisplay = "Day 9"; break;
            case 10: this.TimepointDisplay = "Day 10"; break;
            case 11: this.TimepointDisplay = "Day 11"; break;
            case 12: this.TimepointDisplay = "Day 12"; break;
            case 13: this.TimepointDisplay = "Day 13"; break;
            case 14: this.TimepointDisplay = "Day 14"; break;
            case 15: this.TimepointDisplay = "Day 15"; break;
            case 16: this.TimepointDisplay = "Day 16"; break;
            case 17: this.TimepointDisplay = "Day 17"; break;
            case 18: this.TimepointDisplay = "Day 18"; break;
            case 22: this.TimepointDisplay = "Day 22"; break;
            case 24: this.TimepointDisplay = "Day 24"; break;
            case 26: this.TimepointDisplay = "Day 26"; break;
            case 27: this.TimepointDisplay = "Day 27"; break;
            case 29: this.TimepointDisplay = "Day 29"; break;
            case 31: this.TimepointDisplay = "Day 31"; break;
            case 60: this.TimepointDisplay = "Day 60"; break;
            case 99: this.TimepointDisplay = "Treatment End"; break;
            default: this.TimepointDisplay = "Unknown"; break;
        }

        this.SampleId = this.Patient + "_" + this.SampleTypeId + "_" + this.Timepoint;

        switch (this.UnitsId) {
            case 0: this.Units = "μL"; break;
            case 1: this.Units = "Spots"; break;
            default: this.Units = "Unknown"; break;
        }

        //console.log(this);
    }
}